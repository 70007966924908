import React from "react";
import { Grid, Container } from "@material-ui/core";
import { useTheme } from "@mui/material/styles";

const FlymyaTravelInfoText = () => {
  const theme = useTheme();

  return (
    <Grid
      item
      sm={12}
      xs={12}
      className="covidInfo"
      style={{ backgroundColor: theme.palette.primary.main }}
    >
      <a
        href="https://flymya.notion.site/Flymya-Travel-Information-f678cb0ee3204fc99e49b2e70106d14c"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Container
          className="covidTravelInfo"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span className="reliefTitle covidInfoTitle">
            You can reach our customer service team every day from 7am until
            11pm. To find the latest information about travel (restrictions){" "}
            <span
              className="highlight"
              style={{ color: theme.palette.info.main }}
            >
              click here
            </span>{" "}
            or visit{" "}
            <span
              className="highlight"
              style={{ color: theme.palette.info.main }}
            >
              flymya.co/faq
            </span>
          </span>
        </Container>
      </a>
    </Grid>
  );
};

export default FlymyaTravelInfoText;
