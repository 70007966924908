import { gql } from "apollo-boost";

export const CREATE_CUSTOMER_INFO = gql`
  mutation ($input: createQueryCustomerInfoInput!) {
    createQueryCustomerInfo(input: $input) {
      status
      message
    }
  }
`;

export const CREATE_ARRANGER_VIBER = gql`
  mutation createArrangerViber($input: arrangerViberCreateInput) {
    createArrangerViber(input: $input) {
      channel
      viber_name
      viber_id
      viber_avatar
      viber_language
      viber_country
      viber_api
      arranger {
        id
        first_name
        last_name
      }
    }
  }
`;
