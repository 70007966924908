import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withFormik } from "formik";
import { compose } from "recompose";
import PhonePrefix from "../../components/inputs/PhonePrefix";
import DayOptionComponent from "../../components/inputs/DayOptionComponent";
import MonthOptionComponent from "../../components/inputs/MonthOptionComponent";
import YearOptionComponent from "../../components/inputs/YearOptionComponent";
import { COUNTRIES } from "../../config/data/v3countries";
import { useLazyQuery } from "@apollo/react-hooks";
import {
  GET_REGIONS,
  GET_CITIES,
  GET_TOWNSHIPS,
} from "../../queries/countryquery";
import {
  DivisionList,
  OneList,
  TwoList,
  ThreeList,
  FourList,
  FiveList,
  SixList,
  SevenList,
  EightList,
  NineList,
  TenList,
  ElevenList,
  TwelveList,
  ThirteenList,
  FourteenList,
  CardList,
} from "../../config/data/nrcList";
import {
  MYANMAR_ID,
  YANGON_REGION,
  YANGON_CITY,
  IDENTIFIER,
} from "../../config/constants";
import "../../assets/scss/Home.scss";
import OTP_LOGIN_EMAIL_REGEX from "../../config/regex/otpLoginEmailRegex";
import { getUserEditDate } from "../../utils/loginutils";
import { showErrorMessage } from "../../utils/addressbookUtils";
import ValidationMethod from "./ValidationMethod";
import ProfileEditForm from "./ProfileEditForm";

let socialLogin = false;
let pageName = "home";

const SignupForm = ({
  errorMsg,
  isDim,
  values,
  page,
  isSpinner,
  handleSubmit,
  setFieldValue,
  touched,
  errors,
  formError,
  setFormError,
  regValues,
  setIsShow,
  isShow,
  setIsSignUp,
  isSocialLogin,
  socialLoginType,
  val,
  data,
  editDate,
  addId,
  pageContent,
}) => {
  let passErr = useRef(null);
  const [selectedDivision, setSelectedDivison] = useState("1");
  const [selectedList, setSelectedList] = useState("HsaBaTa");
  const [selectedCardList, setSelectedCardList] = useState("N");
  const [selectedNrcNo, setSelectedNrcNo] = useState("");
  const [listArr, setListArr] = useState(OneList);
  const [regionArr, setRegionArr] = useState([]);
  const [cityArr, setCityArr] = useState([]);
  const [townshipArr, setTownshipArr] = useState([]);
  const [flag, setFlag] = useState(false);
  const [date, setDate] = useState(false);
  const [click, setClick] = useState(false);
  const [checkNRC, setCheckNRC] = useState(false);
  const [isSubmitClick, setIsSubmitClick] = useState(false);

  socialLogin = isSocialLogin;

  pageName = page;

  const [getRegion] = useLazyQuery(GET_REGIONS, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      let arr =
        res.regions.data.length > 0
          ? res.regions.data.map((option) => ({
              value: option.id,
              label: option.name,
            }))
          : [];
      setRegionArr(arr);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const [getCity] = useLazyQuery(GET_CITIES, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      let arr =
        res.cities.data.length > 0
          ? res.cities.data.map((option) => ({
              value: option.id,
              label: option.name,
            }))
          : [];
      setCityArr(arr);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const [getTownship] = useLazyQuery(GET_TOWNSHIPS, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      let arr =
        res.townships.data.length > 0
          ? res.townships.data.map((option) => ({
              value: option.id,
              label: option.name,
            }))
          : [];
      setTownshipArr(arr);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const chooseList = (selected) => {
    switch (selected) {
      case "1":
        return setListArr(OneList);
      case "2":
        return setListArr(TwoList);
      case "3":
        return setListArr(ThreeList);
      case "4":
        return setListArr(FourList);
      case "5":
        return setListArr(FiveList);
      case "6":
        return setListArr(SixList);
      case "7":
        return setListArr(SevenList);
      case "8":
        return setListArr(EightList);
      case "9":
        return setListArr(NineList);
      case "10":
        return setListArr(TenList);
      case "11":
        return setListArr(ElevenList);
      case "12":
        return setListArr(TwelveList);
      case "13":
        return setListArr(ThirteenList);
      case "14":
        return setListArr(FourteenList);
      default:
        return setListArr(OneList);
    }
  };

  useEffect(() => {
    if (!flag) {
      let val =
        regValues && regValues.adultInfo && regValues.adultInfo.length > 0
          ? regValues.adultInfo[0]
          : null;

      getRegion({
        variables: {
          first: 30,
          page: 1,
          filter: [
            {
              field: "country.id",
              operator: "=",
              value: String(MYANMAR_ID),
            },
          ],
        },
      });
      getCity({
        variables: {
          first: 30,
          page: 1,
          filter: [
            {
              field: "region_id",
              operator: "=",
              value:
                val && val.regionId
                  ? String(val.regionId)
                  : String(YANGON_REGION),
            },
          ],
        },
      });
      getTownship({
        variables: {
          first: 30,
          page: 1,
          filter: [
            {
              field: "city_id",
              operator: "=",
              value:
                val && val.cityId ? String(val.cityId) : String(YANGON_CITY),
            },
          ],
        },
      });

      let isCheck =
        values.signupNrc !== "" && values.signupNrc.indexOf("/") !== -1
          ? true
          : false;
      if (isCheck) {
        chooseList(values.signupNrc.split("/")[0]);
        setSelectedDivison(values.signupNrc.split("/")[0]);
        setSelectedList(
          values.signupNrc.substring(
            values.signupNrc.indexOf("/") + 1,
            values.signupNrc.lastIndexOf("("),
          ),
        );
        setSelectedCardList(
          values.signupNrc.substring(
            values.signupNrc.indexOf("(") + 1,
            values.signupNrc.lastIndexOf(")"),
          ),
        );
        setSelectedNrcNo(values.signupNrc.split(")")[1]);
      }

      setFlag(true);
    }
  }, [
    flag,
    getCity,
    getRegion,
    getTownship,
    regValues,
    values,
    values.nrc,
    values.signupNrcPass,
    values.singupNrc,
  ]);

  const setDefaultList = (selected) => {
    switch (selected) {
      case "1":
        setSelectedList("HsaBaTa");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "HsaBaTa", "N", "");
        break;
      case "2":
        setSelectedList("BaLaKha");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "BaLaKha", "N", "");
        break;
      case "3":
        setSelectedList("RaRaTha");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "RaRaTha", "N", "");
        break;
      case "4":
        setSelectedList("HsaMaNa");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "HsaMaNa", "N", "");
        break;
      case "5":
        setSelectedList("KhaPaNa");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "KhaPaNa", "N", "");
        break;
      case "6":
        setSelectedList("KaLaA");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "KaLaA", "N", "");
        break;
      case "7":
        setSelectedList("AhPhaNa");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "AhPhaNa", "N", "");
        break;
      case "8":
        setSelectedList("MaHtaNa");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "MaHtaNa", "N", "");
        break;
      case "9":
        setSelectedList("NgaZaNa");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "NgaZaNa", "N", "");
        break;
      case "10":
        setSelectedList("KhaZaNa");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "KhaZaNa", "N", "");
        break;
      case "11":
        setSelectedList("TaPaWa");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "TaPaWa", "N", "");
        break;
      case "12":
        setSelectedList("AhLaNa");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "AhLaNa", "N", "");
        break;
      case "13":
        setSelectedList("KaTaLa");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "KaTaLa", "N", "");
        break;
      case "14":
        setSelectedList("KaKaHTa");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "KaKaHTa", "N", "");
        break;
      default:
        setSelectedList("HsaBaTa");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "HsaBaTa", "N", "");
        break;
    }
  };

  const handleNrc = (division, list, cardList, nrcNo) => {
    setFieldValue(
      "signupNrc",
      division + "/" + list + "(" + cardList + ")" + nrcNo,
    );
  };

  useEffect(() => {
    if (val) {
      setFieldValue("signupPrefix", val?.gender);
      setFieldValue("signupFirstName", val?.firstName);
      setFieldValue("signupLastName", val?.lastName);
      setFieldValue("signupEmail", val?.email);
      setFieldValue("signupPhone", val?.mobileNo);
      setFieldValue("signupPhonePrefix", val?.mobilePrefix);
    }
  }, [setFieldValue, val]);

  useEffect(() => {
    if (data) {
      setFieldValue(
        "signupPrefix",
        data?.prefix ? data.prefix.replace(".", "") : "Mr",
      );
      setFieldValue("signupFirstName", data?.first_name);
      setFieldValue("signupLastName", data?.last_name);
      setFieldValue("signupEmail", data?.email);
      setFieldValue(
        "signupPhonePrefix",
        data?.phone?.replace(" ", "")?.substring(0, 2),
      );
      setFieldValue("signupPhone", data?.phone?.replace(" ", "")?.substring(2));
      setFieldValue("signupDobDay", data?.dob?.split("-")[2]);
      setFieldValue("signupDobMonth", data?.dob?.split("-")[1]);
      setFieldValue("signupDobYear", data?.dob?.split("-")[0]);
      setFieldValue("signupNationality", data?.nationality?.id);
      setFieldValue("signupNrcPass", data?.passport);
      setFieldValue("signupExpDay", data?.passport_exp?.split("-")[2]);
      setFieldValue("signupExpMonth", data?.passport_exp?.split("-")[1]);
      setFieldValue("signupExpYear", data?.passport_exp?.split("-")[0]);
      setFieldValue("address", data?.address);
      // setSelectedDivison(data?.nrc?.split("/")[0]);
      // chooseList(data?.nrc?.split("/")[0]);
      // setSelectedList(
      //   data?.nrc?.substring(
      //     data?.nrc?.indexOf("/") + 1,
      //     data?.nrc?.lastIndexOf("("),
      //   ),
      // );
      // setSelectedCardList(
      //   data?.nrc?.substring(
      //     data?.nrc?.indexOf("(") + 1,
      //     data?.nrc?.lastIndexOf(")"),
      //   ),
      // );
      // setSelectedNrcNo(
      //   data?.nrc?.split(")")[1] !== "undefined"
      //     ? data?.nrc?.split(")")[1]
      //     : "",
      // );

      setFieldValue("township", data?.township?.id);
      setFieldValue("city", data?.township?.city?.id);
      setFieldValue(
        "signupNrc",
        data?.nrc?.split("/")[0] +
          "/" +
          data?.nrc?.substring(
            data?.nrc?.indexOf("/") + 1,
            data?.nrc?.lastIndexOf("("),
          ) +
          "(" +
          data?.nrc?.substring(
            data?.nrc?.indexOf("(") + 1,
            data?.nrc?.lastIndexOf(")"),
          ) +
          ")" +
          data?.nrc?.split(")")[1],
      );
      setSelectedDivison(data?.nrc?.split("/")[0]);
      chooseList(data?.nrc?.split("/")[0]);
      setSelectedList(
        data?.nrc?.substring(
          data?.nrc?.indexOf("/") + 1,
          data?.nrc?.lastIndexOf("("),
        ),
      );
      setSelectedCardList(
        data?.nrc?.substring(
          data?.nrc?.indexOf("(") + 1,
          data?.nrc?.lastIndexOf(")"),
        ),
      );
      setSelectedNrcNo(
        data?.nrc?.split(")")[1] !== "undefined"
          ? data?.nrc?.split(")")[1]
          : "",
      );
    }
  }, []);

  useEffect(() => {
    // if (pageName === "profile") {
    //   let val = getUserEditDate()?.split(",");
    //   if (val) {
    //     let d = new Date(val[1]).getDate() + 7;
    //     if (val[0] === window.location.pathname.split("/")[3]) {
    //       if (new Date(val[1]) > d) {
    //         setDate(true);
    //       }
    //     }
    //   }
    // }
  }, []);

  useEffect(() => {
    setIsSubmitClick(false);
  }, []);

  // useEffect(() => {
  //   if (page === "addressBook" && isSubmitClick) {
  //     console.log("hey");
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //     passErr.current = showErrorMessage("Passport", values.signupNrcPass);
  //     console.log(
  //       "pass error is",
  //       showErrorMessage("Passport", values.signupNrcPass),
  //     );

  //     // nrcErr = showErrorMessage("Nrc", selectedNrcNo);
  //     // passExpErr = showErrorMessage("expDay", values.signupExpDay);
  //     // passExpErr = showErrorMessage("expMonth", values.signupExpMonth);
  //     // passExpErr = showErrorMessage("expYear", values.signupExpYear);
  //   }
  // }, []);

  return (
    <div className={isDim ? "dim-div" : ""}>
      <Grid container>
        <Grid item={true} xs={12} sm={12} md={2}>
          <label>
            {pageContent && pageContent.prefix ? pageContent.prefix : "Title *"}
          </label>
          <select
            className="selectBox selectBoxFullWidthSignUpMobile"
            disabled={val?.gender || date ? true : false}
            name="signupPrefix"
            value={values.signupPrefix}
            onChange={(e) => setFieldValue("signupPrefix", e.target.value)}
          >
            <option value="Mr">Mr.</option>
            <option value="Mrs">Mrs.</option>
            <option value="Ms">Ms.</option>
          </select>
        </Grid>
        <Grid item={true} xs={12} sm={12} md={5}>
          <label>
            {pageContent && pageContent.firstName
              ? pageContent.firstName
              : "First Name *"}
          </label>
          <input
            className={
              touched.signupFirstName && errors.signupFirstName
                ? "error-textbox"
                : ""
            }
            disabled={val?.firstName || date ? true : false}
            name="signupFirstName"
            value={values.signupFirstName}
            onChange={(e) => setFieldValue("signupFirstName", e.target.value)}
            data-lpignore="true"
            required
          />
          {touched.signupFirstName && errors.signupFirstName && (
            <p className="signup-error">{errors.signupFirstName}</p>
          )}
        </Grid>
        <Grid item={true} xs={12} sm={12} md={5}>
          <label>
            {pageContent && pageContent.lastName
              ? `${pageContent.lastName} *`
              : "Last Name *"}
          </label>
          <input
            className={
              touched.signupLastName && errors.signupLastName
                ? "error-textbox"
                : ""
            }
            name="signupLastName"
            disabled={val?.lastName || date ? true : false}
            value={values.signupLastName}
            onChange={(e) => setFieldValue("signupLastName", e.target.value)}
            data-lpignore="true"
            required
          />
          {touched.signupLastName && errors.signupLastName && (
            <p className="signup-error">{errors.signupLastName}</p>
          )}
        </Grid>
        {pageName !== "addressBook" && pageName !== "profile" && (
          <>
            <Grid item={true} xs={12} sm={12} md={6}>
              <label>
                {pageContent && pageContent.email
                  ? pageContent.email
                  : "Email *"}
              </label>
              <input
                className={
                  (touched.signupEmail && errors.signupEmail) ||
                  formError !== ""
                    ? "error-textbox"
                    : ""
                }
                disabled={
                  val?.email
                    ? true
                    : pageName === "addressBook"
                    ? false
                    : socialLoginType === "google" ||
                      pageName === "profile" ||
                      (localStorage.getItem(IDENTIFIER) &&
                        localStorage.getItem(IDENTIFIER) !== "" &&
                        !localStorage.getItem(IDENTIFIER).includes("+"))
                }
                name="signupEmail"
                value={values.signupEmail}
                onChange={(e) => {
                  setFormError("");
                  setFieldValue("signupEmail", e.target.value);
                }}
                data-lpignore="true"
                required
              />
              {touched.signupEmail && errors.signupEmail && (
                <p className="signup-error">{errors.signupEmail}</p>
              )}
              {formError !== "" && <p className="signup-error">{formError}</p>}
            </Grid>
            <Grid item xs={4} sm={4} md={2} className="phone-container">
              <label>
                {" "}
                {pageContent && pageContent.phone
                  ? pageContent.phone
                  : "Phone *"}
              </label>
              <PhonePrefix
                val={val}
                name="signupPhonePrefix"
                value={values.signupPhonePrefix || ""}
                prefixValue={values.signupPhonePrefix}
                handlePhonePrefix={(value) =>
                  setFieldValue("signupPhonePrefix", value)
                }
                handlePhoneFlag={(value) =>
                  setFieldValue("mobileCountryFlag", value)
                }
                mobileCountryFlag={values.mobileCountryFlag}
                isDisable={
                  socialLoginType === "phone" ||
                  (localStorage.getItem(IDENTIFIER) &&
                    localStorage.getItem(IDENTIFIER) !== "" &&
                    localStorage.getItem(IDENTIFIER).includes("+"))
                }
              />
            </Grid>
            <Grid item={true} xs={8} sm={8} md={4}>
              <label>&nbsp;</label>
              <input
                className={
                  touched.signupPhone && errors.signupPhone
                    ? "error-textbox"
                    : ""
                }
                disabled={
                  val?.mobileNo || date ? true : socialLoginType === "phone"
                }
                name="signupPhone"
                value={values.signupPhone}
                onChange={(e) => setFieldValue("signupPhone", e.target.value)}
                data-lpignore="true"
                required
              />
              {touched.signupPhone && errors.signupPhone && (
                <p className="signup-error">{errors.signupPhone}</p>
              )}
            </Grid>
          </>
        )}
        {!isSocialLogin &&
          pageName !== "profile" &&
          pageName !== "addressBook" &&
          pageName !== "emailLinkAuth" && (
            <Grid item={true} xs={12} sm={12} md={6}>
              <label>
                {pageContent && pageContent.password
                  ? pageContent.password
                  : "Password *"}
              </label>
              <input
                className={
                  touched.signupPassword && errors.signupPassword
                    ? "error-textbox"
                    : ""
                }
                name="signupPassword"
                type="password"
                value={values.signupPassword}
                autoComplete="off"
                onChange={(e) =>
                  setFieldValue("signupPassword", e.target.value)
                }
                data-lpignore="true"
                required={isSocialLogin ? false : true}
              />
              {touched.signupPassword && errors.signupPassword && (
                <p className="signup-error">{errors.signupPassword}</p>
              )}
            </Grid>
          )}
        {pageName === "addressBook" && (
          <>
            <Grid item={true} xs={12} sm={12} md={6} className="dobRegion">
              <Grid container className="dobRegion">
                <label>
                  {pageContent && pageContent.dob
                    ? `${pageContent.dob} *`
                    : "Date of Birth *"}
                </label>
                <Grid item={true} xs={4} sm={4} md={3}>
                  <select
                    name="signupDobDay"
                    className={
                      values.signupDobDay === "00"
                        ? "selectBox placeHolderText"
                        : "selectBox"
                    }
                    required
                    disabled={date ? true : false}
                    value={values.signupDobDay}
                    onChange={(e) =>
                      setFieldValue("signupDobDay", e.target.value)
                    }
                  >
                    <DayOptionComponent
                      dayOption={values.signupDobMonth === "02" ? 29 : 31}
                    />
                  </select>
                  {isSubmitClick && values.signupDobDay === "00" && (
                    <p className="signup-error">Birth day is required</p>
                  )}
                </Grid>
                <Grid item={true} xs={4} sm={4} md={5}>
                  <select
                    name="signupDobMonth"
                    disabled={date ? true : false}
                    className={
                      values.signupDobMonth === "00"
                        ? "selectBox placeHolderText"
                        : "selectBox"
                    }
                    required
                    value={values.signupDobMonth}
                    onChange={(e) =>
                      setFieldValue("signupDobMonth", e.target.value)
                    }
                  >
                    <MonthOptionComponent />
                  </select>
                  {isSubmitClick && values.signupDobMonth === "00" && (
                    <p className="signup-error">Birth month is required.</p>
                  )}
                </Grid>
                <Grid item={true} xs={4} sm={4} md={4}>
                  <select
                    disabled={date}
                    required
                    name="signupDobYear"
                    className={
                      values.signupDobYear === "00"
                        ? "selectBox placeHolderText"
                        : "selectBox"
                    }
                    value={values.signupDobYear}
                    onChange={(e) =>
                      setFieldValue("signupDobYear", e.target.value)
                    }
                  >
                    <YearOptionComponent type="dob" />
                  </select>
                  {isSubmitClick && values.signupDobYear === "0000" && (
                    <p className="signup-error">Birth year is required.</p>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true} sm={12} md={6}>
              <label>
                {pageContent && pageContent.nationality
                  ? pageContent.nationality
                  : "Nationality *"}
              </label>
              <select
                className="nationality"
                name="signupNationality"
                disabled={date}
                value={values.signupNationality}
                onChange={(e) =>
                  setFieldValue("signupNationality", e.target.value)
                }
              >
                {COUNTRIES.map((country, i) => {
                  return (
                    <option key={i} value={country.CountryId}>
                      {country.CountryName}
                    </option>
                  );
                })}
              </select>
            </Grid>
            {Number(values.signupNationality) === MYANMAR_ID && (
              <Grid item={true} xs={12} sm={12} md={12} className="dobRegion">
                <Grid container className="dobRegion">
                  <label>
                    {pageContent && pageContent.nrc
                      ? `${pageContent.nrc} *`
                      : "NRC *"}
                  </label>
                  <Grid item={true} xs={3} sm={3} md={1}>
                    <select
                      name="nrcDivision"
                      disabled={date}
                      defaultValue={5}
                      className="selectBox nrcNumberField"
                      value={selectedDivision}
                      onChange={(e) => {
                        setSelectedDivison(e.target.value);
                        setDefaultList(e.target.value);
                        chooseList(e.target.value);
                      }}
                    >
                      {DivisionList.map((division, key) => {
                        return (
                          <option key={key} value={division}>
                            {division}
                          </option>
                        );
                      })}
                    </select>
                  </Grid>
                  <Grid item={true} xs={5} sm={5} md={4}>
                    <select
                      name="nrcList"
                      disabled={date}
                      className="selectBox nrcTextField"
                      value={selectedList}
                      onChange={(e) => {
                        setSelectedList(e.target.value);
                        handleNrc(
                          selectedDivision,
                          e.target.value,
                          selectedCardList,
                          selectedNrcNo,
                        );
                      }}
                    >
                      {listArr &&
                        listArr.map((list, key) => {
                          return (
                            <option key={key} value={list}>
                              {list}
                            </option>
                          );
                        })}
                    </select>
                  </Grid>
                  <Grid item={true} xs={4} sm={4} md={2}>
                    <select
                      disabled={date}
                      name="nrcCard"
                      className="selectBox nrcNumberField"
                      value={selectedCardList}
                      onChange={(e) => {
                        setSelectedCardList(e.target.value);
                        handleNrc(
                          selectedDivision,
                          selectedList,
                          e.target.value,
                          selectedNrcNo,
                        );
                      }}
                    >
                      {CardList.map((card, key) => {
                        return (
                          <option key={key} value={card}>
                            {card}
                          </option>
                        );
                      })}
                    </select>
                  </Grid>
                  <Grid item={true} xs={12} sm={12} md={4}>
                    <input
                      name="nrcNumber"
                      disabled={date}
                      className="nrcNumber"
                      value={selectedNrcNo}
                      autoComplete="off"
                      onChange={(e) => {
                        if (!/^[0-9\b]*$/.test(e.target.value)) {
                          setCheckNRC(true);
                          return;
                        }
                        setCheckNRC(false);
                        setSelectedNrcNo(e.target.value);
                        handleNrc(
                          selectedDivision,
                          selectedList,
                          selectedCardList,
                          e.target.value,
                        );
                      }}
                      data-lpignore="true"
                    />
                    {checkNRC && (
                      <p className="signup-error">Please type only number.</p>
                    )}
                    {isSubmitClick && selectedNrcNo === "" && (
                      <p className="signup-error">NRC is required.</p>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        )}
        {pageName === "addressBook" && (
          <>
            <Grid item={true} xs={12} sm={6} md={6} className="nrcRegion">
              <label>
                {pageContent && pageContent.passport
                  ? `${pageContent.passport} *`
                  : "Passport"}
              </label>
              <Grid item={true} sm={12} md={12}>
                <input
                  className={
                    touched.signupNrcPass && errors.signupNrcPass
                      ? "error-textbox nrcNumber"
                      : "nrcNumber"
                  }
                  name="signupNrcPass"
                  disabled={date}
                  type="text"
                  value={values.signupNrcPass}
                  autoComplete="off"
                  onChange={(e) =>
                    setFieldValue("signupNrcPass", e.target.value)
                  }
                  data-lpignore="true"
                  required
                />
                {touched.signupNrcPass && errors.signupNrcPass && (
                  <p className="signup-error">{errors.signupNrcPass}</p>
                )}
              </Grid>
            </Grid>
            <Grid item={true} sm={6} md={6} className="nrcRegion">
              <Grid container className="nrcRegion">
                <label>
                  {pageContent && pageContent.passportExpirationDate
                    ? `${pageContent.passportExpirationDate} *`
                    : "Passport Expiration Date"}
                </label>
                <Grid item={true} md={3}>
                  <select
                    disabled={date}
                    name="signupExpDay"
                    className={
                      values.signupExpDay === "00"
                        ? "selectBox placeHolderText"
                        : "selectBox"
                    }
                    value={values.signupExpDay}
                    onChange={(e) =>
                      setFieldValue("signupExpDay", e.target.value)
                    }
                  >
                    <DayOptionComponent
                      dayOption={values.signupExpMonth === "02" ? 29 : 31}
                    />
                    {/* {passExpErr?.error && (
                            <p className="signup-error">
                              {passExpErr?.errorMessage}
                            </p>
                          )} */}
                  </select>
                  {touched.signupExpDay && errors.signupExpDay && (
                    <p className="signup-error">{errors.signupExpDay}</p>
                  )}
                </Grid>
                <Grid item={true} md={5}>
                  <select
                    name="signupExpMonth"
                    disabled={date}
                    className={
                      values.signupExpMonth === "00"
                        ? "selectBox placeHolderText"
                        : "selectBox"
                    }
                    value={values.signupExpMonth}
                    onChange={(e) =>
                      setFieldValue("signupExpMonth", e.target.value)
                    }
                  >
                    <MonthOptionComponent />
                  </select>
                  {touched.signupExpMonth && errors.signupExpMonth && (
                    <p className="signup-error">{errors.signupExpMonth}</p>
                  )}
                </Grid>
                <Grid item={true} md={4}>
                  <select
                    name="signupExpYear"
                    disabled={date}
                    className={
                      values.signupExpYear === "00"
                        ? "selectBox placeHolderText"
                        : "selectBox"
                    }
                    value={values.signupExpYear}
                    onChange={(e) =>
                      setFieldValue("signupExpYear", e.target.value)
                    }
                  >
                    <YearOptionComponent type="exp" />
                  </select>
                  {touched.signupExpYear && errors.signupExpYear && (
                    <p className="signup-error">{errors.signupExpYear}</p>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        {/* {!isSocialLogin && (
          <Grid item={true} xs={12} sm={12} md={6}>
            <label>Confirm Password *</label>
            <input
              className={
                touched.signupConfirmPassword && errors.signupConfirmPassword
                  ? "error-textbox"
                  : ""
              }
              name="signupConfirmPassword"
              type="password"
              value={values.signupConfirmPassword}
              autoComplete="off"
              onChange={e =>
                setFieldValue("signupConfirmPassword", e.target.value)
              }
              data-lpignore="true"
              required={isSocialLogin ? false : true}
            />
            {touched.signupConfirmPassword && errors.signupConfirmPassword && (
              <p className="signup-error">{errors.signupConfirmPassword}</p>
            )}
          </Grid>
        )} */}
        {pageName === "profile" && (
          <ProfileEditForm
            pageContent={pageContent}
            values={values}
            date={date}
            setFieldValue={setFieldValue}
            selectedDivision={selectedDivision}
            setSelectedDivison={setSelectedDivison}
            setDefaultList={setDefaultList}
            chooseList={chooseList}
            selectedList={selectedList}
            setSelectedList={setSelectedList}
            handleNrc={handleNrc}
            selectedNrcNo={selectedNrcNo}
            selectedCardList={selectedCardList}
            setSelectedCardList={setSelectedCardList}
            checkNRC={checkNRC}
            setCheckNRC={setCheckNRC}
            setSelectedNrcNo={setSelectedNrcNo}
            DivisionList={DivisionList}
            listArr={listArr}
            CardList={CardList}
            touched={touched}
            errors={errors}
            getCity={getCity}
            getTownship={getTownship}
            regionArr={regionArr}
            cityArr={cityArr}
            townshipArr={townshipArr}
          />
        )}

        {pageName !== "profile" && (
          <Grid item={true} xs={12} sm={12} md={12}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <p style={{ color: "black" }}>
                  {pageContent && pageContent.moreInfoOptional
                    ? pageContent.moreInfoOptional
                    : "More Information (Optional)"}
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  {pageName !== "addressBook" && (
                    <Grid
                      item={true}
                      xs={12}
                      sm={12}
                      md={6}
                      className="dobRegion"
                    >
                      <Grid container className="dobRegion">
                        <label>
                          {pageContent && pageContent.dob
                            ? pageContent.dob
                            : "Date of Birth *"}
                        </label>
                        <Grid item={true} xs={4} sm={4} md={3}>
                          <select
                            name="signupDobDay"
                            className={
                              values.signupDobDay === "00"
                                ? "selectBox placeHolderText"
                                : "selectBox"
                            }
                            disabled={date ? true : false}
                            value={values.signupDobDay}
                            onChange={(e) =>
                              setFieldValue("signupDobDay", e.target.value)
                            }
                          >
                            <DayOptionComponent
                              dayOption={
                                values.signupDobMonth === "02" ? 29 : 31
                              }
                            />
                          </select>
                        </Grid>
                        <Grid item={true} xs={4} sm={4} md={5}>
                          <select
                            name="signupDobMonth"
                            disabled={date ? true : false}
                            className={
                              values.signupDobMonth === "00"
                                ? "selectBox placeHolderText"
                                : "selectBox"
                            }
                            value={values.signupDobMonth}
                            onChange={(e) =>
                              setFieldValue("signupDobMonth", e.target.value)
                            }
                          >
                            <MonthOptionComponent />
                          </select>
                        </Grid>
                        <Grid item={true} xs={4} sm={4} md={4}>
                          <select
                            disabled={date}
                            name="signupDobYear"
                            className={
                              values.signupDobYear === "00"
                                ? "selectBox placeHolderText"
                                : "selectBox"
                            }
                            value={values.signupDobYear}
                            onChange={(e) =>
                              setFieldValue("signupDobYear", e.target.value)
                            }
                          >
                            <YearOptionComponent type="dob" />
                          </select>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  {pageName !== "addressBook" && (
                    <Grid
                      item={true}
                      sm={12}
                      md={pageName === "addressBook" ? 8 : 6}
                    >
                      <label>
                        {pageContent && pageContent.nationality
                          ? pageContent.nationality
                          : "Nationality *"}
                      </label>
                      <select
                        className="nationality"
                        name="signupNationality"
                        disabled={date}
                        value={values.signupNationality}
                        onChange={(e) =>
                          setFieldValue("signupNationality", e.target.value)
                        }
                      >
                        {COUNTRIES.map((country, i) => {
                          return (
                            <option key={i} value={country.CountryId}>
                              {country.CountryName}
                            </option>
                          );
                        })}
                      </select>
                    </Grid>
                  )}
                  {Number(values.signupNationality) === MYANMAR_ID &&
                    pageName !== "addressBook" && (
                      <Grid
                        item={true}
                        xs={12}
                        sm={12}
                        md={12}
                        className="nrcRegion"
                      >
                        <Grid container className="nrcRegion">
                          <label>
                            {pageContent && pageContent.nrc
                              ? pageContent.nrc
                              : "NRC"}
                          </label>
                          <Grid item={true} xs={3} sm={3} md={1}>
                            <select
                              name="nrcDivision"
                              disabled={date}
                              defaultValue={5}
                              className="selectBox nrcNumberField"
                              value={selectedDivision}
                              onChange={(e) => {
                                setSelectedDivison(e.target.value);
                                setDefaultList(e.target.value);
                                chooseList(e.target.value);
                              }}
                            >
                              {DivisionList.map((division, key) => {
                                return (
                                  <option key={key} value={division}>
                                    {division}
                                  </option>
                                );
                              })}
                            </select>
                          </Grid>
                          <Grid item={true} xs={5} sm={5} md={4}>
                            <select
                              name="nrcList"
                              disabled={date}
                              className="selectBox nrcTextField"
                              value={selectedList}
                              onChange={(e) => {
                                setSelectedList(e.target.value);
                                handleNrc(
                                  selectedDivision,
                                  e.target.value,
                                  selectedCardList,
                                  selectedNrcNo,
                                );
                              }}
                            >
                              {listArr &&
                                listArr.map((list, key) => {
                                  return (
                                    <option key={key} value={list}>
                                      {list}
                                    </option>
                                  );
                                })}
                            </select>
                          </Grid>
                          <Grid item={true} xs={4} sm={4} md={1}>
                            <select
                              disabled={date}
                              name="nrcCard"
                              className="selectBox nrcNumberField"
                              value={selectedCardList}
                              onChange={(e) => {
                                setSelectedCardList(e.target.value);
                                handleNrc(
                                  selectedDivision,
                                  selectedList,
                                  e.target.value,
                                  selectedNrcNo,
                                );
                              }}
                            >
                              {CardList.map((card, key) => {
                                return (
                                  <option key={key} value={card}>
                                    {card}
                                  </option>
                                );
                              })}
                            </select>
                          </Grid>
                          <Grid item={true} xs={12} sm={12} md={6}>
                            <input
                              name="nrcNumber"
                              disabled={date}
                              className="nrcNumber"
                              value={selectedNrcNo}
                              autoComplete="off"
                              onChange={(e) => {
                                if (!/^[0-9\b]*$/.test(e.target.value)) {
                                  setCheckNRC(true);
                                  return;
                                }
                                setCheckNRC(false);
                                setSelectedNrcNo(e.target.value);
                                handleNrc(
                                  selectedDivision,
                                  selectedList,
                                  selectedCardList,
                                  e.target.value,
                                );
                              }}
                              data-lpignore="true"
                            />
                            {checkNRC && (
                              <p className="signup-error">
                                Please type only number.
                              </p>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  {/* {Number(values.signupNationality) !== MYANMAR_ID && ( */}
                  {pageName !== "addressBook" && (
                    <>
                      <Grid
                        item={true}
                        xs={12}
                        sm={6}
                        md={6}
                        className="nrcRegion"
                      >
                        <label>
                          {pageContent && pageContent.passport
                            ? pageContent.passport
                            : "Passport"}
                        </label>
                        <Grid item={true} sm={12} md={12}>
                          <input
                            className={
                              touched.signupNrcPass && errors.signupNrcPass
                                ? "error-textbox nrcNumber"
                                : "nrcNumber"
                            }
                            name="signupNrcPass"
                            disabled={date}
                            type="text"
                            value={values.signupNrcPass}
                            autoComplete="off"
                            onChange={(e) =>
                              setFieldValue("signupNrcPass", e.target.value)
                            }
                            data-lpignore="true"
                            required
                          />
                        </Grid>
                      </Grid>
                      <Grid item={true} sm={6} md={6} className="nrcRegion">
                        <Grid container className="nrcRegion">
                          <label>
                            {pageContent && pageContent.passportExpirationDate
                              ? pageContent.passportExpirationDate
                              : "Passport Expiration Date"}
                          </label>
                          <Grid item={true} md={3}>
                            <select
                              disabled={date}
                              name="signupExpDay"
                              className={
                                values.signupExpDay === "00"
                                  ? "selectBox placeHolderText"
                                  : "selectBox"
                              }
                              value={values.signupExpDay}
                              onChange={(e) =>
                                setFieldValue("signupExpDay", e.target.value)
                              }
                            >
                              <DayOptionComponent
                                dayOption={
                                  values.signupExpMonth === "02" ? 29 : 31
                                }
                              />
                              {/* {passExpErr?.error && (
                            <p className="signup-error">
                              {passExpErr?.errorMessage}
                            </p>
                          )} */}
                            </select>
                          </Grid>
                          <Grid item={true} md={5}>
                            <select
                              name="signupExpMonth"
                              disabled={date}
                              className={
                                values.signupExpMonth === "00"
                                  ? "selectBox placeHolderText"
                                  : "selectBox"
                              }
                              value={values.signupExpMonth}
                              onChange={(e) =>
                                setFieldValue("signupExpMonth", e.target.value)
                              }
                            >
                              <MonthOptionComponent />
                            </select>
                          </Grid>
                          <Grid item={true} md={4}>
                            <select
                              name="signupExpYear"
                              disabled={date}
                              className={
                                values.signupExpYear === "00"
                                  ? "selectBox placeHolderText"
                                  : "selectBox"
                              }
                              value={values.signupExpYear}
                              onChange={(e) =>
                                setFieldValue("signupExpYear", e.target.value)
                              }
                            >
                              <YearOptionComponent type="exp" />
                            </select>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {Number(values.signupNationality) === MYANMAR_ID && (
                    <>
                      <Grid item={true} xs={6} sm={6} md={4}>
                        <label>
                          {pageContent && pageContent.region
                            ? pageContent.region
                            : "Region"}
                        </label>
                        <select
                          name="region"
                          disabled={date}
                          className="selectBox regionSelect"
                          value={values.region}
                          onChange={(e) => {
                            setFieldValue("region", e.target.value);
                            setFieldValue("cityArr", []);
                            getCity({
                              variables: {
                                first: 30,
                                page: 1,
                                filter: [
                                  {
                                    field: "region_id",
                                    operator: "=",
                                    value: e.target.value,
                                  },
                                ],
                              },
                            });
                          }}
                        >
                          {regionArr.map((val, key) => {
                            return (
                              <option key={key} value={val.value}>
                                {val.label}
                              </option>
                            );
                          })}
                        </select>
                      </Grid>

                      <Grid item={true} xs={6} sm={6} md={4}>
                        <label>
                          {pageContent && pageContent.city
                            ? pageContent.city
                            : "City"}
                        </label>
                        <select
                          name="city"
                          disabled={date}
                          className="selectBox regionSelect"
                          value={values.city}
                          onChange={(e) => {
                            setFieldValue("city", e.target.value);
                            setFieldValue("townshipArr", []);
                            getTownship({
                              variables: {
                                first: 30,
                                page: 1,
                                filter: [
                                  {
                                    field: "city_id",
                                    operator: "=",
                                    value: e.target.value,
                                  },
                                ],
                              },
                            });
                          }}
                        >
                          <option value="">Select City</option>
                          {cityArr.map((val, key) => {
                            return (
                              <option key={key} value={val.value}>
                                {val.label}
                              </option>
                            );
                          })}
                        </select>
                      </Grid>

                      <Grid item={true} xs={12} sm={12} md={4}>
                        <label>
                          {pageContent && pageContent.township
                            ? pageContent.township
                            : "Township"}
                        </label>
                        <select
                          disabled={date}
                          name="township"
                          className="selectBox regionSelect"
                          value={values.township}
                          onChange={(e) =>
                            setFieldValue("township", e.target.value)
                          }
                        >
                          <option value="">Select Township</option>
                          {townshipArr.map((val, key) => {
                            return (
                              <option key={key} value={val.value}>
                                {val.label}
                              </option>
                            );
                          })}
                        </select>
                      </Grid>
                    </>
                  )}
                  <Grid item={true} xs={12} sm={12} md={12}>
                    <label>
                      {pageContent && pageContent.addressDetails
                        ? pageContent.addressDetails
                        : "Address Details"}
                    </label>
                    <textarea
                      name="address"
                      disabled={date}
                      className="address"
                      value={values.address}
                      autoComplete="off"
                      onChange={(e) => setFieldValue("address", e.target.value)}
                    ></textarea>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
      </Grid>
      {/* {pageName === "profile" && date ? (
        <p style={{ textAlign: "center", color: "red" }}>
          You have to wait 7 days for next attempt.
        </p>
      ) : (
        ""
      )} */}
      <p style={{ textAlign: "center" }}>{errorMsg}</p>
      <Button
        color="primary"
        variant="contained"
        // style={
        //   date
        //     ? { background: "#D9D9D9", color: "#000" }
        //     : { background: "#20a8d8" }
        // }
        disabled={checkNRC ? checkNRC : date}
        type="button"
        id="btn-sign-in"
        onClick={() => {
          setClick(true);
          setIsSubmitClick(true);
          handleSubmit();
        }}
      >
        {pageName === "profile"
          ? "Save"
          : addId === "addressBookCreate"
          ? pageContent && pageContent.addPassenger
            ? pageContent.addPassenger
            : "Add Passenger"
          : addId === "addressBookEdit"
          ? "Save Passenger"
          : pageContent && pageContent.signUp
          ? pageContent.signUp
          : "Sign Up"}{" "}
        {isSpinner && <CircularProgress />}
      </Button>

      {pageName === "home" && (
        <Grid item={true} sm={12} md={12} xs={12} className="member-account">
          <span>
            {pageContent && pageContent.alreadyHaveAccount
              ? pageContent.alreadyHaveAccount
              : "Already have an account?"}
          </span>{" "}
          <span className="signIn_btn" id="login_btn" onClick={setIsSignUp}>
            {pageContent && pageContent.signIn ? pageContent.signIn : "Sign in"}
          </span>
        </Grid>
      )}
    </div>
  );
};

export default compose(
  withFormik({
    mapPropsToValues: (props) => {
      let val =
        props.regValues &&
        props.regValues.adultInfo &&
        props.regValues.adultInfo.length > 0
          ? props.regValues.adultInfo[0]
          : null;
      return {
        id:
          localStorage.getItem("arranger_people_id") &&
          localStorage.getItem("arranger_people_id") !== ""
            ? localStorage.getItem("arranger_people_id")
            : null,
        signupPrefix: val && val.title ? val.title : "Mr",
        signupFirstName: val && val.firstName ? val.firstName : "",
        signupLastName: val && val.lastName ? val.lastName : "",
        signupEmail:
          val && val.email && !OTP_LOGIN_EMAIL_REGEX.test(val.email)
            ? val.email
            : "",
        signupPassword: "",
        mobileCountryFlag:
          val && val.mobileCountryFlag ? val.mobileCountryFlag : "mm",
        signupPhonePrefix: val && val.mobilePrefix ? val.mobilePrefix : "95",
        signupPhone: val && val.mobileNo ? val.mobileNo : "",
        signupDobDay: val && val.dobDay ? val.dobDay : "00",
        signupDobMonth: val && val.dobMonth ? val.dobMonth : "00",
        signupDobYear: val && val.dobYear ? val.dobYear : "0000",
        signupExpDay: val && val.expDay ? val.expDay : "00",
        signupExpMonth: val && val.expMonth ? val.expMonth : "00",
        signupExpYear: val && val.expYear ? val.expYear : "0000",
        signupNationality: MYANMAR_ID,
        signupNrcPass: val && val.passportNrc ? val.passportNrc : "",
        signupNrc: val && val.nrc ? val.nrc : "",
        region: val && val.regionId ? val.regionId : YANGON_REGION,
        city: val && val.cityId ? val.cityId : YANGON_CITY,
        township: val && val.townshipId ? val.townshipId : null,
        address: val && val.address ? val.address : "",
      };
    },
    validationSchema: (props) => ValidationMethod(props),
    handleSubmit(values, { props }) {
      props.setIsSpinner(true);
      props.handleSignUp(values);
    },
  }),
)(SignupForm);
