import React, { useState, useEffect, useRef, useCallback, lazy } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import { retry } from "../utils/retryloadable";
import NewLogo from "../assets/images/header-menu/flymya_mobile_logo.svg";
import ReactFlags from "../components/inputs/ReactFlags";
import FlightProgressbar from "../components/progressbar/FlightProgressbar";
import BusProgressbar from "../components/progressbar/BusProgressbar";
import MediaQuery from "react-responsive";
import { ContentfulData, getDataContentfulId } from "../utils/contentfulUtil";
import GorgiasIcon from "../components/GorgiasIcon";
import PhoneIcon from "../assets/images/phone-icon-2.svg";
import AgentLoginMenu from "./AgentLoginMenu";
import { triggerEmail } from "../utils/gorgiasTicket";
import { isMobile } from "react-device-detect";
import { checkInWeb, checkInApp } from "../utils/checkurl";
import { VIBER_IN_WEB, CITY_REWARD_IN_WEB } from "../config/inwebclients";
import useResultPageB2BTopUp from "../hooks/resultPageB2BTopUpHook";
import {
  CC_PHONENO_LINK,
  IS_B2B,
  IS_CUSTOMER,
  LOGIN_PEOPLE_COMPANY,
} from "../config/constants";
import CityRewardInWebLogo from "../assets/images/city-reward-logo.png";
import { getClientTag } from "../utils/checkurl";
import { useTheme } from "@mui/material/styles";
import { isAuthenticated } from "../lib/auth";
import { CHECK_AUTH, FORCE_LOGOUT } from "../queries/loginuserquery";
import { clientV3 } from "../utils/v3utils";
import { removeFromLocalStorage } from "../utils/logoututil";
import LoginSignupModal from "./login/LoginSignupModal";
import { FIREBASE_LOGIN_QUERY } from "../queries/loginquery";
import { setToLocalStorage, storeUserProfileInfo } from "../utils/loginutils";
import {
  gtmB2BClassType,
  gtmB2BLogin,
  gtmB2CLogin,
} from "../utils/gtmDataLayer";
import OfflineInternationalMsg from "../components/modal/OfflineInternationalMsg";
import PhoneMailIcon from "../assets/images/Phone&Mail_black_new.svg";
import PhoneMailIconWhite from "../assets/images/Phone&Mail_White_new.svg";

const Banner = lazy(() =>
  retry(() => import("../components/CovidAlertBanner")),
);

const PageHeader = ({
  pageName,
  selectedLanguage,
  pageContent,
  imageContent,
  pathName,
  isInApp,
  isInWeb,
  pageType,
  gorgiasInfo = null,
  showLogin,
  setLoginSignupPopupShow,
  page,
  showModal,
  logo,
}) => {
  const [isShow, setIsShow] = useState(false);
  const [isMenuShow, setIsMenuShow] = useState(false);
  const [isForget, setIsForget] = useState(false);
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [isSpinner, setIsSpinner] = useState(false);
  const [isCreatedSpinner, setIsCreatedSpinner] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isDim, setIsDim] = useState(false);
  const [isCheck, setIsCheck] = useState(null);
  const [flag, setFlag] = useState(false);
  const location = useLocation();
  const clientTag = getClientTag(window.location.search);
  const [showContactUsModal, setShowContactUsModal] = useState(false);
  const [flightMsg, setFlightMsg] = useState(null);
  //const [isInWeb] = useState(checkInWeb(window.location.pathname));
  const [
    showB2BAccountBlockContactDetail,
    setShowB2BAccountBlockContactDetail,
  ] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);

  const { removeNextSearchParam } = useResultPageB2BTopUp({});
  const theme = useTheme();
  const navigate = useNavigate();
  const mounted = useRef();

  const handleLogin = (emailVal, passVal) => {
    setIsSpinner(true);
    setIsDim(true);
    clientV3
      .mutate({
        mutation: FIREBASE_LOGIN_QUERY,
        variables: {
          input: {
            username: emailVal && emailVal !== "" ? emailVal : loginEmail,
            password: passVal && passVal !== "" ? passVal : loginPassword,
            device_type: "website",
            oneclick: false,
            client:
              clientTag && clientTag !== ""
                ? clientTag
                : isMobile
                ? "default"
                : "default",
          },
        },
      })
      .then((res) => {
        storeUserProfileInfo(res);
        setToLocalStorage(res);
        //if user is in-app after login redirect to in-app home page. else normal web home page
        if (isInApp) {
          window.location.href = `/${selectedLanguage}/in-app${window.location.search}`;
        } else if (isInWeb) {
          window.location.href = `/${selectedLanguage}/in-web${window.location.search}`;
        } else {
          window.location.href = `/${selectedLanguage}${window.location.search}`;
        }
        localStorage.getItem(IS_CUSTOMER) === "yes"
          ? gtmB2CLogin(true)
          : gtmB2BLogin(true);
        localStorage.getItem(IS_B2B) === "yes" &&
          gtmB2BClassType(
            JSON.parse(localStorage.getItem(LOGIN_PEOPLE_COMPANY)) !== null &&
              JSON.parse(localStorage.getItem(LOGIN_PEOPLE_COMPANY))
                .class_type &&
              JSON.parse(localStorage.getItem(LOGIN_PEOPLE_COMPANY)).class_type
                .id,
          );
      })
      .catch((err) => {
        console.log(err);
        const isAccountClose = err.message.includes(
          " Your account is temporarily closed.",
        );

        const errMsg = isAccountClose
          ? "Your account is temporarily closed. Please contact our partnership team for any assistance."
          : "The user credentials were incorrect! please try again.";

        setShowB2BAccountBlockContactDetail(isAccountClose);

        setErrorMsg(errMsg);
        setIsSpinner(false);
        setIsDim(false);
      });
  };

  const checkAuth = useCallback(() => {
    clientV3
      .mutate({
        mutation: CHECK_AUTH,
        fetchPolicy: "network-only",
      })
      .catch((err) => {
        console.log(err);
        forceLogout();
      });
  }, []);
  const forceLogout = () => {
    clientV3
      .mutate({
        mutation: FORCE_LOGOUT,
        fetchPolicy: "network-only",
        variables: {
          input: {
            id: localStorage.getItem("userId"),
          },
        },
      })
      .then((res) => {
        removeFromLocalStorage();
      })
      .catch((err) => {
        console.log(err);
        removeFromLocalStorage();
      });
  };

  useEffect(() => {
    setIsMenuShow(showLogin);
    setTimeout(() => {
      localStorage.getItem("clientActive") === "false" && navigate(`/en`);
    }, 500);
    if (!flag) {
      isAuthenticated() && checkAuth();
      setFlag(true);
    }
  }, [checkAuth, flag, showLogin]);

  if (
    (pageName === "HotelCustomerPage" ||
      pageName === "HotelPaymentPage" ||
      pageName === "FlightSearch") &&
    gorgiasInfo !== null
  ) {
    localStorage.setItem("gorgiasInfo", JSON.stringify(gorgiasInfo));
  }

  const sendGorgiaMessage = () => {
    localStorage.setItem("gorgiasPageInfo", "");
    window &&
      window.GorgiasChat &&
      window.GorgiasChat.init().then(function () {
        window.GorgiasChat.open();
        let pageinfo = localStorage.getItem("gorgiasPageInfo");
        if (pageinfo !== "") {
          window.location.href = `javascript:GorgiasChat.sendMessage(${JSON.stringify(
            pageinfo,
          )})`;
        }
      });
  };

  return (
    <>
      <Banner isInWeb={isInWeb} pageContent={pageContent} />
      <Grid item xs={12} className="header">
        <Grid
          container
          style={
            isMobile
              ? { position: "relative", paddingInline: "15px" }
              : { position: "relative" }
          }
          className="homeContainer"
        >
          {isMobile && pageName && pageName.includes("Hotel") ? (
            ""
          ) : (
            <Grid
              style={
                pageName &&
                (pageName.includes("B2BPage") ||
                  pageName.includes("ProfilePage"))
                  ? { maxWidth: "none" }
                  : {}
              }
              item
              sm={
                pageName && pageName.includes("Hotel")
                  ? 2
                  : pageName &&
                    pageName.includes("B2BPage") &&
                    pageName.includes("ProfilePage")
                  ? 1
                  : 2
              }
              md={
                pageName && pageName.includes("Hotel")
                  ? 2
                  : pageName &&
                    pageName.includes("B2BPage") &&
                    pageName.includes("ProfilePage")
                  ? 1
                  : 2
              }
              xs={
                pageName === "PrivacyPolicy" || pageName === "ResetPassword"
                  ? 10
                  : pageName && pageName.includes("Hotel")
                  ? 4
                  : 1
              }
              className={
                pageName === "PrivacyPolicy" || pageName === "ResetPassword"
                  ? "headerLogoContainer privacyPolicyHeader"
                  : "headerLogoContainer"
              }
            >
              <Link
                to={{
                  pathname:
                    pageName && pageName.includes("Hotel")
                      ? "/" +
                        selectedLanguage +
                        `${
                          checkInApp(location.pathname)
                            ? "/in-app"
                            : checkInWeb(location.pathname)
                            ? "/in-web"
                            : ""
                        }` +
                        "/hotel"
                      : "/" +
                        selectedLanguage +
                        `${
                          checkInApp(location.pathname)
                            ? "/in-app"
                            : checkInWeb(location.pathname)
                            ? "/in-web"
                            : ""
                        }`,
                  // search: window.location.search
                  search: removeNextSearchParam(),
                }}
                className="pageHeaderLogo"
                data-contentful-id={getDataContentfulId("Blog Logo")}
              >
                <img
                  src={NewLogo}
                  className={
                    pageName &&
                    (pageName.includes("B2BPage") ||
                      pageName.includes("ProfilePage"))
                      ? ""
                      : ""
                  }
                  alt="Flymya.com"
                />
                {logo && localStorage.getItem("clientActive") === "true" && (
                  <img
                    style={{
                      borderLeft: `2px solid ${theme.palette.primary.main}`,
                    }}
                    src={logo}
                    alt="Viber"
                    className="logo CRLogo"
                    width="50"
                    height="30"
                  />
                )}
              </Link>
            </Grid>
          )}

          {!pageType && <div style={{ flexGrow: 1, height: "60px" }}></div>}

          {pageType &&
            pageType !== "Bus" &&
            pageName !== "PrivacyPolicy" &&
            pageName !== "ResetPassword" &&
            pageName !== "B2BPage" &&
            pageName !== "ProfilePage" && (
              <FlightProgressbar
                pageContent={pageContent}
                pageName={pageName}
                selectedLanguage={selectedLanguage}
              />
            )}
          {pageType &&
            pageType === "Bus" &&
            pageName !== "PrivacyPolicy" &&
            pageName !== "ResetPassword" &&
            pageName !== "B2BPage" &&
            pageName !== "Promotions" &&
            pageName !== "ProfilePage" && (
              <BusProgressbar
                pageContent={pageContent}
                pageName={pageName}
                selectedLanguage={selectedLanguage}
              />
            )}
          {(pageName === "PrivacyPolicy" ||
            pageName === "ResetPassword" ||
            pageName === "Promotions" ||
            pageName === "HotelSearchResult" ||
            pageName === "HotelDetailPage" ||
            pageName === "B2BPage" ||
            pageName === "ProfilePage" ||
            pageName === "Car") && (
            <MediaQuery minWidth={780}>
              <Grid
                item
                xs={2}
                sm={
                  pageName === "PrivacyPolicy" ||
                  pageName === "ResetPassword" ||
                  pageName === "HotelSearchResult" ||
                  pageName === "HotelDetailPage"
                    ? 7
                    : pageName === "B2BPage" ||
                      pageName === "ProfilePage" ||
                      pageName === "Promotions"
                    ? 6
                    : 4
                }
                md={
                  pageName === "PrivacyPolicy" ||
                  pageName === "ResetPassword" ||
                  pageName === "HotelSearchResult" ||
                  pageName === "HotelDetailPage"
                    ? 7
                    : pageName === "B2BPage" ||
                      pageName === "ProfilePage" ||
                      pageName === "Promotions"
                    ? 7
                    : 4
                }
                className={
                  selectedLanguage === "my-mm"
                    ? "contactUsMenu mmContactUsMenu"
                    : "contactUsMenu"
                }
              >
                <Link
                  id="contactUsBus"
                  to={{
                    pathname: isInApp
                      ? "/" +
                        (selectedLanguage ? selectedLanguage : "en") +
                        "/in-app/promotion"
                      : "/" +
                        (selectedLanguage ? selectedLanguage : "en") +
                        "/promotion",
                    search: window.location.search,
                  }}
                >
                  <ContentfulData content={pageContent} id="promotion">
                    Promos
                  </ContentfulData>
                </Link>
                <Link
                  id="contactUsBus"
                  to={{
                    pathname: isInApp
                      ? "/" +
                        (selectedLanguage ? selectedLanguage : "en") +
                        "/in-app/balloon"
                      : "/" +
                        (selectedLanguage ? selectedLanguage : "en") +
                        "/balloon",
                    search: window.location.search,
                  }}
                >
                  <ContentfulData content={pageContent} id="balloon">
                    Balloons
                  </ContentfulData>
                </Link>
                <Link
                  id="contactUsBus"
                  to={{
                    pathname: isInApp
                      ? "/" +
                        (selectedLanguage ? selectedLanguage : "en") +
                        "/in-app/car-rental"
                      : "/" +
                        (selectedLanguage ? selectedLanguage : "en") +
                        "/car-rental",
                    search: window.location.search,
                  }}
                >
                  <ContentfulData content={pageContent} id="carRental">
                    Cars
                  </ContentfulData>
                </Link>

                {/* <Link
                id="contactUsBalloon"
                to={{
                  pathname: isInApp
                    ? "/" +
                      (selectedLanguage ? selectedLanguage : "en") +
                      "/in-app/balloons"
                    : "/" +
                      (selectedLanguage ? selectedLanguage : "en") +
                      "/balloons",
                  search: window.location.search
                }}
                data-contentful-id={getDataContentfulId("balloon")}
              >
                <ContentfulData content={pageContent} id="balloon">
                  Balloons
                </ContentfulData>
              </Link> */}
                <Link
                  id="contactUsHotel"
                  to={{
                    pathname: isInApp
                      ? "/" +
                        (selectedLanguage ? selectedLanguage : "en") +
                        "/in-app/hotel"
                      : "/" +
                        (selectedLanguage ? selectedLanguage : "en") +
                        "/hotel",
                    search: window.location.search,
                  }}
                  data-contentful-id={getDataContentfulId("hotel")}
                >
                  <ContentfulData content={pageContent} id="hotel">
                    Hotels
                  </ContentfulData>
                </Link>
                <Link
                  id="contactUsBus"
                  to={{
                    pathname: isInApp
                      ? "/" +
                        (selectedLanguage ? selectedLanguage : "en") +
                        "/in-app/bus"
                      : "/" +
                        (selectedLanguage ? selectedLanguage : "en") +
                        "/bus",
                    search: window.location.search,
                  }}
                  data-contentful-id={getDataContentfulId("bus")}
                >
                  <ContentfulData content={pageContent} id="bus">
                    Buses
                  </ContentfulData>
                </Link>
                <Link
                  id="contactUsFlight"
                  to={{
                    pathname: isInApp
                      ? "/" +
                        (selectedLanguage ? selectedLanguage : "en") +
                        "/in-app"
                      : "/" + (selectedLanguage ? selectedLanguage : "en"),
                    search: window.location.search,
                  }}
                  data-contentful-id={getDataContentfulId("flight")}
                >
                  <ContentfulData content={pageContent} id="flight">
                    Flights
                  </ContentfulData>
                </Link>
              </Grid>
            </MediaQuery>
          )}
          {pageName === "Promotions" && (
            <MediaQuery maxWidth={780}>
              <Grid
                item
                xs={10}
                sm={
                  pageName === "PrivacyPolicy" ||
                  pageName === "ResetPassword" ||
                  pageName === "HotelSearchResult" ||
                  pageName === "HotelDetailPage"
                    ? 7
                    : pageName === "B2BPage" ||
                      pageName === "ProfilePage" ||
                      pageName === "Promotions"
                    ? 7
                    : 4
                }
                md={
                  pageName === "PrivacyPolicy" ||
                  pageName === "ResetPassword" ||
                  pageName === "HotelSearchResult" ||
                  pageName === "HotelDetailPage"
                    ? 7
                    : pageName === "B2BPage" ||
                      pageName === "ProfilePage" ||
                      pageName === "Promotions"
                    ? 7
                    : 4
                }
                className={"contactUsMenu mobContactUsMenu"}
              >
                <Link
                  id="contactUsBus"
                  to={{
                    pathname: isInApp
                      ? "/" +
                        (selectedLanguage ? selectedLanguage : "en") +
                        "/in-app"
                      : "/" + (selectedLanguage ? selectedLanguage : "en"),
                    search: window.location.search,
                  }}
                >
                  Flights
                </Link>
                <Link
                  id="contactUsBalloon"
                  to={{
                    pathname: isInApp
                      ? "/" +
                        (selectedLanguage ? selectedLanguage : "en") +
                        "/in-app/bus"
                      : "/" +
                        (selectedLanguage ? selectedLanguage : "en") +
                        "/bus",
                    search: window.location.search,
                  }}
                  data-contentful-id={getDataContentfulId("balloon")}
                >
                  <ContentfulData content={pageContent} id="balloon">
                    Bus
                  </ContentfulData>
                </Link>
                <Link
                  id="contactUsHotel"
                  to={{
                    pathname: isInApp
                      ? "/" +
                        (selectedLanguage ? selectedLanguage : "en") +
                        "/in-app/hotel"
                      : "/" +
                        (selectedLanguage ? selectedLanguage : "en") +
                        "/hotel",
                    search: window.location.search,
                  }}
                  data-contentful-id={getDataContentfulId("hotel")}
                >
                  <ContentfulData content={pageContent} id="hotel">
                    Hotels
                  </ContentfulData>
                </Link>
                {!isInApp && (
                  <Link
                    id="contactUsBus"
                    to={{
                      pathname: isInApp
                        ? "/" +
                          (selectedLanguage ? selectedLanguage : "en") +
                          "/in-app/car-rental"
                        : "/" +
                          (selectedLanguage ? selectedLanguage : "en") +
                          "/car-rental",
                      search: window.location.search,
                    }}
                  >
                    <ContentfulData content={pageContent} id="carRental">
                      Cars
                    </ContentfulData>
                  </Link>
                )}
                {!isInApp && (
                  <Link
                    id="contactUsBus"
                    to={{
                      pathname: isInApp
                        ? "/" +
                          (selectedLanguage ? selectedLanguage : "en") +
                          "/in-app/balloon"
                        : "/" +
                          (selectedLanguage ? selectedLanguage : "en") +
                          "/balloon",
                      search: window.location.search,
                    }}
                  >
                    <ContentfulData content={pageContent} id="balloon">
                      Balloons
                    </ContentfulData>
                  </Link>
                )}
              </Grid>
            </MediaQuery>
          )}
          {(pageName && pageName.includes("Hotel")) ||
            (pageName && pageName.includes("FlightSearch")) ||
            (pageName && pageName.includes("CustomerDetails")) ||
            (pageName &&
              (pageName.includes("B2BPage") ||
                pageName.includes("ProfilePage")) && (
                <MediaQuery maxWidth={780}>
                  <div
                    style={
                      pageName.includes("B2BPage") ||
                      pageName.includes("ProfilePage")
                        ? {
                            display: "flex",
                            alignItems: "center",
                            marginRight: "12px",
                          }
                        : pageName.includes("Flight") ||
                          pageName === "CustomerDetails"
                        ? {
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginRight: "18px",
                          }
                        : {
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginRight: "18px",
                          }
                    }
                  >
                    <a href={CC_PHONENO_LINK}>
                      <div
                        onClick={() => {
                          if (
                            pageName.includes("B2BPage") ||
                            pageName.includes("ProfilePage")
                          )
                            return;
                          let product = pageName.includes("Hotel")
                            ? "Hotel"
                            : "Flight";
                          triggerEmail(product, gorgiasInfo, isInApp);
                        }}
                      >
                        <img
                          className="menuPhone"
                          src={PhoneIcon}
                          alt=""
                          style={{
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      </div>
                    </a>
                  </div>
                  {pageName &&
                    (!pageName.includes("B2BPage") ||
                      !pageName.includes("ProfilePage")) && <GorgiasIcon />}
                </MediaQuery>
              ))}

          <div
            className="wrap"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "10px",
              paddingRight: "15px",
            }}
          >
            <div
              style={{ width: "25px", height: "25px", paddingBottom: "2px" }}
            >
              <img
                src={
                  PhoneMailIcon
                  // !isInApp
                  //   ? PhoneMailIcon
                  //   : clientTag === "wavemoney" ||
                  //     clientTag === "kbz" ||
                  //     clientTag === "onepay"
                  //   ? PhoneMailIconWhite
                  //   : PhoneMailIcon
                }
                onClick={() => {
                  setShowContactUsModal(true);
                }}
                alt="contact-us-icon"
                style={{ width: "25px", height: "25px" }}
              />
              <OfflineInternationalMsg
                sendGorgiaMessage={sendGorgiaMessage}
                // searchInfo={flightInfo}
                productDetail={flightMsg}
                showContactUsModal={showContactUsModal}
                setShowContactUsModal={setShowContactUsModal}
              />
            </div>
            {pageName !== "ResetPassword" && (
              <div className={"language-select"}>
                <ReactFlags
                  className="flightResult"
                  selectedLanguage={selectedLanguage}
                  pageName={pageName}
                  pathName={pathName}
                />
              </div>
            )}

            {
              // (!checkInWeb(window.location.pathname) ||
              //   (checkInWeb(window.location.pathname) &&
              //     clientTag === CITY_REWARD_IN_WEB) ||
              //   (checkInWeb(window.location.pathname) &&
              //     clientTag === VIBER_IN_WEB)) &&
              (pageName === "B2BPage" ||
                pageName === "ProfilePage" ||
                pageName === "CustomerDetails" ||
                pageName === "FlightPaymentPage" ||
                pageName === "FlightConfirmation" ||
                pageName === "BusSearch" ||
                pageName === "BusSeat" ||
                pageName === "BusContactInfo" ||
                pageName === "HotelSearchResult" ||
                pageName === "HotelDetailPage" ||
                pageName === "HotelCustomerPage" ||
                pageName === "HotelPaymentPage" ||
                pageName === "FlightSearch" ||
                pageName === "InsiderDashboard" ||
                pageName === "EticketDownload") && (
                <Grid
                  item
                  // xs={1}
                  //sm={3}
                  // sm={2}
                  className="login-company"
                >
                  <MediaQuery minWidth={781}>
                    <div>
                      <AgentLoginMenu
                        selectedLanguage={selectedLanguage}
                        setIsShow={setIsShow}
                        isShow={isShow}
                        pageContent={pageContent}
                      />
                    </div>
                  </MediaQuery>
                  <MediaQuery maxWidth={780}>
                    <div
                      style={
                        pageName === "B2BPage" || pageName === "ProfilePage"
                          ? {}
                          : { marginTop: "0" }
                      }
                    >
                      <AgentLoginMenu
                        selectedLanguage={selectedLanguage}
                        isMobile={true}
                        setIsShow={setIsShow}
                        isShow={isShow}
                        pageContent={pageContent}
                      />
                    </div>
                  </MediaQuery>
                </Grid>
              )
            }
          </div>
        </Grid>
      </Grid>
      <LoginSignupModal
        isInApp={isInApp}
        isInWeb={isInWeb}
        isShow={isShow}
        setIsShow={setIsShow}
        isDim={isDim}
        setIsDim={setIsDim}
        loginEmail={loginEmail}
        setLoginEmail={setLoginEmail}
        loginPassword={loginPassword}
        setLoginPassword={setLoginPassword}
        handleLogin={handleLogin}
        errorMsg={errorMsg}
        setErrorMsg={setErrorMsg}
        isForget={isForget}
        setIsForget={setIsForget}
        isSpinner={isSpinner}
        setIsSpinner={setIsSpinner}
        // setFieldValue={setFieldValue}
        // handleContinue={props.handleSubmit}
        setIsCheck={setIsCheck}
        page="home"
        originalPage="flightResult"
        setInfoOpen={setInfoOpen}
        //regValues={props.values}
        //createdResPeople={props.createdResPeople}
      />
    </>
  );
};

export default PageHeader;
