import React, { useState, useRef, useEffect } from "react";
import { createTheme } from "@mui/material/styles";
import { clientV3 } from "./utils/v3utils";
import { getClientTag } from "./utils/checkurl";

export const waveTheme = createTheme({
  components: {
    MuiGrid: {
      styleOverrides: {
        root: {
          "& .highlight": {
            background: localStorage.getItem("primaryColor")
              ? localStorage.getItem("primaryColor")
              : "#1692c5",
            color: "#fff",
          },
          "& .detailBox:hover": {
            border: localStorage.getItem("primaryColor")
              ? `1px solid ${localStorage.getItem("primaryColor")}`
              : "1px solid #1692c5",
          },
          "& .fullCircle,.arrivalPoint": {
            backgroundColor: localStorage.getItem("primaryColor")
              ? localStorage.getItem("primaryColor")
              : "#1692c5",
            border: localStorage.getItem("primaryColor")
              ? `1px solid ${localStorage.getItem("primaryColor")}`
              : "1px solid #1692c5",
          },
          "& .circle,.departurePoint": {
            border: localStorage.getItem("primaryColor")
              ? `1px solid ${localStorage.getItem("primaryColor")}`
              : "1px solid #1692c5",
          },
          "& .covidInfoContainer": {
            border: localStorage.getItem("primaryColor")
              ? `1px solid ${localStorage.getItem("primaryColor")}`
              : "1px solid #1692c5",
          },
          "& .CalendarDay__selected,.selectedMultiDateBox": {
            backgroundColor: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#1692c5 !important",
          },
          "& .DateInput_fangStroke": {
            fill: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#1692c5 !important",
            stroke: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#1692c5 !important",
          },
          "& .CalendarDay:hover,.popupSearch,.domesticInternationalTabsBtnActive":
            {
              backgroundColor: localStorage.getItem("primaryColor")
                ? `${localStorage.getItem("primaryColor")} !important`
                : "#1692c5 !important",
            },
          "& .airportSearchIcon,.nextDate:before,.previousDate:before": {
            color: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#1692c5 !important",
          },
          "& .airportNames:hover": {
            color: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#1692c5 !important",
            cursor: "pointer",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            background: "#f3f3f3",
            color: "#dadada",
          },
          "&.btnEditSearch": {
            color: "#fee337",
            border: localStorage.getItem("secondaryColor")
              ? `1px solid ${localStorage.getItem("secondaryColor")} !important`
              : "1px solid #fee337 !important",
          },
          "&.btnEditSearch:hover": {
            color: "#fff",
            background: localStorage.getItem("secondaryColor")
              ? `${localStorage.getItem("secondaryColor")} !important`
              : "#fee337 !important",
          },
          "&.choosePackBtn:hover": {
            backgroundColor: localStorage.getItem("primaryColor")
              ? localStorage.getItem("primaryColor")
              : "#1692c5",
            color: "#fff",
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: localStorage.getItem("primaryColor")
        ? localStorage.getItem("primaryColor")
        : "#1692c5",
      contrastText: "#fff",
      bg: localStorage.getItem("primaryColor")
        ? localStorage.getItem("primaryColor")
        : "#1692c5",
    },
    info: {
      main: localStorage.getItem("secondaryColor")
        ? localStorage.getItem("secondaryColor")
        : "#fee337",
      contrastText: "#231f20",
    },
    secondary: {
      main: localStorage.getItem("thirdColor")
        ? localStorage.getItem("thirdColor")
        : "#e6eaed",
      contrastText: "#231f20",
    },
  },
});

export const kbzTheme = createTheme({
  components: {
    MuiGrid: {
      styleOverrides: {
        root: {
          "& .highlight": {
            background: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#0b55a8",
            color: "#fff",
          },
          "& .detailBox:hover": {
            border: localStorage.getItem("primaryColor")
              ? `1px solid ${localStorage.getItem("primaryColor")} !important`
              : "1px solid #0b55a8",
          },
          "& .fullCircle,.arrivalPoint": {
            backgroundColor: localStorage.getItem("primaryColor")
              ? localStorage.getItem("primaryColor")
              : "#0b55a8",
            border: localStorage.getItem("primaryColor")
              ? `1px solid ${localStorage.getItem("primaryColor")} !important`
              : "1px solid #0b55a8",
          },
          "& .circle,.departurePoint": {
            border: localStorage.getItem("primaryColor")
              ? `1px solid ${localStorage.getItem("primaryColor")}`
              : "1px solid #0b55a8",
          },
          "& .covidInfoContainer": {
            border: "1px solid #0b55a8",
          },
          "& .CalendarDay__selected,.selectedMultiDateBox": {
            backgroundColor: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#0b55a8 !important",
          },
          "& .DateInput_fangStroke": {
            fill: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#0b55a8 !important",
            stroke: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#0b55a8 !important",
          },
          "& .CalendarDay:hover,.popupSearch,.domesticInternationalTabsBtnActive":
            {
              backgroundColor: localStorage.getItem("primaryColor")
                ? `${localStorage.getItem("primaryColor")} !important`
                : "#0b55a8 !important",
            },
          "& .airportSearchIcon,.nextDate:before,.previousDate:before": {
            color: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#0b55a8 !important",
          },
          "& .airportNames:hover": {
            color: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#0b55a8 !important",
            cursor: "pointer",
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            background: localStorage.getItem("primaryColor")
              ? localStorage.getItem("primaryColor")
              : "#0b55a8",
            color: "#fff",
          },
          "&.btnEditSearch": {
            color: "#052a54",
            border: localStorage.getItem("secondaryColor")
              ? `1px solid ${localStorage.getItem("secondaryColor")} !important`
              : "1px solid #052a54 !important",
          },
          "&.btnEditSearch:hover": {
            color: "#fff",
            background: localStorage.getItem("secondaryColor")
              ? `${localStorage.getItem("secondaryColor")} !important`
              : "#052a54 !important",
          },
          "&.choosePackBtn:hover": {
            backgroundColor: localStorage.getItem("primaryColor")
              ? localStorage.getItem("primaryColor")
              : "#0b55a8",
            color: "#fff",
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: localStorage.getItem("primaryColor")
        ? localStorage.getItem("primaryColor")
        : "#0b55a8",
      bg: localStorage.getItem("primaryColor")
        ? localStorage.getItem("primaryColor")
        : "#0b55a8",
      contrastText: "#fff",
    },
    info: {
      main: localStorage.getItem("secondaryColor")
        ? localStorage.getItem("secondaryColor")
        : "#052a54",
      contrastText: "#fff",
    },
    secondary: {
      main: localStorage.getItem("thirdColor")
        ? localStorage.getItem("thirdColor")
        : "#e6eaed",
      contrastText: "#231f20",
      "& .btnEditSearch": {
        color: localStorage.getItem("thirdColor")
          ? localStorage.getItem("thirdColor")
          : "#e6eaed",
      },
    },
  },
});
export const theme = createTheme({
  components: {
    MuiGrid: {
      styleOverrides: {
        root: {
          "& .highlight": {
            background: "#00aeef",
            color: "#fff",
          },
          "& .detailBox:hover": {
            border: "1px solid #00aeef",
          },
          "& .fullCircle,.arrivalPoint": {
            backgroundColor: "#00aeef",
            border: "1px solid #00aeef",
          },
          "& .circle,.departurePoint": {
            border: "1px solid #00aeef",
          },
          "& .covidInfoContainer": {
            border: "1px solid #00aeef",
          },
          "& .CalendarDay__selected,.selectedMultiDateBox": {
            backgroundColor: "#00aeef !important",
          },
          "& .DateInput_fangStroke": {
            fill: "#00aeef !important",
            stroke: "#00aeef !important",
          },
          "& .CalendarDay:hover,.popupSearch,.domesticInternationalTabsBtnActive":
            {
              backgroundColor: "#00aeef !important",
            },
          "& .airportSearchIcon,.nextDate:before,.previousDate:before": {
            color: "#00aeef !important",
          },
          "& .airportNames:hover": {
            color: "#00aeef !important",
            cursor: "pointer",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            background: "#00aeef",
            color: "#fff",
          },
          "&.btnEditSearch": {
            color: "#fc9d00",
            border: "1px solid #fc9d00 !important",
          },
          "&.btnEditSearch:hover": {
            color: "#fff",
            backgroundColor: "#fc9d00 !important",
          },
          "&.choosePackBtn:hover": {
            backgroundColor: "#00aeef",
            color: "#fff",
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#00aeef",
      bg: "#00aeef",
      contrastText: "#fff",
    },
    info: {
      main: "#fc9d00",
      contrastText: "#fff",
    },
    secondary: {
      main: "#e6eaed",
      contrastText: "#231f20",
    },
  },
});
export const viberTheme = createTheme({
  components: {
    MuiGrid: {
      styleOverrides: {
        root: {
          "& .highlight": {
            background: localStorage.getItem("primaryColor")
              ? localStorage.getItem("primaryColor")
              : "#794aff",
            color: "#fff",
          },
          "& .detailBox:hover": {
            border: localStorage.getItem("primaryColor")
              ? `1px solid ${localStorage.getItem("primaryColor")}`
              : "1px solid #794aff",
          },
          "& .fullCircle,.arrivalPoint": {
            backgroundColor: localStorage.getItem("primaryColor")
              ? localStorage.getItem("primaryColor")
              : "#794aff",
            border: localStorage.getItem("primaryColor")
              ? `1px solid ${localStorage.getItem("primaryColor")}`
              : "1px solid #794aff",
          },
          "& .circle,.departurePoint": {
            border: localStorage.getItem("primaryColor")
              ? `1px solid ${localStorage.getItem("primaryColor")}`
              : "1px solid #794aff",
          },
          "& .covidInfoContainer": {
            border: localStorage.getItem("primaryColor")
              ? `1px solid ${localStorage.getItem("primaryColor")}`
              : "1px solid #794aff",
          },
          "& .CalendarDay__selected,.selectedMultiDateBox": {
            backgroundColor: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#794aff !important",
          },
          "& .DateInput_fangStroke": {
            fill: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#794aff !important",
            stroke: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#794aff !important",
          },
          "& .CalendarDay:hover,.popupSearch,.domesticInternationalTabsBtnActive":
            {
              backgroundColor: localStorage.getItem("primaryColor")
                ? `${localStorage.getItem("primaryColor")} !important`
                : "#794aff !important",
            },
          "& .airportSearchIcon,.nextDate:before,.previousDate:before": {
            color: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#794aff !important",
          },
          "& .airportNames:hover": {
            color: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#794aff !important",
            cursor: "pointer",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            background: localStorage.getItem("primaryColor")
              ? localStorage.getItem("primaryColor")
              : "#794aff",
            color: "#fff",
          },
          "&.btnEditSearch": {
            color: localStorage.getItem("secondaryColor")
              ? localStorage.getItem("secondaryColor")
              : "#4e4879",
            border: localStorage.getItem("secondaryColor")
              ? `1px solid ${localStorage.getItem("secondaryColor")} !important`
              : "1px solid #4e4879 !important",
          },
          "&.btnEditSearch:hover": {
            color: "#fff",
            background: localStorage.getItem("secondaryColor")
              ? `${localStorage.getItem("secondaryColor")} !important`
              : "#4e4879 !important",
          },
          "&.choosePackBtn:hover": {
            backgroundColor: localStorage.getItem("primaryColor")
              ? localStorage.getItem("primaryColor")
              : "#794aff",
            color: "#fff",
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: localStorage.getItem("primaryColor")
        ? localStorage.getItem("primaryColor")
        : "#794aff",
      bg: localStorage.getItem("primaryColor")
        ? localStorage.getItem("primaryColor")
        : "#794aff",
      contrastText: "#fff",
    },
    info: {
      main: localStorage.getItem("secondaryColor")
        ? localStorage.getItem("secondaryColor")
        : "#4e4879",
      contrastText: "#fff",
    },
    secondary: {
      main: localStorage.getItem("thirdColor")
        ? localStorage.getItem("thirdColor")
        : "#e6eaed",
      contrastText: "#231f20",
    },
  },
});
export const CRTheme = createTheme({
  components: {
    MuiGrid: {
      styleOverrides: {
        root: {
          "& .highlight": {
            background: localStorage.getItem("primaryColor")
              ? localStorage.getItem("primaryColor")
              : "#6e51a2",
            color: "#fff",
          },
          "& .detailBox:hover": {
            border: localStorage.getItem("primaryColor")
              ? `1px solid${localStorage.getItem("primaryColor")}`
              : "1px solid #6e51a2",
          },
          "& .fullCircle,.arrivalPoint": {
            backgroundColor: "#6e51a2",
            border: localStorage.getItem("primaryColor")
              ? `1px solid${localStorage.getItem("primaryColor")}`
              : "1px solid #6e51a2",
          },
          "& .circle,.departurePoint": {
            border: localStorage.getItem("primaryColor")
              ? `1px solid${localStorage.getItem("primaryColor")}`
              : "1px solid #6e51a2",
          },
          "& .covidInfoContainer": {
            border: localStorage.getItem("primaryColor")
              ? `1px solid${localStorage.getItem("primaryColor")}`
              : "1px solid #6e51a2",
          },
          "& .CalendarDay__selected,.selectedMultiDateBox": {
            backgroundColor: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#6e51a2 !important",
          },
          "& .DateInput_fangStroke": {
            fill: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#6e51a2 !important",
            stroke: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#6e51a2 !important",
          },
          "& .CalendarDay:hover,.popupSearch,.domesticInternationalTabsBtnActive":
            {
              backgroundColor: localStorage.getItem("primaryColor")
                ? `${localStorage.getItem("primaryColor")} !important`
                : "#6e51a2 !important",
            },
          "& .airportSearchIcon,.nextDate:before,.previousDate:before": {
            color: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#6e51a2 !important",
          },
          "& .airportNames:hover": {
            color: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#6e51a2 !important",
            cursor: "pointer",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            background: localStorage.getItem("primaryColor")
              ? localStorage.getItem("primaryColor")
              : "#6e51a2",
            color: "#fff",
          },
          "&.btnEditSearch": {
            color: localStorage.getItem("secondaryColor")
              ? localStorage.getItem("secondaryColor")
              : "#ffd41c",
            border: "1px solid #ffd41c !important",
          },
          "&.btnEditSearch:hover": {
            color: "#fff",
            backgroundColor: localStorage.getItem("secondaryColor")
              ? `${localStorage.getItem("secondaryColor")} !important`
              : "#ffd41c !important",
          },
          "&.choosePackBtn:hover": {
            backgroundColor: localStorage.getItem("primaryColor")
              ? localStorage.getItem("primaryColor")
              : "#6e51a2",
            color: "#fff",
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: localStorage.getItem("primaryColor")
        ? localStorage.getItem("primaryColor")
        : "#6e51a2",
      bg: localStorage.getItem("primaryColor")
        ? localStorage.getItem("primaryColor")
        : "#6e51a2",
      contrastText: "#fff",
    },
    info: {
      main: localStorage.getItem("secondaryColor")
        ? localStorage.getItem("secondaryColor")
        : "#ffd41c",
      contrastText: "#231f20",
    },
    secondary: {
      main: localStorage.getItem("thirdColor")
        ? localStorage.getItem("thirdColor")
        : "#e6eaed",
      contrastText: "#231f20",
    },
  },
});
export const onepayTheme = createTheme({
  components: {
    MuiGrid: {
      styleOverrides: {
        root: {
          "& .highlight": {
            background: localStorage.getItem("primaryColor")
              ? localStorage.getItem("primaryColor")
              : "#2e9d68",
            color: "#fff",
          },
          "& .detailBox:hover": {
            border: localStorage.getItem("primaryColor")
              ? `1px solid ${localStorage.getItem("primaryColor")}`
              : "1px solid #2e9d68",
          },
          "& .fullCircle,.arrivalPoint": {
            backgroundColor: localStorage.getItem("primaryColor")
              ? localStorage.getItem("primaryColor")
              : "#2e9d68",
            border: localStorage.getItem("primaryColor")
              ? `1px solid ${localStorage.getItem("primaryColor")}`
              : "1px solid #2e9d68",
          },
          "& .circle,.departurePoint": {
            border: localStorage.getItem("primaryColor")
              ? `1px solid ${localStorage.getItem("primaryColor")}`
              : "1px solid #2e9d68",
          },
          "& .covidInfoContainer": {
            border: localStorage.getItem("primaryColor")
              ? `1px solid ${localStorage.getItem("primaryColor")}`
              : "1px solid #2e9d68",
          },
          "& .CalendarDay__selected,.selectedMultiDateBox": {
            backgroundColor: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#2e9d68 !important",
          },
          "& .DateInput_fangStroke": {
            fill: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#2e9d68 !important",
            stroke: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#2e9d68 !important",
          },
          "& .CalendarDay:hover,.popupSearch,.domesticInternationalTabsBtnActive":
            {
              backgroundColor: localStorage.getItem("primaryColor")
                ? `${localStorage.getItem("primaryColor")} !important`
                : "#2e9d68 !important",
            },
          "& .airportSearchIcon,.nextDate:before,.previousDate:before": {
            color: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#2e9d68 !important",
          },
          "& .airportNames:hover": {
            color: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#2e9d68 !important",
            cursor: "pointer",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            background: localStorage.getItem("primaryColor")
              ? localStorage.getItem("primaryColor")
              : "#2e9d68",
            color: "#fff",
          },
          "&.btnEditSearch": {
            color: localStorage.getItem("primaryColor")
              ? localStorage.getItem("primaryColor")
              : "#2e9d68",
            border: localStorage.getItem("primaryColor")
              ? `1px solid ${localStorage.getItem("primaryColor")} !important`
              : "1px solid #2e9d68 !important",
          },
          "&.btnEditSearch:hover": {
            color: "#fff",
            backgroundColor: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#2e9d68 !important",
          },
          "&.choosePackBtn:hover": {
            backgroundColor: localStorage.getItem("primaryColor")
              ? localStorage.getItem("primaryColor")
              : "#2e9d68",
            color: "#fff",
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: localStorage.getItem("primaryColor")
        ? localStorage.getItem("primaryColor")
        : "#2e9d68",
      contrastText: "#fff",
      bg: localStorage.getItem("secondaryColor")
        ? localStorage.getItem("secondaryColor")
        : "#45acab",
    },
    info: {
      main: localStorage.getItem("primaryColor")
        ? localStorage.getItem("primaryColor")
        : "#2e9d68",
      contrastText: "#fff",
    },
    secondary: {
      main: localStorage.getItem("thirdColor")
        ? localStorage.getItem("thirdColor")
        : "#e6eaed",
      contrastText: "#231f20",
    },
  },
});
export const citizensPayTheme = createTheme({
  components: {
    MuiGrid: {
      styleOverrides: {
        root: {
          "& .highlight": {
            background: localStorage.getItem("primaryColor")
              ? localStorage.getItem("primaryColor")
              : "#ec008c",
            color: "#fff",
          },
          "& .detailBox:hover": {
            border: localStorage.getItem("primaryColor")
              ? `1px solid ${localStorage.getItem("primaryColor")}`
              : "1px solid #ec008c",
          },
          "& .fullCircle,.arrivalPoint": {
            backgroundColor: localStorage.getItem("primaryColor")
              ? localStorage.getItem("primaryColor")
              : "#ec008c",
            border: localStorage.getItem("primaryColor")
              ? `1px solid ${localStorage.getItem("primaryColor")}`
              : "1px solid #ec008c",
          },
          "& .circle,.departurePoint": {
            border: localStorage.getItem("primaryColor")
              ? `1px solid ${localStorage.getItem("primaryColor")}`
              : "1px solid #ec008c",
          },
          "& .covidInfoContainer": {
            border: localStorage.getItem("primaryColor")
              ? `1px solid ${localStorage.getItem("primaryColor")}`
              : "1px solid #ec008c",
          },
          "& .CalendarDay__selected,.selectedMultiDateBox": {
            backgroundColor: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#ec008c !important",
          },
          "& .DateInput_fangStroke": {
            fill: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#ec008c !important",
            stroke: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#ec008c !important",
          },
          "& .CalendarDay:hover,.popupSearch,.domesticInternationalTabsBtnActive":
            {
              backgroundColor: localStorage.getItem("primaryColor")
                ? `${localStorage.getItem("primaryColor")} !important`
                : "#ec008c !important",
            },
          "& .airportSearchIcon,.nextDate:before,.previousDate:before": {
            color: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#ec008c !important",
          },
          "& .airportNames:hover": {
            color: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")}`
              : "#ec008c !important",
            cursor: "pointer",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            background: "#2e9d68",
            color: "#fff",
          },
          "&.btnEditSearch": {
            color: localStorage.getItem("secondaryColor")
              ? localStorage.getItem("secondaryColor")
              : "#ec008c",
            border: localStorage.getItem("secondaryColor")
              ? `1px solid ${localStorage.getItem("secondaryColor")}`
              : "1px solid #ec008c !important",
          },
          "&.btnEditSearch:hover": {
            color: "#fff",
            backgroundColor: localStorage.getItem("secondaryColor")
              ? `1px solid ${localStorage.getItem("secondaryColor")}`
              : "#ec008c !important",
          },
          "&.choosePackBtn:hover": {
            backgroundColor: localStorage.getItem("primaryColor")
              ? localStorage.getItem("primaryColor")
              : "#ec008c",
            color: "#fff",
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: localStorage.getItem("primaryColor")
        ? localStorage.getItem("primaryColor")
        : "#ec008c",
      contrastText: "#fff",
    },
    info: {
      main: localStorage.getItem("secondaryColor")
        ? localStorage.getItem("secondaryColor")
        : "#ec008c",
      contrastText: "#fff",
    },
    secondary: {
      main: localStorage.getItem("thirdColor")
        ? localStorage.getItem("thirdColor")
        : "#e6eaed",
      contrastText: "#231f20",
    },
  },
});
export const ayaPayTheme = createTheme({
  components: {
    MuiGrid: {
      styleOverrides: {
        root: {
          "& .highlight": {
            background: localStorage.getItem("primaryColor")
              ? localStorage.getItem("primaryColor")
              : "#cd1f2c",
            color: "#fff",
          },
          "& .detailBox:hover": {
            border: localStorage.getItem("primaryColor")
              ? `1px solid ${localStorage.getItem("primaryColor")}`
              : "1px solid #cd1f2c",
          },
          "& .fullCircle,.arrivalPoint": {
            backgroundColor: "#cd1f2c",
            border: localStorage.getItem("primaryColor")
              ? `1px solid ${localStorage.getItem("primaryColor")}`
              : "1px solid #cd1f2c",
          },
          "& .circle,.departurePoint": {
            border: localStorage.getItem("primaryColor")
              ? `1px solid ${localStorage.getItem("primaryColor")}`
              : "1px solid #cd1f2c",
          },
          "& .covidInfoContainer": {
            border: localStorage.getItem("primaryColor")
              ? `1px solid ${localStorage.getItem("primaryColor")}`
              : "1px solid #cd1f2c",
          },
          "& .CalendarDay__selected,.selectedMultiDateBox": {
            backgroundColor: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#cd1f2c !important",
          },
          "& .DateInput_fangStroke": {
            fill: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#cd1f2c !important",
            stroke: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#cd1f2c !important",
          },
          "& .CalendarDay:hover,.popupSearch,.domesticInternationalTabsBtnActive":
            {
              backgroundColor: localStorage.getItem("primaryColor")
                ? `${localStorage.getItem("primaryColor")} !important`
                : "#cd1f2c !important",
            },
          "& .airportSearchIcon,.nextDate:before,.previousDate:before": {
            color: "#cd1f2c !important",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            background: "#2e9d68",
            color: "#fff",
          },
          "&.btnEditSearch": {
            color: localStorage.getItem("primaryColor")
              ? localStorage.getItem("primaryColor")
              : "#cd1f2c",
            border: localStorage.getItem("secondaryColor")
              ? `1px solid ${localStorage.getItem("secondaryColor")} !important`
              : "1px solid #cd1f2c !important",
          },
          "&.btnEditSearch:hover": {
            color: "#fff",
            backgroundColor: localStorage.getItem("secondaryColor")
              ? `${localStorage.getItem("secondaryColor")} !important`
              : "#cd1f2c !important",
          },
          "&.choosePackBtn:hover": {
            backgroundColor: localStorage.getItem("primaryColor")
              ? localStorage.getItem("primaryColor")
              : "#cd1f2c",
            color: "#fff",
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: localStorage.getItem("primaryColor")
        ? localStorage.getItem("primaryColor")
        : "#cd1f2c",
      contrastText: "#fff",
    },
    info: {
      main: localStorage.getItem("secondaryColor")
        ? localStorage.getItem("secondaryColor")
        : "#cd1f2c",
      contrastText: "#fff",
    },
    secondary: {
      main: localStorage.getItem("thirdColor")
        ? localStorage.getItem("thirdColor")
        : "#e6eaed",
      contrastText: "#231f20",
    },
  },
});
export const mpitesanTheme = createTheme({
  components: {
    MuiGrid: {
      styleOverrides: {
        root: {
          "& .highlight": {
            background: localStorage.getItem("primaryColor")
              ? localStorage.getItem("primaryColor")
              : "#ec3833",
            color: "#fff",
          },

          "& .detailBox:hover": {
            border: localStorage.getItem("primaryColor")
              ? `1px solid${localStorage.getItem("primaryColor")}`
              : "1px solid #ec3833",
          },
          "& .fullCircle,.arrivalPoint": {
            backgroundColor: localStorage.getItem("primaryColor")
              ? localStorage.getItem("primaryColor")
              : "#ec3833",
            border: localStorage.getItem("primaryColor")
              ? `1px solid${localStorage.getItem("primaryColor")}`
              : "1px solid #ec3833",
          },
          "& .circle,.departurePoint": {
            border: localStorage.getItem("primaryColor")
              ? `1px solid${localStorage.getItem("primaryColor")}`
              : "1px solid #ec3833",
          },
          "& .covidInfoContainer": {
            border: localStorage.getItem("primaryColor")
              ? `1px solid${localStorage.getItem("primaryColor")}`
              : "1px solid #ec3833",
          },
          "& .CalendarDay__selected,.selectedMultiDateBox": {
            backgroundColor: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#ec3833 !important",
          },
          "& .DateInput_fangStroke": {
            fill: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#ec3833 !important",
            stroke: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#ec3833 !important",
          },
          "& .CalendarDay:hover,.popupSearch,.domesticInternationalTabsBtnActive":
            {
              backgroundColor: localStorage.getItem("primaryColor")
                ? `${localStorage.getItem("primaryColor")} !important`
                : "#ec3833 !important",
            },
          "& .airportSearchIcon,.nextDate:before,.previousDate:before": {
            color: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#ec3833 !important",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            background: "#2e9d68",
            color: "#fff",
          },
          "&.btnEditSearch": {
            color: localStorage.getItem("secondaryColor")
              ? localStorage.getItem("secondaryColor")
              : "#ec008c",
            border: "1px solid #ec3833 !important",
          },
          "&.btnEditSearch:hover": {
            color: "#fff",
            backgroundColor: localStorage.getItem("secondaryColor")
              ? `${localStorage.getItem("secondaryColor")} !important`
              : "#ec3833 !important",
          },
          "&.choosePackBtn:hover": {
            backgroundColor: localStorage.getItem("primaryColor")
              ? localStorage.getItem("primaryColor")
              : "#ec3833",
            color: "#fff",
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: localStorage.getItem("primaryColor")
        ? localStorage.getItem("primaryColor")
        : "#ec3833",
      contrastText: "#fff",
    },
    info: {
      main: localStorage.getItem("secondaryColor")
        ? localStorage.getItem("secondaryColor")
        : "#ec3833",
      contrastText: "#fff",
    },
    secondary: {
      main: localStorage.getItem("thirdColor")
        ? localStorage.getItem("thirdColor")
        : "#e6eaed",
      contrastText: "#231f20",
    },
  },
});
export const momoneyTheme = createTheme({
  components: {
    MuiGrid: {
      styleOverrides: {
        root: {
          "& .highlight": {
            background: localStorage.getItem("secondaryColor")
              ? localStorage.getItem("secondaryColor")
              : "#4cb849",
            color: "#fff",
          },

          "& .detailBox:hover": {
            border: localStorage.getItem("primaryColor")
              ? `1px solid ${localStorage.getItem("primaryColor")}`
              : "1px solid #4cb849",
          },
          "& .fullCircle,.arrivalPoint": {
            backgroundColor: "#4cb849",
            border: localStorage.getItem("primaryColor")
              ? `1px solid ${localStorage.getItem("primaryColor")}`
              : "1px solid #4cb849",
          },
          "& .circle,.departurePoint": {
            border: localStorage.getItem("primaryColor")
              ? `1px solid ${localStorage.getItem("primaryColor")}`
              : "1px solid #4cb849",
          },
          "& .covidInfoContainer": {
            border: localStorage.getItem("primaryColor")
              ? `1px solid ${localStorage.getItem("primaryColor")}`
              : "1px solid #4cb849",
          },
          "& .CalendarDay__selected,.selectedMultiDateBox": {
            backgroundColor: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#4cb849 !important",
          },
          "& .DateInput_fangStroke": {
            fill: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#4cb849 !important",
            stroke: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#4cb849 !important",
          },
          "& .CalendarDay:hover,.popupSearch,.domesticInternationalTabsBtnActive":
            {
              backgroundColor: localStorage.getItem("primaryColor")
                ? `${localStorage.getItem("primaryColor")} !important`
                : "#4cb849 !important",
            },
          "& .airportSearchIcon": {
            color: localStorage.getItem("primaryColor")
              ? `${localStorage.getItem("primaryColor")} !important`
              : "#4cb849 !important",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            background: "#2e9d68",
            color: "#fff",
          },
          "&.btnEditSearch": {
            color: localStorage.getItem("secondaryColor")
              ? localStorage.getItem("secondaryColor")
              : "#4cb849",
            border: localStorage.getItem("secondaryColor")
              ? `1px solid ${localStorage.getItem("secondaryColor")} !important`
              : "1px solid #4cb849 !important",
          },
          "&.btnEditSearch:hover": {
            color: "#fff",
            backgroundColor: localStorage.getItem("secondaryColor")
              ? `${localStorage.getItem("secondaryColor")} !important`
              : "#4cb849 !important",
          },
          "&.choosePackBtn:hover": {
            backgroundColor: localStorage.getItem("primaryColor")
              ? localStorage.getItem("primaryColor")
              : "#4cb849",
            color: "#fff",
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: localStorage.getItem("primaryColor")
        ? localStorage.getItem("primaryColor")
        : "#4cb849",
      contrastText: "#fff",
    },
    info: {
      main: localStorage.getItem("secondaryColor")
        ? localStorage.getItem("secondaryColor")
        : "#4cb849",
      contrastText: "#fff",
    },
    secondary: {
      main: localStorage.getItem("thirdColor")
        ? localStorage.getItem("thirdColor")
        : "#e6eaed",
      contrastText: "#231f20",
    },
  },
});
