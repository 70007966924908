import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@mui/material/styles";

export const ProgressBarStyles = makeStyles((theme) => ({
  progressBar: (props) => ({
    "& li.active:before": {
      backgroundColor: props.bgColor,
      borderColor: props.bgColor,
    },
  }),
}));
