import React from "react";
import Grid from "@material-ui/core/Grid";
import { MY_MM } from "../../config/constants";
import {
  ContentfulData,
  getDataContentfulId,
} from "../../utils/contentfulUtil";

const BusProgressbar = ({ pageName, selectedLanguage, pageContent }) => {
  return (
    <Grid
      item
      xs={10}
      sm={10}
      md={7}
      className="progressBarContainer busProgressbar"
    >
      <ul
        className={
          selectedLanguage === MY_MM
            ? "progressbar mm-progress"
            : "progressbar eng-progress"
        }
      >
        <li
          className={
            pageName === "BusSearch"
              ? "active"
              : "" + pageName !== "/"
              ? "completed"
              : ""
          }
        >
          <span
            className="step-info"
            data-contentful-id={getDataContentfulId("search")}
          >
            <ContentfulData content={pageContent} id="search">
              Search
            </ContentfulData>
          </span>
        </li>
        <li
          className={
            pageName === "BusSeat"
              ? "active"
              : "" + pageName === "BusContactInfo" ||
                pageName === "BusPayment" ||
                pageName === "BusConfirmation" ||
                pageName === "BusTransactionfail"
              ? "completed"
              : ""
          }
        >
          <span
            className="step-info"
            data-contentful-id={getDataContentfulId("selectSeat")}
          >
            <ContentfulData content={pageContent} id="selectSeat">
              Select Seat
            </ContentfulData>
          </span>
        </li>
        <li
          className={
            pageName === "BusContactInfo"
              ? "active"
              : "" + pageName === "BusPayment" ||
                pageName === "BusConfirmation" ||
                pageName === "BusTransactionfail"
              ? "completed"
              : ""
          }
        >
          <span
            className="step-info"
            data-contentful-id={getDataContentfulId("info")}
          >
            <ContentfulData content={pageContent} id="info">
              Info
            </ContentfulData>
          </span>
        </li>
        <li
          className={
            pageName === "BusPayment"
              ? "active"
              : "" + pageName === "BusConfirmation" ||
                pageName === "BusTransactionfail"
              ? "completed"
              : ""
          }
        >
          <span
            className="step-info"
            data-contentful-id={getDataContentfulId("pay")}
          >
            <ContentfulData content={pageContent} id="pay">
              Pay
            </ContentfulData>
          </span>
        </li>
        <li
          className={
            pageName === "BusConfirmation"
              ? "active"
              : "" + pageName === "BusTransactionfail"
              ? "warning"
              : ""
          }
        >
          <span
            className="step-info"
            data-contentful-id={getDataContentfulId("confirm")}
          >
            <ContentfulData content={pageContent} id="confirm">
              Confirm
            </ContentfulData>
          </span>
        </li>
      </ul>
    </Grid>
  );
};
export default BusProgressbar;
