import React, { useState } from "react";
import { Button, Grid } from "@material-ui/core";
import MediaQuery from "react-responsive";
import closeIcon from "../../assets/images/relief_flight_close_icon.png";
import { clientV3 } from "../../utils/v3utils";
import { FIREBASE_SIGNUP_QUERY } from "../../queries/loginquery";
import SignupForm from "./SignupForm";
import { toPairs, includes } from "lodash";
import SigninOtpIcon from "../../assets/images/signin-otp.svg";
import GoogleIcon from "../../components/svg/GoogleIcon";
import EditProfileDialog from "../loginusers/EditProfileDialog";
import { CREATE_ARRANGER } from "../../queries/loginuserquery";
import {
  storeProfileId,
  storeUserArrangerInfo,
  storeUserEditDate,
} from "../../utils/loginutils";
import { getLocale } from "../../utils/checkurl";
import { useParams } from "react-router-dom";
import {
  ADDRESSBOOK_ID,
  LOGIN_PEOPLE_DETAIL,
  LOGIN_USER_ID,
  LOGIN_USER_PEOPLE,
  MYANMAR_ID,
} from "../../config/constants";
import { CREATE_ADDRESSBOOK } from "../../queries/addressbookquery";
import { isMobile } from "react-device-detect";
import { getClientTag } from "../../utils/checkurl";

const SignupRegion = ({
  setIsShow,
  setIsSignUp,
  isSocialLogin,
  createPeople,
  setIsDim,
  createdResPeople,
  page,
  handleLogin,
  errorMsg,
  isDim,
  signUpValues,
  isSpinner,
  setIsSpinner,
  isShow,
  setErrorMsg,
  socialLoginType,
  signInWithGoogle,
  setChooseState,
  setIsPhone,
  setIsPhoneOnly,
  setOtpErrMsg,
  setIsForget,
  regValues,
  handleContinue,
  setFieldValue,
  data,
  addId,
  pageContent,
  setInfoOpen,
  originalPage,
}) => {
  const params = useParams();
  const [formError, setFormError] = useState("");
  const [open, setOpen] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [editDate, setEditDate] = useState("");
  const [selectedLanguage] = useState(getLocale(params.locale));
  const [closeClick, setCloseClick] = useState(false);
  const [clientTag] = useState(getClientTag(window.location.search));

  const createArranger = (values) => {
    let pid = localStorage.getItem(LOGIN_USER_PEOPLE);
    let loginId = localStorage.getItem(LOGIN_USER_ID);
    let commonInput = {
      id: pid ? pid : null,
      login_id: Number(loginId),
      prefix: values?.signupPrefix.replace(".", ""),
      first_name: values?.signupFirstName,
      last_name: values?.signupLastName,
      nationality_id: values?.signupNationality,
      dob:
        values.signupDobYear !== "0000" &&
        values.signupDobYear !== undefined &&
        values.signupDobMonth !== "00" &&
        values.signupDobYear !== undefined &&
        values.signupDobDay !== "00" &&
        values.signupDobYear !== undefined
          ? `${values.signupDobYear}-${values.signupDobMonth}-${values.signupDobDay}`
          : null,
      passport: values.signupNrcPass,
      passport_exp:
        values.signupExpYear !== "0000" &&
        values.signupExpYear !== undefined &&
        values.signupExpMonth !== "00" &&
        values.signupDobMonth !== undefined &&
        values.signupExpDay !== "00" &&
        values.signupExpDay !== undefined
          ? `${values.signupExpYear}-${values.signupExpMonth}-${values.signupExpDay}`
          : null,
      nrc: values.signupNrc
        ? values.signupNrc.startsWith("/")
          ? `1/HsaBaTa(N)${values.signupNrc?.split(")")[1]}`
          : values.signupNrc
        : "",
      address: values.address,
      township_id: values.township,
    };

    clientV3
      .mutate({
        mutation: CREATE_ARRANGER,
        variables: {
          input: commonInput,
        },
      })
      .then((res) => {
        console.log(res);
        setInfoOpen(true);
        storeUserArrangerInfo(res);
        storeUserEditDate(res);
        storeProfileId(res?.data?.createArranger?.id);
        setEditDate(res?.data?.createArranger?.updated_at);
        // window.location.href = `/${selectedLanguage}/profile/${
        //   window.location.pathname?.split("/")[3]
        // }`;
        setIsSpinner(false);
        setIsShow(false);
      })
      .catch((err) => {
        console.log(err);
        setIsSpinner(false);
      });
  };

  const createAddressBook = (val) => {
    let arrangerId = localStorage.getItem(LOGIN_PEOPLE_DETAIL)
      ? JSON.parse(localStorage.getItem(LOGIN_PEOPLE_DETAIL))?.id
      : null;
    let pid = localStorage.getItem(ADDRESSBOOK_ID);
    let commonInput = {
      id: addId === "addressBookCreate" ? null : pid,
      arranger_id: arrangerId ? String(arrangerId) : null,
      prefix: val?.signupPrefix.replace(".", ""),
      first_name: val?.signupFirstName,
      last_name: val?.signupLastName,
      phone: `${val?.signupPhonePrefix} ${val.signupPhone}`,
      email: val?.signupEmail,
      nationality_id: val?.signupNationality,
      dob:
        val.signupDobYear !== "0000" &&
        val.signupDobYear !== undefined &&
        val.signupDobMonth !== "00" &&
        val.signupDobYear !== undefined &&
        val.signupDobDay !== "00" &&
        val.signupDobYear !== undefined
          ? `${val.signupDobYear}-${val.signupDobMonth}-${val.signupDobDay}`
          : null,
      passport: val.signupNrcPass,
      passport_exp:
        val.signupExpYear !== "0000" &&
        val.signupExpYear !== undefined &&
        val.signupExpMonth !== "00" &&
        val.signupDobMonth !== undefined &&
        val.signupExpDay !== "00" &&
        val.signupExpDay !== undefined
          ? `${val.signupExpYear}-${val.signupExpMonth}-${val.signupExpDay}`
          : null,
      nrc: val.signupNrc
        ? val.signupNrc.startsWith("/")
          ? `1/HsaBaTa(N)${val.signupNrc?.split(")")[1]}`
          : val.signupNrc
        : "",
      address: val.address,
      township_id: val.township,
    };

    clientV3
      .mutate({
        mutation: CREATE_ADDRESSBOOK,
        variables: {
          input: commonInput,
        },
      })
      .then((res) => {
        setIsSpinner(false);
        window.location.href = `/${selectedLanguage}/address-book/${localStorage.getItem(
          LOGIN_USER_ID,
        )}`;
      })
      .catch((err) => {
        console.log(err);
        setIsSpinner(false);
      });
  };

  const handleSignUp = (values) => {
    if (page === "profile") {
      //setProfileData(values);
      createArranger(values);
      //setOpen(true);
      return;
    }
    if (page === "addressBook") {
      if (
        values?.signupDobDay === "00" ||
        values?.signupDobMonth === "00" ||
        values?.signupDobYear === "0000" ||
        (values?.signupNationality === MYANMAR_ID &&
          values?.signupNrc === "") ||
        values?.signupExpDay === "00" ||
        values?.signupExpMonth === "00" ||
        values?.signupExpYear === "0000"
      ) {
        setIsSpinner(false);
        return;
      } else {
        createAddressBook(values);
        return;
      }
    }
    if (isSocialLogin) {
      createPeople(values);
      return;
    }
    setIsDim(true);
    let commonInput = {
      id: values.id ? values.id : null,
      //name: `${values.signupFirstName} ${values.signupLastName}`,
      email: values.signupEmail,
      password: values.signupPassword,
      // password_confirmation: values.signupConfirmPassword,
      password_confirmation: values.signupPassword,
      //group_account_id: "",
      //role_ids: [2],
      //company_id: null,
      //login_type_id: 4,
      phone_prefix: values.signupPhonePrefix,
      phone: values.signupPhone,
      prefix: values.signupPrefix,
      first_name: values.signupFirstName,
      last_name: values.signupLastName,
      passport: values.signupNrcPass,
      passport_exp:
        values.signupExpYear !== "0000" &&
        values.signupExpMonth !== "00" &&
        values.signupExpDay !== "00"
          ? `${values.signupExpYear}-${values.signupExpMonth}-${values.signupExpDay}`
          : null,
      nrc: values.signupNrc,
      township_id: values.township,
      nationality_id: values.signupNationality,
      dob:
        values.signupDobYear !== "0000" &&
        values.signupDobMonth !== "00" &&
        values.signupDobDay !== "00"
          ? `${values.signupDobYear}-${values.signupDobMonth}-${values.signupDobDay}`
          : null,
      address: values.address,
      oneclick: false,
      client:
        clientTag && clientTag !== ""
          ? clientTag
          : isMobile
          ? "default"
          : "default",
    };
    // if (page === "customerInfo") {
    //   let pArr = [];
    //   createdResPeople &&
    //     createdResPeople.length > 0 &&
    //     createdResPeople.forEach(val => {
    //       val.people && val.people.id !== null && pArr.push(val.people.id);
    //     });
    //   // commonInput.people_ids = pArr;
    //   let pid = localStorage.getItem("arranger_people_id");
    //   commonInput.people_ids = [pid];
    // }

    clientV3
      .mutate({
        mutation: FIREBASE_SIGNUP_QUERY,
        variables: {
          input: commonInput,
        },
      })
      .then((res) => {
        setIsShow(false);
        setIsSpinner(false);
        setIsSignUp(false);
        setIsDim(false);
        setInfoOpen(true);
        if (originalPage && originalPage === "flightResult") {
          window.location.href = `/${selectedLanguage}`;
          localStorage.setItem("isSignupVerify", "yes");
        }

        // if (page === "customerInfo") {
        //   if (res.data.firebaseSignup.status === "SUCCESS") {
        //     handleLogin(values.signupEmail, values.signupPassword);
        //   }
        // } else {
        //   handleLogin(values.signupEmail, values.signupPassword);
        // }
      })
      .catch((err) => {
        console.log(err);
        let errorArr =
          err &&
          err.graphQLErrors &&
          err.graphQLErrors.length > 0 &&
          toPairs(
            err.graphQLErrors[0] && err.graphQLErrors[0].extensions
              ? err.graphQLErrors[0].extensions.validation
              : [],
          );
        let isErr = "";
        errorArr.length > 0 &&
          errorArr.forEach((val) => {
            includes(val[0], "email") && (isErr = "true");
          });
        isErr === "true" && setFormError("The email has already been taken.");
        setErrorMsg("Something went wrong.");
        setIsSpinner(false);
        setIsDim(false);
      });
  };

  return (
    <>
      <div className="login-left-inner signup">
        <MediaQuery maxWidth={780}>
          {(formError.length > 0 ||
            errorMsg.length > 0 ||
            page === "addressBook" ||
            page === "profile") && (
            <img
              src={closeIcon}
              alt="close"
              disabled={closeClick}
              onClick={() => {
                setCloseClick(true);
                if (formError.length > 0 || errorMsg.length > 0) {
                  if (page === "profile") {
                    setIsShow(false);
                  }
                  if (page === "customerInfo") {
                    !closeClick && handleContinue();
                  }
                } else {
                  setIsShow();

                  //setIsSignUp(false);
                }
              }}
              className="closeImg"
            />
          )}
        </MediaQuery>
        <MediaQuery minWidth={781}>
          {(regValues?.isSignUp ||
            page === "profile" ||
            page === "addressBook") &&
          (formError.length > 0 ||
            errorMsg.length > 0 ||
            page === "profile" ||
            page === "addressBook") ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <h3>
                {page === "profile"
                  ? "User Profile Edit"
                  : addId === "addressBookCreate"
                  ? pageContent && pageContent.addPassenger
                    ? pageContent.addPassenger
                    : "Add Passenger"
                  : addId === "addressBookEdit"
                  ? pageContent && pageContent.editPassenger
                    ? pageContent.editPassenger
                    : "Edit Passenger"
                  : pageContent && pageContent.signupForm
                  ? pageContent.signupForm
                  : "Signup Form"}
              </h3>

              <img
                src={closeIcon}
                disabled={closeClick}
                alt="close"
                style={{
                  width: "15px",
                  height: "15px",
                  marginTop: "20px",
                  cursor: "pointer",
                  position: "fixed",
                  right: "32%",
                }}
                onClick={() => {
                  setCloseClick(true);
                  if (formError.length > 0 || errorMsg.length > 0) {
                    if (page === "profile") {
                      setIsShow(false);
                    }
                    if (page === "customerInfo") {
                      !closeClick && handleContinue();
                    }
                  } else {
                    setIsShow();

                    //setIsSignUp(false);
                  }
                }}
                className="closeImg"
              />
            </div>
          ) : page === "profile" ? (
            <h3>User Profile Edit</h3>
          ) : page === "addressBookEdit" ? (
            <h3>
              {pageContent && pageContent.editPassenger
                ? pageContent.editPassenger
                : "Edit Passenger"}
            </h3>
          ) : addId === "addressBookCreate" ? (
            <h3>
              {pageContent && pageContent.addPassenger
                ? pageContent.addPassenger
                : "Add Passenger"}
            </h3>
          ) : (
            <h3>
              {pageContent && pageContent.signupForm
                ? pageContent.signupForm
                : "Signup Form"}
            </h3>
          )}
        </MediaQuery>
        <SignupForm
          editDate={editDate}
          addId={addId}
          val={regValues}
          errorMsg={errorMsg}
          isDim={isDim}
          values={signUpValues}
          page={page}
          handleSignUp={handleSignUp}
          isSpinner={isSpinner}
          setIsSpinner={setIsSpinner}
          formError={formError}
          setFormError={setFormError}
          regValues={signUpValues}
          isShow={isShow}
          setIsShow={() => setIsShow(true)}
          setIsDim={setIsDim}
          isSocialLogin={isSocialLogin}
          socialLoginType={socialLoginType}
          data={data}
          setIsSignUp={() => setIsSignUp(false)}
          pageContent={pageContent}
        />
      </div>
      {page === "home" && (
        <Grid container className="signuptab-social-login">
          <Grid item={true} sm={6} md={6} xs={6} className="google-login">
            <Button
              className="btn-login-box"
              onClick={signInWithGoogle}
              startIcon={<GoogleIcon />}
            >
              <span className="sign-text">
                {pageContent && pageContent.continueGoogleSso
                  ? pageContent.continueGoogleSso
                  : "Continue with Google SSO"}
              </span>
            </Button>
          </Grid>
          <Grid item={true} sm={6} md={6} xs={6} className="phone-login">
            <Button
              className="btn-login-box"
              onClick={() => {
                setIsSignUp();
                setChooseState(false);
                setIsPhone(true);
                setIsPhoneOnly(true);
                setErrorMsg("");
                setOtpErrMsg("");
                setIsForget(false);
              }}
              startIcon={<img src={SigninOtpIcon} alt="" />}
            >
              <span className="sign-text">
                {pageContent && pageContent.continueOtp
                  ? pageContent.continueOtp
                  : "Continue with OTP"}
              </span>
            </Button>
          </Grid>
        </Grid>
      )}
      <EditProfileDialog
        profileData={profileData}
        editProfile={createArranger}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
};

export default SignupRegion;
