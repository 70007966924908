export const getAge = (dob) => {
  const today = new Date();
  const birthDate = new Date(dob);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const getPassengerType = (age) => {
  let passengerType;
  if (age >= 12) {
    passengerType = "Adult";
  } else if (age >= 2 && age <= 11) {
    passengerType = "Child";
  } else if (age < 2) {
    passengerType = "Infant";
  }
  return passengerType;
};

export const showErrorMessage = (name, value) => {
  let response = {
    error: false,
    errorMessage: "",
  };

  if (name === "expDay" || name === "expMonth" || name === "expYear") {
    if (value === "00" || value === "0000") {
      response.error = true;
      response.errorMessage = "Passport Expiry Date is required";
    }
  } else {
    if (value === "") {
      response.error = true;
      response.errorMessage = `${name} is required`;
    } else {
      response.error = false;
      response.errorMessage = "";
    }
  }

  return response;
};
