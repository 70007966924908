import {
  AUTH_TOKEN,
  TOKEN_TYPE,
  LOGIN_USER_ID,
  IDENTIFIER,
  EXPIRES_IN,
  LOGIN_PEOPLE_COMPANY,
  LOGIN_USER_PEOPLE,
  IS_B2B,
  IS_CALL_CENTER,
  IS_CUSTOMER,
  IS_EMPLOYEE,
  LOGIN_PEOPLE_DETAIL,
  LOGIN_NAME,
  LOGIN_PHONE_PREFIX,
  LOGIN_PHONE,
  LOGIN_SOCIAL,
  SOCIAL_TOKEN,
  B2B_ClASS_TYPE,
  ARRANGER_PEOPLE_ID,
} from "../config/constants";

export const removeFromLocalStorage = () => {
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(TOKEN_TYPE);
  localStorage.removeItem(EXPIRES_IN);
  localStorage.removeItem(IDENTIFIER);
  localStorage.removeItem(LOGIN_USER_ID);
  localStorage.removeItem(LOGIN_PEOPLE_COMPANY);
  localStorage.removeItem(LOGIN_USER_PEOPLE);
  localStorage.removeItem(IS_B2B);
  localStorage.removeItem(IS_CALL_CENTER);
  localStorage.removeItem(IS_CUSTOMER);
  localStorage.removeItem(IS_EMPLOYEE);
  localStorage.removeItem(LOGIN_PEOPLE_DETAIL);
  localStorage.removeItem(LOGIN_NAME);
  localStorage.removeItem(LOGIN_PHONE_PREFIX);
  localStorage.removeItem(LOGIN_PHONE);
  localStorage.removeItem(LOGIN_SOCIAL);
  localStorage.removeItem(SOCIAL_TOKEN);
  localStorage.removeItem(B2B_ClASS_TYPE);
  localStorage.removeItem(ARRANGER_PEOPLE_ID);
  localStorage.removeItem("userData");
  localStorage.removeItem("companyPayment");
};

export const removePassengerInfo = () => {
  sessionStorage.removeItem("adultpassenger");
  sessionStorage.removeItem("childpassenger");
  sessionStorage.removeItem("infantpassenger");
  sessionStorage.removeItem("contactInfo");
  sessionStorage.removeItem("resId");
  sessionStorage.removeItem("pnrFailDept");
  sessionStorage.removeItem("pnrFailReturn");
  sessionStorage.removeItem("PNRfailCount");
  sessionStorage.removeItem("PNRfailReturnCount");
};
