import { gql } from "apollo-boost";

const RESET_PASSWORD = gql`
  mutation ($input: NewPasswordWithCodeInput) {
    updateForgottenPassword(input: $input) {
      status
      message
    }
  }
`;
const CREATE_PASSWORD = gql`
  mutation ($input: CreatePasswordInput!) {
    createPassword(input: $input) {
      status
      message
      __typename
    }
  }
`;
const UPDATE_CREATE_PASSWORD = gql`
  mutation ($input: CreateWithCodeInput) {
    updateCreatePassword(input: $input) {
      status
      message
      __typename
    }
  }
`;
const CHECK_CREATE_PASSWORD = gql`
  mutation ($input: CreatePasswordInput!) {
    checkCreatePassword(input: $input) {
      status
      message
      __typename
    }
  }
`;
export {
  RESET_PASSWORD,
  CREATE_PASSWORD,
  UPDATE_CREATE_PASSWORD,
  CHECK_CREATE_PASSWORD,
};
