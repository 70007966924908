//Google Tag Manager -> Oneway Trip or RoundTrip
export const gtmTripType = (tripType) => {
  let dataLayer = window.dataLayer || [];
  (window.location.hostname === "coffee-web.mmflights.com" ||
    window.location.hostname === "flymya.com" ||
    window.location.hostname === "booking.flymya.com" ||
    window.location.hostname === "www.flymya.com") &&
    dataLayer.push({
      flightTripType: tripType,
    });
};

export const gtmAppsflyerRevenue = (val) => {
  let dataLayer = window.dataLayer || [];
  (window.location.hostname === "coffee-web.mmflights.com" ||
    window.location.hostname === "booking.flymya.com" ||
    window.location.hostname === "flymya.com" ||
    window.location.hostname === "www.flymya.com") &&
    dataLayer.push({
      event: "returnAppsflyerRevenue",
      appsflyerRevenue: val,
    });
};

export const gtmHotelCustomPrice = () => {
  let dataLayer = window.dataLayer || [];
  (window.location.hostname === "coffee-web.mmflights.com" ||
    window.location.hostname === "booking.flymya.com" ||
    window.location.hostname === "flymya.com" ||
    window.location.hostname === "www.flymya.com") &&
    dataLayer.push({
      event: "hotel_custom_price",
      flymyaHotel: "yes",
    });
};

export const gtmB2BLogin = (val) => {
  let dataLayer = window.dataLayer || [];
  (window.location.hostname === "coffee-web.mmflights.com" ||
    window.location.hostname === "booking.flymya.com" ||
    window.location.hostname === "flymya.com" ||
    window.location.hostname === "www.flymya.com") &&
    dataLayer.push({
      b2bLogin: val,
    });
};

export const gtmB2CLogin = (val) => {
  let dataLayer = window.dataLayer || [];
  (window.location.hostname === "coffee-web.mmflights.com" ||
    window.location.hostname === "booking.flymya.com" ||
    window.location.hostname === "flymya.com" ||
    window.location.hostname === "www.flymya.com") &&
    dataLayer.push({
      b2cLogin: val,
    });
};

export const gtmB2BClassType = (val) => {
  let dataLayer = window.dataLayer || [];
  (window.location.hostname === "coffee-web.mmflights.com" ||
    window.location.hostname === "booking.flymya.com" ||
    window.location.hostname === "flymya.com" ||
    window.location.hostname === "www.flymya.com") &&
    dataLayer.push({
      b2bClassType: val,
    });
};
