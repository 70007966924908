const MYTEL_PHONE_PATTERN = /^((96)\d{8}|(96)\d{8})$/;

const OOREDOO_PHONE_PATTERN =
  /^((994|995|996|997|998)\d{7}|(994|995|996|997|998)\d{7})$/;

const ATOM_PHONE_PATTERN =
  /^((974|975|976|977|978|979)\d{7}|(974|975|976|977|978|979)\d{7})$/;

const MECTEL_PHONE_PATTERN =
  /^((930|931|932|933|936)\d{6}|(930|931|932|933|936)\d{6}|(934)\d{7}|(934)\d{7})$/;

const MPT_PHONE_PATTERN =
  /^((920|921|922|923|924|981|983|984|985|986|987)\d{5}|(920|921|922|923|924|981|983|984|985|986|987)\d{5}|(941|943|95|973|991|949|947|9890|9891|9892|9893|9894|9895|9896|9897|9898|9899|939)\d{6}|(941|943|95|973|991|949|947|9890|9891|9892|9893|9894|9895|9896|9897|9898|9899|939)\d{6}|(925|926|940|942|944|945|9447|9266|9267|9268|988)\d{7}|(925|926|940|942|944|945|9447|9266|9267|9268|988)\d{7})$/;

export const PhoneValidation = (val) => {
  if (
    !MYTEL_PHONE_PATTERN.test(val) &&
    !OOREDOO_PHONE_PATTERN.test(val) &&
    !ATOM_PHONE_PATTERN.test(val) &&
    !MECTEL_PHONE_PATTERN.test(val) &&
    !MPT_PHONE_PATTERN.test(val)
  ) {
    return false;
  }
  return true;
};
