import React, { useState } from "react";
//import ReactFlagsSelect from "react-flags-select";
//import "react-flags-select/scss/react-flags-select.scss";
import { MY_MM } from "../../config/constants";
import "react-custom-flag-select/lib/react-custom-flag-select.min.css";
import MediaQuery from "react-responsive";
import EngMobileFlag from "../../assets/images/flags/en-flag-mobile.webp";
import MMMobileFlag from "../../assets/images/flags/mm-flag-mobile.webp";
import { useLocation } from "react-router-dom";

const ReactFlags = (props) => {
  let location = useLocation();
  const [selectedCode, setSelectedCode] = useState(
    props.selectedLanguage === "my-mm" ? "MM" : "US",
  );

  const changeLanguage = (countryCode, pathName) => {
    let arr = pathName && pathName.pathname ? pathName.pathname.split("/") : [];
    if (arr.length > 1) {
      arr[1] = countryCode === "MM" ? MY_MM : "en";
      window.location = arr.join("/") + pathName.search;
    }
  };

  return (
    <>
      <div style={{paddingTop: '1px'}}>
        <MediaQuery minDeviceWidth={781}>
          <img
            width={23}
            height={23}
            src={selectedCode === "US" ? EngMobileFlag : MMMobileFlag}
            selected={
              props.selectedLanguage === MY_MM || props.selectedLanguage === ""
                ? "MM"
                : "US"
            }
            onClick={() => {
              setSelectedCode((prev) => (prev === "US" ? "MM" : "US"));
              changeLanguage(selectedCode === "MM" ? "US" : "MM", location);
            }}
            alt="mm-mobile-flag"
          />
        </MediaQuery>
        <MediaQuery maxDeviceWidth={780}>
          <img
            // style={{
            //   marginRight:
            //     props.pageName === "FlightSearch" ||
            //     props.pageName === "CustomerDetails" ||
            //     props.pageName === "FlightPaymentPage"
            //       ? "31px"
            //       : props.pageName === "FlightConfirmation"
            //       ? "67px"
            //       : "3px",
            //   marginTop: "3px",
            // }}
            width={23}
            height={23}
            src={selectedCode === "US" ? EngMobileFlag : MMMobileFlag}
            onClick={() => {
              setSelectedCode((prev) => (prev === "US" ? "MM" : "US"));
              changeLanguage(selectedCode === "MM" ? "US" : "MM", location);
            }}
            alt="mm-mobile-flag"
          />
        </MediaQuery>
      </div>
    </>
  );
};

export default ReactFlags;
