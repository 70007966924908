import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  ARRANGER_PEOPLE_ID,
  EN_LOCALE,
  LOGIN_PEOPLE_DETAIL,
  LOGIN_USER_ID,
  MM_LOCALE,
  MY_MM,
  PASSENGER_COUNT,
  PASSENGER_MAX_COUNT,
} from "../../config/constants";
import { checkInApp, checkInWeb, getLocale } from "../../utils/checkurl";
import { ContentfulEntries } from "../../utils/Contentful";
import PageHeader from "../PageHeader";
import { Box, Button, Card, CardContent, Grid } from "@material-ui/core";
import "../../assets/scss/loginusers/Profile.scss";
import Passenger from "../../components/passenger/Passenger";
import "../../assets/scss/loginusers/Passenger.scss";
import LoginSignupModal from "../login/LoginSignupModal";
import { ADDRESSBOOK_BY_ARRANGER } from "../../queries/addressbookquery";
import { clientV3 } from "../../utils/v3utils";

const AddressBook = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const [selectedLanguage] = useState(getLocale(params.locale));
  const [language] = useState(
    getLocale(params.locale) === MY_MM ? MM_LOCALE : EN_LOCALE,
  );
  const [arrangerData, setArrangerData] = useState(null);
  const [isInApp] = useState(checkInApp(window.location.pathname));
  const [isInWeb] = useState(checkInWeb(window.location.pathname));
  const [pageContent, setPageContent] = useState({});
  const [addressbookArr, setAddressbookArr] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [isForget, setIsForget] = useState(false);
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [isSpinner, setIsSpinner] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isDim, setIsDim] = useState(false);
  const [data, setData] = useState(null);
  const [count, setCount] = useState(0);
  let passArr = [];

  const getContentfulEntries = (language) => {
    ContentfulEntries(
      "masterHomePage,masterHomeSecondPage,termsAndConditions,images,b2c,bookingHistory,balance,masterPassengerInformation,carRental",
      language,
    )
      .then((response) => {
        setPageContent(response.contentData);
      })
      .catch((error) => {
        console.log(error);
        setPageContent(error.contentData);
      });
  };

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      getContentfulEntries(language);
      mounted.current = true;
    }
  }, [language]);

  const getAddressBooks = useCallback((val, filter, countVal) => {
    let arrangerId = JSON.parse(localStorage.getItem(LOGIN_PEOPLE_DETAIL))?.id;

    clientV3
      .query({
        query: ADDRESSBOOK_BY_ARRANGER,
        fetchPolicy: "network-only",
        variables: {
          filter: [
            { field: "arranger_id", operator: "is", value: String(arrangerId) },
          ],
          orderBy: [{ column: "id", order: "DESC" }],
          first: 10,
          page: 1,
        },
      })
      .then((res) => {
        res?.data?.addressBooksByArranger?.data &&
          setAddressbookArr(res?.data?.addressBooksByArranger?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getDataFromStoredData = () => {
    let data = localStorage.getItem(LOGIN_PEOPLE_DETAIL);
    setArrangerData(JSON.parse(data));
  };

  useEffect(() => {
    const checkLogin = JSON.parse(localStorage.getItem(LOGIN_PEOPLE_DETAIL));
    checkLogin && checkLogin.id && getAddressBooks();
    getDataFromStoredData();
  }, []);

  const goToProfile = () => {
    navigate({
      pathname: isInApp
        ? `/${selectedLanguage}/in-app/profile/${localStorage.getItem(
            LOGIN_USER_ID,
          )}`
        : isInWeb
        ? `/${selectedLanguage}/in-web/profile/${localStorage.getItem(
            LOGIN_USER_ID,
          )}`
        : `/${selectedLanguage}/profile/${localStorage.getItem(LOGIN_USER_ID)}`,
      search: window.location.search,
    });
  };

  const handleLinkToBookingHistory = () => {
    navigate({
      pathname: isInApp
        ? `/${selectedLanguage}/in-app/booking-history/${localStorage.getItem(
            LOGIN_USER_ID,
          )}`
        : isInWeb
        ? `/${selectedLanguage}/in-web/booking-history/${localStorage.getItem(
            LOGIN_USER_ID,
          )}`
        : `/${selectedLanguage}/booking-history/${localStorage.getItem(
            LOGIN_USER_ID,
          )}`,
      search: window.location.search,
    });
  };

  const handleLinkToInsiderDashboard = () => {
    navigate({
      pathname: isInApp
        ? `/${selectedLanguage}/in-app/insider-dashboard/${localStorage.getItem(
            LOGIN_USER_ID,
          )}`
        : isInWeb
        ? `/${selectedLanguage}/in-web/insider-dashboard/${localStorage.getItem(
            LOGIN_USER_ID,
          )}`
        : `/${selectedLanguage}/insider-dashboard/${localStorage.getItem(
            LOGIN_USER_ID,
          )}`,
      search: window.location.search,
    });
  };

  for (let i = 0; i < count; i++) {
    passArr.push(<Passenger />);
  }

  return (
    <div className="profileContainer">
      <PageHeader
        pageName={"ProfilePage"}
        selectedLanguage={selectedLanguage}
        pageContent={pageContent}
        pathName={props.location}
        isInApp={isInApp}
        isInWeb={isInWeb}
      />
      <Box mt={5}>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <Card className="card">
              <CardContent>
                <div className="addressBookContainer">
                  <span style={{ fontSize: "24px" }}>
                    {pageContent && pageContent.addressBook
                      ? pageContent.addressBook
                      : "Address Book"}
                  </span>
                </div>
                <div
                  style={{ marginTop: "15px" }}
                  className="userProfileContainer"
                >
                  <div className="profileRedirect">
                    <span className="userProfile">
                      {pageContent && pageContent.userProfile
                        ? pageContent.userProfile
                        : "User Profile"}
                    </span>
                    <div className="arrangerContainer" onClick={goToProfile}>
                      <div className="profileName">
                        {arrangerData?.prefix}
                        {arrangerData?.first_name} {arrangerData?.last_name}
                      </div>
                      <div className="arranger" style={{ marginRight: "0px" }}>
                        {pageContent && pageContent.arranger
                          ? pageContent.arranger
                          : "Arranger"}
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ marginLeft: "48px", marginTop: "10px" }}>
                  <span
                    onClick={handleLinkToBookingHistory}
                    style={{ color: "#00aeef", cursor: "pointer" }}
                  >
                    {pageContent && pageContent.bookingHistory
                      ? pageContent.bookingHistory
                      : "Booking History"}
                  </span>
                  <span> | </span>
                  <span
                    onClick={handleLinkToInsiderDashboard}
                    style={{ color: "#00aeef", cursor: "pointer" }}
                  >
                    {" "}
                    {pageContent && pageContent.insiderDashboard
                      ? pageContent.insiderDashboard
                      : "Insider Dashboard"}
                  </span>
                </div>
                <div className="passengerListContainer">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span className="passengersList">
                      {pageContent && pageContent.passengerList
                        ? pageContent.passengerList
                        : "Passengers List"}
                    </span>
                    <div className="count">
                      {addressbookArr?.length}/{PASSENGER_MAX_COUNT}
                    </div>
                  </div>

                  <span className="addPpl">
                    {pageContent && pageContent.addPpl
                      ? pageContent.addPpl
                      : "Add 5 people you travel often with."}
                  </span>

                  {addressbookArr &&
                    addressbookArr.map((add) => {
                      return (
                        <Passenger
                          key={add?.id}
                          addBook={add}
                          pageContent={pageContent}
                        />
                      );
                    })}
                  {addressbookArr?.length < 5 && (
                    <div className="addPplBtn">
                      <Button
                        fullWidth
                        style={{
                          background: "#00aeef",
                          color: "#fff",
                          textTransform: "capitalize",
                          fontSize: "18px",
                        }}
                        variant="contained"
                        onClick={() => {
                          setIsShow(true);
                          setCount(count + 1);
                        }}
                      >
                        {pageContent && pageContent.addPassenger
                          ? pageContent.addPassenger
                          : "Add Passengers"}
                      </Button>
                    </div>
                  )}
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <LoginSignupModal
        isInApp={isInApp}
        addId="addressBookCreate"
        // data={data}
        isShow={isShow}
        setIsShow={() => setIsShow(false)}
        setIsSpinner={setIsSpinner}
        isDim={isDim}
        setIsDim={setIsDim}
        loginEmail={loginEmail}
        setLoginEmail={setLoginEmail}
        loginPassword={loginPassword}
        setLoginPassword={setLoginPassword}
        // handleLogin={handleLogin}
        errorMsg={errorMsg}
        setErrorMsg={setErrorMsg}
        isForget={isForget}
        setIsForget={setIsForget}
        isSpinner={isSpinner}
        // setIsCheck={setIsCheck}
        selectedLanguage={selectedLanguage}
        pageContent={pageContent}
        // clientTag={clientTag}
        page="addressBook"
      />
    </div>
  );
};

export default AddressBook;
