import axios from "axios";
import { CONTENTFUL_API } from "../config/constants";
import { extend } from "lodash";

export const ContentfulEntries = (content_type, language) => {
  let contentId = {},
    contentData = {};
  let contentImages = [];

  return axios
    .get(
      CONTENTFUL_API +
        "&sys.contentType.sys.id[in]=" +
        content_type +
        "&locale=" +
        language,
    )
    .then((response) => {
      if (response && response.data) {
        response.data.items &&
          response.data.items.length > 1 &&
          response.data.items.map((item) => {
            return extend(contentData, item.fields);
          });

        response.data.includes != null &&
          response.data.includes.Asset.map((images) => {
            return (contentImages[images.fields.title] = images.fields.file);
          });

        window.location.search.split("locale=")[1] &&
          window.location.search.split("locale=")[1] === "contentful" &&
          contentData &&
          Object.keys(contentData).map((keyName) => {
            return (contentId[keyName] = keyName);
          });
      }

      return {
        contentId: contentId,
        contentData: contentData,
        contentImages: contentImages,
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        contentId: contentId,
        contentData: contentData,
        contentImages: contentImages,
      };
    });
};
