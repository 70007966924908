export const COUNTRIES = [
  {
    CountryId: 1,
    CountryName: "Afghanistan",
    ISOCode: "AF",
    iso_code_3: "AFG",
    nationality: "Afghan",
    currency_code: "AFN",
    CountryCode: "93",
    Status: 1,
  },
  {
    CountryId: 2,
    CountryName: "\u00c5land Islands",
    ISOCode: "AX",
    iso_code_3: "ALA",
    nationality: "\u00c5land Islander",
    currency_code: "EUR",
    CountryCode: "358",
    Status: 1,
  },
  {
    CountryId: 3,
    CountryName: "Albania",
    ISOCode: "AL",
    iso_code_3: "ALB",
    nationality: "Albanian",
    currency_code: "ALL",
    CountryCode: "355",
    Status: 1,
  },
  {
    CountryId: 4,
    CountryName: "Algeria",
    ISOCode: "DZ",
    iso_code_3: "DZA",
    nationality: "Algerian",
    currency_code: "DZD",
    CountryCode: "213",
    Status: 1,
  },
  {
    CountryId: 5,
    CountryName: "American Samoa",
    ISOCode: "AS",
    iso_code_3: "ASM",
    nationality: "American Samoan",
    currency_code: "USD",
    CountryCode: "1",
    Status: 1,
  },
  {
    CountryId: 6,
    CountryName: "Andorra",
    ISOCode: "AD",
    iso_code_3: "AND",
    nationality: "Andorran",
    currency_code: "EUR",
    CountryCode: "376",
    Status: 1,
  },
  {
    CountryId: 7,
    CountryName: "Angola",
    ISOCode: "AO",
    iso_code_3: "AGO",
    nationality: "Angolan",
    currency_code: "AOA",
    CountryCode: "244",
    Status: 1,
  },
  {
    CountryId: 8,
    CountryName: "Anguilla",
    ISOCode: "AI",
    iso_code_3: "AIA",
    nationality: "Anguillan",
    currency_code: "XCD",
    CountryCode: "1",
    Status: 1,
  },
  {
    CountryId: 9,
    CountryName: "Antarctica",
    ISOCode: "AQ",
    iso_code_3: "ATA",
    nationality: "of Antartica",
    currency_code: "",
    CountryCode: "672",
    Status: 1,
  },
  {
    CountryId: 10,
    CountryName: "Antigua and Barbuda",
    ISOCode: "AG",
    iso_code_3: "ATG",
    nationality: "of Antigua and Barbuda",
    currency_code: "XCD",
    CountryCode: "1",
    Status: 1,
  },
  {
    CountryId: 11,
    CountryName: "Argentina",
    ISOCode: "AR",
    iso_code_3: "ARG",
    nationality: "Argentinian",
    currency_code: "ARS",
    CountryCode: "54",
    Status: 1,
  },
  {
    CountryId: 12,
    CountryName: "Armenia",
    ISOCode: "AM",
    iso_code_3: "ARM",
    nationality: "Armenian",
    currency_code: "AMD",
    CountryCode: "374",
    Status: 1,
  },
  {
    CountryId: 13,
    CountryName: "Aruba",
    ISOCode: "AW",
    iso_code_3: "ABW",
    nationality: "Aruban",
    currency_code: "AWG",
    CountryCode: "297",
    Status: 1,
  },
  {
    CountryId: 14,
    CountryName: "Australia",
    ISOCode: "AU",
    iso_code_3: "AUS",
    nationality: "Australian",
    currency_code: "AUD",
    CountryCode: "61",
    Status: 1,
  },
  {
    CountryId: 15,
    CountryName: "Austria",
    ISOCode: "AT",
    iso_code_3: "AUT",
    nationality: "Austrian",
    currency_code: "EUR",
    CountryCode: "43",
    Status: 1,
  },
  {
    CountryId: 16,
    CountryName: "Azerbaijan",
    ISOCode: "AZ",
    iso_code_3: "AZE",
    nationality: "Azerbaijani",
    currency_code: "AZN",
    CountryCode: "994",
    Status: 1,
  },
  {
    CountryId: 17,
    CountryName: "Bahamas",
    ISOCode: "BS",
    iso_code_3: "BHS",
    nationality: "Bahamian",
    currency_code: "BSD",
    CountryCode: "1",
    Status: 1,
  },
  {
    CountryId: 18,
    CountryName: "Bahrain",
    ISOCode: "BH",
    iso_code_3: "BHR",
    nationality: "Bahraini",
    currency_code: "BHD",
    CountryCode: "973",
    Status: 1,
  },
  {
    CountryId: 19,
    CountryName: "Bangladesh",
    ISOCode: "BD",
    iso_code_3: "BGD",
    nationality: "Bangladeshi",
    currency_code: "BDT",
    CountryCode: "880",
    Status: 1,
  },
  {
    CountryId: 20,
    CountryName: "Barbados",
    ISOCode: "BB",
    iso_code_3: "BRB",
    nationality: "Barbadian",
    currency_code: "BBD",
    CountryCode: "1",
    Status: 1,
  },
  {
    CountryId: 21,
    CountryName: "Belarus",
    ISOCode: "BY",
    iso_code_3: "BLR",
    nationality: "Belarusian",
    currency_code: "BYR",
    CountryCode: "375",
    Status: 1,
  },
  {
    CountryId: 22,
    CountryName: "Belgium",
    ISOCode: "BE",
    iso_code_3: "BEL",
    nationality: "Belgian",
    currency_code: "EUR",
    CountryCode: "32",
    Status: 1,
  },
  {
    CountryId: 23,
    CountryName: "Belize",
    ISOCode: "BZ",
    iso_code_3: "BLZ",
    nationality: "Belizean",
    currency_code: "BZD",
    CountryCode: "501",
    Status: 1,
  },
  {
    CountryId: 24,
    CountryName: "Benin",
    ISOCode: "BJ",
    iso_code_3: "BEN",
    nationality: "Beninese",
    currency_code: "XOF",
    CountryCode: "229",
    Status: 1,
  },
  {
    CountryId: 25,
    CountryName: "Bermuda",
    ISOCode: "BM",
    iso_code_3: "BMU",
    nationality: "Bermudian",
    currency_code: "BMD",
    CountryCode: "1",
    Status: 1,
  },
  {
    CountryId: 26,
    CountryName: "Bhutan",
    ISOCode: "BT",
    iso_code_3: "BTN",
    nationality: "Bhutanese",
    currency_code: "BTN",
    CountryCode: "975",
    Status: 1,
  },
  {
    CountryId: 27,
    CountryName: "Bolivia, Plurinational State of",
    ISOCode: "BO",
    iso_code_3: "BOL",
    nationality: "Bolivian",
    currency_code: "BOB",
    CountryCode: "591",
    Status: 1,
  },
  {
    CountryId: 28,
    CountryName: "Bonaire, Sint Eustatius and Saba",
    ISOCode: "BQ",
    iso_code_3: "BES",
    nationality: "of Bonaire, Sint Eustatius and Saba",
    currency_code: "USD",
    CountryCode: "599",
    Status: 1,
  },
  {
    CountryId: 29,
    CountryName: "Bosnia and Herzegovina",
    ISOCode: "BA",
    iso_code_3: "BIH",
    nationality: "of Bosnia and Herzegovina",
    currency_code: "BAM",
    CountryCode: "387",
    Status: 1,
  },
  {
    CountryId: 30,
    CountryName: "Botswana",
    ISOCode: "BW",
    iso_code_3: "BWA",
    nationality: "Botswanan",
    currency_code: "BWP",
    CountryCode: "267",
    Status: 1,
  },
  {
    CountryId: 31,
    CountryName: "Bouvet Island",
    ISOCode: "BV",
    iso_code_3: "BVT",
    nationality: "of Bouvet island",
    currency_code: "",
    CountryCode: "47",
    Status: 1,
  },
  {
    CountryId: 32,
    CountryName: "Brazil",
    ISOCode: "BR",
    iso_code_3: "BRA",
    nationality: "Brazilian",
    currency_code: "BRL",
    CountryCode: "55",
    Status: 1,
  },
  {
    CountryId: 33,
    CountryName: "British Indian Ocean Territory",
    ISOCode: "IO",
    iso_code_3: "IOT",
    nationality: "Changosian",
    currency_code: "USD",
    CountryCode: "246",
    Status: 1,
  },
  {
    CountryId: 34,
    CountryName: "Brunei Darussalam",
    ISOCode: "BN",
    iso_code_3: "BRN",
    nationality: "Bruneian",
    currency_code: "BND",
    CountryCode: "673",
    Status: 1,
  },
  {
    CountryId: 35,
    CountryName: "Bulgaria",
    ISOCode: "BG",
    iso_code_3: "BGR",
    nationality: "Bulgarian",
    currency_code: "BGN",
    CountryCode: "359",
    Status: 1,
  },
  {
    CountryId: 36,
    CountryName: "Burkina Faso",
    ISOCode: "BF",
    iso_code_3: "BFA",
    nationality: "Burkinabe",
    currency_code: "XOF",
    CountryCode: "226",
    Status: 1,
  },
  {
    CountryId: 37,
    CountryName: "Burundi",
    ISOCode: "BI",
    iso_code_3: "BDI",
    nationality: "Burundian",
    currency_code: "BIF",
    CountryCode: "257",
    Status: 1,
  },
  {
    CountryId: 38,
    CountryName: "Cambodia",
    ISOCode: "KH",
    iso_code_3: "KHM",
    nationality: "Cambodian",
    currency_code: "KHR",
    CountryCode: "855",
    Status: 1,
  },
  {
    CountryId: 39,
    CountryName: "Cameroon",
    ISOCode: "CM",
    iso_code_3: "CMR",
    nationality: "Cameroonian",
    currency_code: "XAF",
    CountryCode: "237",
    Status: 1,
  },
  {
    CountryId: 40,
    CountryName: "Canada",
    ISOCode: "CA",
    iso_code_3: "CAN",
    nationality: "Canadian",
    currency_code: "CAD",
    CountryCode: "1",
    Status: 1,
  },
  {
    CountryId: 41,
    CountryName: "Cape Verde",
    ISOCode: "CV",
    iso_code_3: "CPV",
    nationality: "Cape Verdean",
    currency_code: "CVE",
    CountryCode: "238",
    Status: 1,
  },
  {
    CountryId: 42,
    CountryName: "Cayman Islands",
    ISOCode: "KY",
    iso_code_3: "CYM",
    nationality: "Caymanian",
    currency_code: "KYD",
    CountryCode: "1",
    Status: 1,
  },
  {
    CountryId: 43,
    CountryName: "Central African Republic",
    ISOCode: "CF",
    iso_code_3: "CAF",
    nationality: "Central African",
    currency_code: "XAF",
    CountryCode: "236",
    Status: 1,
  },
  {
    CountryId: 44,
    CountryName: "Chad",
    ISOCode: "TD",
    iso_code_3: "TCD",
    nationality: "Chadian",
    currency_code: "XAF",
    CountryCode: "235",
    Status: 1,
  },
  {
    CountryId: 45,
    CountryName: "Chile",
    ISOCode: "CL",
    iso_code_3: "CHL",
    nationality: "Chilean",
    currency_code: "CLP",
    CountryCode: "56",
    Status: 1,
  },
  {
    CountryId: 46,
    CountryName: "China",
    ISOCode: "CN",
    iso_code_3: "CHN",
    nationality: "Chinese",
    currency_code: "CNY",
    CountryCode: "86",
    Status: 1,
  },
  {
    CountryId: 47,
    CountryName: "Christmas Island",
    ISOCode: "CX",
    iso_code_3: "CXR",
    nationality: "Christmas Islander",
    currency_code: "AUD",
    CountryCode: "61",
    Status: 1,
  },
  {
    CountryId: 48,
    CountryName: "Cocos (Keeling) Islands",
    ISOCode: "CC",
    iso_code_3: "CCK",
    nationality: "Cocos Islander",
    currency_code: "AUD",
    CountryCode: "61",
    Status: 1,
  },
  {
    CountryId: 49,
    CountryName: "Colombia",
    ISOCode: "CO",
    iso_code_3: "COL",
    nationality: "Colombian",
    currency_code: "COP",
    CountryCode: "57",
    Status: 1,
  },
  {
    CountryId: 50,
    CountryName: "Comoros",
    ISOCode: "KM",
    iso_code_3: "COM",
    nationality: "Comorian",
    currency_code: "KMF",
    CountryCode: "269",
    Status: 1,
  },
  {
    CountryId: 51,
    CountryName: "Congo",
    ISOCode: "CG",
    iso_code_3: "COG",
    nationality: "Congolese",
    currency_code: "XAF",
    CountryCode: "242",
    Status: 1,
  },
  {
    CountryId: 52,
    CountryName: "Congo, the Democratic Republic of the",
    ISOCode: "CD",
    iso_code_3: "COD",
    nationality: "Congolese",
    currency_code: "CDF",
    CountryCode: "243",
    Status: 1,
  },
  {
    CountryId: 53,
    CountryName: "Cook Islands",
    ISOCode: "CK",
    iso_code_3: "COK",
    nationality: "Cook Islander",
    currency_code: "NZD",
    CountryCode: "682",
    Status: 1,
  },
  {
    CountryId: 54,
    CountryName: "Costa Rica",
    ISOCode: "CR",
    iso_code_3: "CRI",
    nationality: "Costa Rican",
    currency_code: "CRC",
    CountryCode: "506",
    Status: 1,
  },
  {
    CountryId: 55,
    CountryName: "C\u00f4te d'Ivoire",
    ISOCode: "CI",
    iso_code_3: "CIV",
    nationality: "Ivorian",
    currency_code: "XOF",
    CountryCode: "225",
    Status: 1,
  },
  {
    CountryId: 56,
    CountryName: "Croatia",
    ISOCode: "HR",
    iso_code_3: "HRV",
    nationality: "Croatian",
    currency_code: "HRK",
    CountryCode: "385",
    Status: 1,
  },
  {
    CountryId: 57,
    CountryName: "Cuba",
    ISOCode: "CU",
    iso_code_3: "CUB",
    nationality: "Cuban",
    currency_code: "CUP",
    CountryCode: "53",
    Status: 1,
  },
  {
    CountryId: 58,
    CountryName: "Cura\u00e7ao",
    ISOCode: "CW",
    iso_code_3: "CUW",
    nationality: "Cura\u00e7aoan",
    currency_code: "ANG",
    CountryCode: "599",
    Status: 1,
  },
  {
    CountryId: 59,
    CountryName: "Cyprus",
    ISOCode: "CY",
    iso_code_3: "CYP",
    nationality: "Cypriot",
    currency_code: "EUR",
    CountryCode: "357",
    Status: 1,
  },
  {
    CountryId: 60,
    CountryName: "Czech Republic",
    ISOCode: "CZ",
    iso_code_3: "CZE",
    nationality: "Czech",
    currency_code: "CZK",
    CountryCode: "420",
    Status: 1,
  },
  {
    CountryId: 61,
    CountryName: "Denmark",
    ISOCode: "DK",
    iso_code_3: "DNK",
    nationality: "Danish",
    currency_code: "DKK",
    CountryCode: "45",
    Status: 1,
  },
  {
    CountryId: 62,
    CountryName: "Djibouti",
    ISOCode: "DJ",
    iso_code_3: "DJI",
    nationality: "Djiboutian",
    currency_code: "DJF",
    CountryCode: "253",
    Status: 1,
  },
  {
    CountryId: 63,
    CountryName: "Dominica",
    ISOCode: "DM",
    iso_code_3: "DMA",
    nationality: "Dominican",
    currency_code: "XCD",
    CountryCode: "1",
    Status: 1,
  },
  {
    CountryId: 64,
    CountryName: "Dominican Republic",
    ISOCode: "DO",
    iso_code_3: "DOM",
    nationality: "Dominican",
    currency_code: "DOP",
    CountryCode: "1",
    Status: 1,
  },
  {
    CountryId: 65,
    CountryName: "Ecuador",
    ISOCode: "EC",
    iso_code_3: "ECU",
    nationality: "Ecuadorian",
    currency_code: "USD",
    CountryCode: "593",
    Status: 1,
  },
  {
    CountryId: 66,
    CountryName: "Egypt",
    ISOCode: "EG",
    iso_code_3: "EGY",
    nationality: "Egyptian",
    currency_code: "EGP",
    CountryCode: "20",
    Status: 1,
  },
  {
    CountryId: 67,
    CountryName: "El Salvador",
    ISOCode: "SV",
    iso_code_3: "SLV",
    nationality: "Salvadoran",
    currency_code: "SVC",
    CountryCode: "503",
    Status: 1,
  },
  {
    CountryId: 68,
    CountryName: "Equatorial Guinea",
    ISOCode: "GQ",
    iso_code_3: "GNQ",
    nationality: "Equatorial Guinean",
    currency_code: "XAF",
    CountryCode: "240",
    Status: 1,
  },
  {
    CountryId: 69,
    CountryName: "Eritrea",
    ISOCode: "ER",
    iso_code_3: "ERI",
    nationality: "Eritrean",
    currency_code: "ERN",
    CountryCode: "291",
    Status: 1,
  },
  {
    CountryId: 70,
    CountryName: "Estonia",
    ISOCode: "EE",
    iso_code_3: "EST",
    nationality: "Estonian",
    currency_code: "EUR",
    CountryCode: "372",
    Status: 1,
  },
  {
    CountryId: 71,
    CountryName: "Ethiopia",
    ISOCode: "ET",
    iso_code_3: "ETH",
    nationality: "Ethiopian",
    currency_code: "ETB",
    CountryCode: "251",
    Status: 1,
  },
  {
    CountryId: 72,
    CountryName: "Falkland Islands (Malvinas)",
    ISOCode: "FK",
    iso_code_3: "FLK",
    nationality: "Falkland Islander",
    currency_code: "FKP",
    CountryCode: "500",
    Status: 1,
  },
  {
    CountryId: 73,
    CountryName: "Faroe Islands",
    ISOCode: "FO",
    iso_code_3: "FRO",
    nationality: "Faeroese",
    currency_code: "DKK",
    CountryCode: "298",
    Status: 1,
  },
  {
    CountryId: 74,
    CountryName: "Fiji",
    ISOCode: "FJ",
    iso_code_3: "FJI",
    nationality: "Fijian",
    currency_code: "FJD",
    CountryCode: "679",
    Status: 1,
  },
  {
    CountryId: 75,
    CountryName: "Finland",
    ISOCode: "FI",
    iso_code_3: "FIN",
    nationality: "Finnish",
    currency_code: "EUR",
    CountryCode: "358",
    Status: 1,
  },
  {
    CountryId: 76,
    CountryName: "France",
    ISOCode: "FR",
    iso_code_3: "FRA",
    nationality: "French",
    currency_code: "EUR",
    CountryCode: "33",
    Status: 1,
  },
  {
    CountryId: 77,
    CountryName: "French Guiana",
    ISOCode: "GF",
    iso_code_3: "GUF",
    nationality: "Guianese",
    currency_code: "EUR",
    CountryCode: "594",
    Status: 1,
  },
  {
    CountryId: 78,
    CountryName: "French Polynesia",
    ISOCode: "PF",
    iso_code_3: "PYF",
    nationality: "Polynesian",
    currency_code: "XPF",
    CountryCode: "689",
    Status: 1,
  },
  {
    CountryId: 79,
    CountryName: "French Southern Territories",
    ISOCode: "TF",
    iso_code_3: "ATF",
    nationality: "of French Southern and Antarctic Lands",
    currency_code: "EUR",
    CountryCode: "33",
    Status: 1,
  },
  {
    CountryId: 80,
    CountryName: "Gabon",
    ISOCode: "GA",
    iso_code_3: "GAB",
    nationality: "Gabonese",
    currency_code: "XAF",
    CountryCode: "241",
    Status: 1,
  },
  {
    CountryId: 81,
    CountryName: "Gambia",
    ISOCode: "GM",
    iso_code_3: "GMB",
    nationality: "Gambian",
    currency_code: "GMD",
    CountryCode: "220",
    Status: 1,
  },
  {
    CountryId: 82,
    CountryName: "Georgia",
    ISOCode: "GE",
    iso_code_3: "GEO",
    nationality: "Georgian",
    currency_code: "GEL",
    CountryCode: "995",
    Status: 1,
  },
  {
    CountryId: 83,
    CountryName: "Germany",
    ISOCode: "DE",
    iso_code_3: "DEU",
    nationality: "German",
    currency_code: "EUR",
    CountryCode: "49",
    Status: 1,
  },
  {
    CountryId: 84,
    CountryName: "Ghana",
    ISOCode: "GH",
    iso_code_3: "GHA",
    nationality: "Ghanaian",
    currency_code: "GHS",
    CountryCode: "233",
    Status: 1,
  },
  {
    CountryId: 85,
    CountryName: "Gibraltar",
    ISOCode: "GI",
    iso_code_3: "GIB",
    nationality: "Gibraltarian",
    currency_code: "GIP",
    CountryCode: "350",
    Status: 1,
  },
  {
    CountryId: 86,
    CountryName: "Greece",
    ISOCode: "GR",
    iso_code_3: "GRC",
    nationality: "Greek",
    currency_code: "EUR",
    CountryCode: "30",
    Status: 1,
  },
  {
    CountryId: 87,
    CountryName: "Greenland",
    ISOCode: "GL",
    iso_code_3: "GRL",
    nationality: "Greenlander",
    currency_code: "DKK",
    CountryCode: "299",
    Status: 1,
  },
  {
    CountryId: 88,
    CountryName: "Grenada",
    ISOCode: "GD",
    iso_code_3: "GRD",
    nationality: "Grenadian",
    currency_code: "XCD",
    CountryCode: "1",
    Status: 1,
  },
  {
    CountryId: 89,
    CountryName: "Guadeloupe",
    ISOCode: "GP",
    iso_code_3: "GLP",
    nationality: "Guadeloupean",
    currency_code: "EUR ",
    CountryCode: "590",
    Status: 1,
  },
  {
    CountryId: 90,
    CountryName: "Guam",
    ISOCode: "GU",
    iso_code_3: "GUM",
    nationality: "Guamanian",
    currency_code: "USD",
    CountryCode: "1",
    Status: 1,
  },
  {
    CountryId: 91,
    CountryName: "Guatemala",
    ISOCode: "GT",
    iso_code_3: "GTM",
    nationality: "Guatemalan",
    currency_code: "GTQ",
    CountryCode: "502",
    Status: 1,
  },
  {
    CountryId: 92,
    CountryName: "Guernsey",
    ISOCode: "GG",
    iso_code_3: "GGY",
    nationality: "of Guernsey",
    currency_code: "GGP (GG2)",
    CountryCode: "44",
    Status: 1,
  },
  {
    CountryId: 93,
    CountryName: "Guinea",
    ISOCode: "GN",
    iso_code_3: "GIN",
    nationality: "Guinean",
    currency_code: "GNF",
    CountryCode: "224",
    Status: 1,
  },
  {
    CountryId: 94,
    CountryName: "Guinea-Bissau",
    ISOCode: "GW",
    iso_code_3: "GNB",
    nationality: "Guinea-Bissau national",
    currency_code: "XOF",
    CountryCode: "245",
    Status: 1,
  },
  {
    CountryId: 95,
    CountryName: "Guyana",
    ISOCode: "GY",
    iso_code_3: "GUY",
    nationality: "Guyanese",
    currency_code: "GYD",
    CountryCode: "592",
    Status: 1,
  },
  {
    CountryId: 96,
    CountryName: "Haiti",
    ISOCode: "HT",
    iso_code_3: "HTI",
    nationality: "Haitian",
    currency_code: "HTG",
    CountryCode: "509",
    Status: 1,
  },
  {
    CountryId: 97,
    CountryName: "Heard Island and McDonald Islands",
    ISOCode: "HM",
    iso_code_3: "HMD",
    nationality: "of Territory of Heard Island and McDonald Islands",
    currency_code: "",
    CountryCode: "61",
    Status: 1,
  },
  {
    CountryId: 98,
    CountryName: "Holy See (Vatican City State)",
    ISOCode: "VA",
    iso_code_3: "VAT",
    nationality: "of the Holy See/of the Vatican",
    currency_code: "EUR",
    CountryCode: "39",
    Status: 1,
  },
  {
    CountryId: 99,
    CountryName: "Honduras",
    ISOCode: "HN",
    iso_code_3: "HND",
    nationality: "Honduran",
    currency_code: "HNL",
    CountryCode: "504",
    Status: 1,
  },
  {
    CountryId: 100,
    CountryName: "Hong Kong",
    ISOCode: "HK",
    iso_code_3: "HKG",
    nationality: "Hong Kong Chinese",
    currency_code: "HKD",
    CountryCode: "852",
    Status: 1,
  },
  {
    CountryId: 101,
    CountryName: "Hungary",
    ISOCode: "HU",
    iso_code_3: "HUN",
    nationality: "Hungarian",
    currency_code: "HUF",
    CountryCode: "36",
    Status: 1,
  },
  {
    CountryId: 102,
    CountryName: "Iceland",
    ISOCode: "IS",
    iso_code_3: "ISL",
    nationality: "Icelander",
    currency_code: "ISK",
    CountryCode: "354",
    Status: 1,
  },
  {
    CountryId: 103,
    CountryName: "India",
    ISOCode: "IN",
    iso_code_3: "IND",
    nationality: "Indian",
    currency_code: "INR",
    CountryCode: "91",
    Status: 1,
  },
  {
    CountryId: 104,
    CountryName: "Indonesia",
    ISOCode: "CountryId",
    iso_code_3: "CountryIdN",
    nationality: "Indonesian",
    currency_code: "CountryIdR",
    CountryCode: "62",
    Status: 1,
  },
  {
    CountryId: 105,
    CountryName: "Iran, Islamic Republic of",
    ISOCode: "IR",
    iso_code_3: "IRN",
    nationality: "Iranian",
    currency_code: "IRR",
    CountryCode: "98",
    Status: 1,
  },
  {
    CountryId: 106,
    CountryName: "Iraq",
    ISOCode: "IQ",
    iso_code_3: "IRQ",
    nationality: "Iraqi",
    currency_code: "IQD",
    CountryCode: "964",
    Status: 1,
  },
  {
    CountryId: 107,
    CountryName: "Ireland",
    ISOCode: "IE",
    iso_code_3: "IRL",
    nationality: "Irish",
    currency_code: "EUR",
    CountryCode: "353",
    Status: 1,
  },
  {
    CountryId: 108,
    CountryName: "Isle of Man",
    ISOCode: "IM",
    iso_code_3: "IMN",
    nationality: "Manxman; Manxwoman",
    currency_code: "IMP (IM2)",
    CountryCode: "44",
    Status: 1,
  },
  {
    CountryId: 109,
    CountryName: "Israel",
    ISOCode: "IL",
    iso_code_3: "ISR",
    nationality: "Israeli",
    currency_code: "ILS",
    CountryCode: "972",
    Status: 1,
  },
  {
    CountryId: 110,
    CountryName: "Italy",
    ISOCode: "IT",
    iso_code_3: "ITA",
    nationality: "Italian",
    currency_code: "EUR",
    CountryCode: "39",
    Status: 1,
  },
  {
    CountryId: 111,
    CountryName: "Jamaica",
    ISOCode: "JM",
    iso_code_3: "JAM",
    nationality: "Jamaican",
    currency_code: "JMD",
    CountryCode: "1",
    Status: 1,
  },
  {
    CountryId: 112,
    CountryName: "Japan",
    ISOCode: "JP",
    iso_code_3: "JPN",
    nationality: "Japanese",
    currency_code: "JPY",
    CountryCode: "81",
    Status: 1,
  },
  {
    CountryId: 113,
    CountryName: "Jersey",
    ISOCode: "JE",
    iso_code_3: "JEY",
    nationality: "of Jersey",
    currency_code: "JEP (JE2)",
    CountryCode: "44",
    Status: 1,
  },
  {
    CountryId: 114,
    CountryName: "Jordan",
    ISOCode: "JO",
    iso_code_3: "JOR",
    nationality: "Jordanian",
    currency_code: "JOD",
    CountryCode: "962",
    Status: 1,
  },
  {
    CountryId: 115,
    CountryName: "Kazakhstan",
    ISOCode: "KZ",
    iso_code_3: "KAZ",
    nationality: "Kazakh",
    currency_code: "KZT",
    CountryCode: "7",
    Status: 1,
  },
  {
    CountryId: 116,
    CountryName: "Kenya",
    ISOCode: "KE",
    iso_code_3: "KEN",
    nationality: "Kenyan",
    currency_code: "KES",
    CountryCode: "254",
    Status: 1,
  },
  {
    CountryId: 117,
    CountryName: "Kiribati",
    ISOCode: "KI",
    iso_code_3: "KIR",
    nationality: "Kiribatian",
    currency_code: "AUD",
    CountryCode: "686",
    Status: 1,
  },
  {
    CountryId: 118,
    CountryName: "Korea, Democratic People's Republic of",
    ISOCode: "KP",
    iso_code_3: "PRK",
    nationality: "North Korean",
    currency_code: "KPW",
    CountryCode: "850",
    Status: 1,
  },
  {
    CountryId: 119,
    CountryName: "Korea, Republic of",
    ISOCode: "KR",
    iso_code_3: "KOR",
    nationality: "South Korean",
    currency_code: "KRW",
    CountryCode: "82",
    Status: 1,
  },
  {
    CountryId: 120,
    CountryName: "Kuwait",
    ISOCode: "KW",
    iso_code_3: "KWT",
    nationality: "Kuwaiti",
    currency_code: "KWD",
    CountryCode: "965",
    Status: 1,
  },
  {
    CountryId: 121,
    CountryName: "Kyrgyzstan",
    ISOCode: "KG",
    iso_code_3: "KGZ",
    nationality: "Kyrgyz",
    currency_code: "KGS",
    CountryCode: "996",
    Status: 1,
  },
  {
    CountryId: 122,
    CountryName: "Lao People's Democratic Republic",
    ISOCode: "LA",
    iso_code_3: "LAO",
    nationality: "Lao",
    currency_code: "LAK",
    CountryCode: "856",
    Status: 1,
  },
  {
    CountryId: 123,
    CountryName: "Latvia",
    ISOCode: "LV",
    iso_code_3: "LVA",
    nationality: "Latvian",
    currency_code: "EUR",
    CountryCode: "371",
    Status: 1,
  },
  {
    CountryId: 124,
    CountryName: "Lebanon",
    ISOCode: "LB",
    iso_code_3: "LBN",
    nationality: "Lebanese",
    currency_code: "LBP",
    CountryCode: "961",
    Status: 1,
  },
  {
    CountryId: 125,
    CountryName: "Lesotho",
    ISOCode: "LS",
    iso_code_3: "LSO",
    nationality: "Basotho",
    currency_code: "LSL",
    CountryCode: "266",
    Status: 1,
  },
  {
    CountryId: 126,
    CountryName: "Liberia",
    ISOCode: "LR",
    iso_code_3: "LBR",
    nationality: "Liberian",
    currency_code: "LRD",
    CountryCode: "231",
    Status: 1,
  },
  {
    CountryId: 127,
    CountryName: "Libya",
    ISOCode: "LY",
    iso_code_3: "LBY",
    nationality: "Libyan",
    currency_code: "LYD",
    CountryCode: "218",
    Status: 1,
  },
  {
    CountryId: 128,
    CountryName: "Liechtenstein",
    ISOCode: "LI",
    iso_code_3: "LIE",
    nationality: "Liechtensteiner",
    currency_code: "CHF",
    CountryCode: "423",
    Status: 1,
  },
  {
    CountryId: 129,
    CountryName: "Lithuania",
    ISOCode: "LT",
    iso_code_3: "LTU",
    nationality: "Lithuanian",
    currency_code: "EUR",
    CountryCode: "370",
    Status: 1,
  },
  {
    CountryId: 130,
    CountryName: "Luxembourg",
    ISOCode: "LU",
    iso_code_3: "LUX",
    nationality: "Luxembourger",
    currency_code: "EUR",
    CountryCode: "352",
    Status: 1,
  },
  {
    CountryId: 131,
    CountryName: "Macao",
    ISOCode: "MO",
    iso_code_3: "MAC",
    nationality: "Macanese",
    currency_code: "MOP",
    CountryCode: "853",
    Status: 1,
  },
  {
    CountryId: 132,
    CountryName: "Macedonia, the Former Yugoslav Republic of",
    ISOCode: "MK",
    iso_code_3: "MKD",
    nationality: "of the former Yugoslav Republic of Macedonia",
    currency_code: "MKD",
    CountryCode: "389",
    Status: 1,
  },
  {
    CountryId: 133,
    CountryName: "Madagascar",
    ISOCode: "MG",
    iso_code_3: "MDG",
    nationality: "Malagasy",
    currency_code: "MGA",
    CountryCode: "261",
    Status: 1,
  },
  {
    CountryId: 134,
    CountryName: "Malawi",
    ISOCode: "MW",
    iso_code_3: "MWI",
    nationality: "Malawian",
    currency_code: "MWK",
    CountryCode: "265",
    Status: 1,
  },
  {
    CountryId: 135,
    CountryName: "Malaysia",
    ISOCode: "MY",
    iso_code_3: "MYS",
    nationality: "Malaysian",
    currency_code: "MYR",
    CountryCode: "60",
    Status: 1,
  },
  {
    CountryId: 136,
    CountryName: "Maldives",
    ISOCode: "MV",
    iso_code_3: "MDV",
    nationality: "Maldivian",
    currency_code: "MVR",
    CountryCode: "960",
    Status: 1,
  },
  {
    CountryId: 137,
    CountryName: "Mali",
    ISOCode: "ML",
    iso_code_3: "MLI",
    nationality: "Malian",
    currency_code: "XOF",
    CountryCode: "223",
    Status: 1,
  },
  {
    CountryId: 138,
    CountryName: "Malta",
    ISOCode: "MT",
    iso_code_3: "MLT",
    nationality: "Maltese",
    currency_code: "EUR",
    CountryCode: "356",
    Status: 1,
  },
  {
    CountryId: 139,
    CountryName: "Marshall Islands",
    ISOCode: "MH",
    iso_code_3: "MHL",
    nationality: "Marshallese",
    currency_code: "USD",
    CountryCode: "692",
    Status: 1,
  },
  {
    CountryId: 140,
    CountryName: "Martinique",
    ISOCode: "MQ",
    iso_code_3: "MTQ",
    nationality: "Martinican",
    currency_code: "EUR",
    CountryCode: "596",
    Status: 1,
  },
  {
    CountryId: 141,
    CountryName: "Mauritania",
    ISOCode: "MR",
    iso_code_3: "MRT",
    nationality: "Mauritanian",
    currency_code: "MRO",
    CountryCode: "222",
    Status: 1,
  },
  {
    CountryId: 142,
    CountryName: "Mauritius",
    ISOCode: "MU",
    iso_code_3: "MUS",
    nationality: "Mauritian",
    currency_code: "MUR",
    CountryCode: "230",
    Status: 1,
  },
  {
    CountryId: 143,
    CountryName: "Mayotte",
    ISOCode: "YT",
    iso_code_3: "MYT",
    nationality: "Mahorais",
    currency_code: "EUR",
    CountryCode: "262",
    Status: 1,
  },
  {
    CountryId: 144,
    CountryName: "Mexico",
    ISOCode: "MX",
    iso_code_3: "MEX",
    nationality: "Mexican",
    currency_code: "MXN",
    CountryCode: "52",
    Status: 1,
  },
  {
    CountryId: 145,
    CountryName: "Micronesia, Federated States of",
    ISOCode: "FM",
    iso_code_3: "FSM",
    nationality: "Micronesian",
    currency_code: "USD",
    CountryCode: "691",
    Status: 1,
  },
  {
    CountryId: 146,
    CountryName: "Moldova, Republic of",
    ISOCode: "MD",
    iso_code_3: "MDA",
    nationality: "Moldovan",
    currency_code: "MDL",
    CountryCode: "373",
    Status: 1,
  },
  {
    CountryId: 147,
    CountryName: "Monaco",
    ISOCode: "MC",
    iso_code_3: "MCO",
    nationality: "Monegasque",
    currency_code: "EUR",
    CountryCode: "377",
    Status: 1,
  },
  {
    CountryId: 148,
    CountryName: "Mongolia",
    ISOCode: "MN",
    iso_code_3: "MNG",
    nationality: "Mongolian",
    currency_code: "MNT",
    CountryCode: "976",
    Status: 1,
  },
  {
    CountryId: 149,
    CountryName: "Montenegro",
    ISOCode: "ME",
    iso_code_3: "MNE",
    nationality: "Montenegrin",
    currency_code: "EUR",
    CountryCode: "382",
    Status: 1,
  },
  {
    CountryId: 150,
    CountryName: "Montserrat",
    ISOCode: "MS",
    iso_code_3: "MSR",
    nationality: "Montserratian",
    currency_code: "XCD",
    CountryCode: "1",
    Status: 1,
  },
  {
    CountryId: 151,
    CountryName: "Morocco",
    ISOCode: "MA",
    iso_code_3: "MAR",
    nationality: "Moroccan",
    currency_code: "MAD",
    CountryCode: "212",
    Status: 1,
  },
  {
    CountryId: 152,
    CountryName: "Mozambique",
    ISOCode: "MZ",
    iso_code_3: "MOZ",
    nationality: "Mozambican",
    currency_code: "MZN",
    CountryCode: "258",
    Status: 1,
  },
  {
    CountryId: 153,
    CountryName: "Myanmar",
    ISOCode: "MM",
    iso_code_3: "MMR",
    nationality: "Burmese",
    currency_code: "MMK",
    CountryCode: "95",
    Status: 1,
  },
  {
    CountryId: 154,
    CountryName: "Namibia",
    ISOCode: "NA",
    iso_code_3: "NAM",
    nationality: "Namibian",
    currency_code: "NAD",
    CountryCode: "264",
    Status: 1,
  },
  {
    CountryId: 155,
    CountryName: "Nauru",
    ISOCode: "NR",
    iso_code_3: "NRU",
    nationality: "Nauruan",
    currency_code: "AUD",
    CountryCode: "674",
    Status: 1,
  },
  {
    CountryId: 156,
    CountryName: "Nepal",
    ISOCode: "NP",
    iso_code_3: "NPL",
    nationality: "Nepalese",
    currency_code: "NPR",
    CountryCode: "977",
    Status: 1,
  },
  {
    CountryId: 157,
    CountryName: "Netherlands",
    ISOCode: "NL",
    iso_code_3: "NLD",
    nationality: "Dutch",
    currency_code: "EUR",
    CountryCode: "31",
    Status: 1,
  },
  {
    CountryId: 158,
    CountryName: "New Caledonia",
    ISOCode: "NC",
    iso_code_3: "NCL",
    nationality: "New Caledonian",
    currency_code: "XPF",
    CountryCode: "687",
    Status: 1,
  },
  {
    CountryId: 159,
    CountryName: "New Zealand",
    ISOCode: "NZ",
    iso_code_3: "NZL",
    nationality: "New Zealander",
    currency_code: "NZD",
    CountryCode: "64",
    Status: 1,
  },
  {
    CountryId: 160,
    CountryName: "Nicaragua",
    ISOCode: "NI",
    iso_code_3: "NIC",
    nationality: "Nicaraguan",
    currency_code: "NIO",
    CountryCode: "505",
    Status: 1,
  },
  {
    CountryId: 161,
    CountryName: "Niger",
    ISOCode: "NE",
    iso_code_3: "NER",
    nationality: "Nigerien",
    currency_code: "XOF",
    CountryCode: "227",
    Status: 1,
  },
  {
    CountryId: 162,
    CountryName: "Nigeria",
    ISOCode: "NG",
    iso_code_3: "NGA",
    nationality: "Nigerian",
    currency_code: "NGN",
    CountryCode: "234",
    Status: 1,
  },
  {
    CountryId: 163,
    CountryName: "Niue",
    ISOCode: "NU",
    iso_code_3: "NIU",
    nationality: "Niuean",
    currency_code: "NZD",
    CountryCode: "683",
    Status: 1,
  },
  {
    CountryId: 164,
    CountryName: "Norfolk Island",
    ISOCode: "NF",
    iso_code_3: "NFK",
    nationality: "Norfolk Islander",
    currency_code: "AUD",
    CountryCode: "672",
    Status: 1,
  },
  {
    CountryId: 165,
    CountryName: "Northern Mariana Islands",
    ISOCode: "MP",
    iso_code_3: "MNP",
    nationality: "Northern Mariana Islander",
    currency_code: "USD",
    CountryCode: "1",
    Status: 1,
  },
  {
    CountryId: 166,
    CountryName: "Norway",
    ISOCode: "NO",
    iso_code_3: "NOR",
    nationality: "Norwegian",
    currency_code: "NOK",
    CountryCode: "47",
    Status: 1,
  },
  {
    CountryId: 167,
    CountryName: "Oman",
    ISOCode: "OM",
    iso_code_3: "OMN",
    nationality: "Omani",
    currency_code: "OMR",
    CountryCode: "968",
    Status: 1,
  },
  {
    CountryId: 168,
    CountryName: "Pakistan",
    ISOCode: "PK",
    iso_code_3: "PAK",
    nationality: "Pakistani",
    currency_code: "PKR",
    CountryCode: "92",
    Status: 1,
  },
  {
    CountryId: 169,
    CountryName: "Palau",
    ISOCode: "PW",
    iso_code_3: "PLW",
    nationality: "Palauan",
    currency_code: "USD",
    CountryCode: "680",
    Status: 1,
  },
  {
    CountryId: 170,
    CountryName: "Palestine, State of",
    ISOCode: "PS",
    iso_code_3: "PSE",
    nationality: "Palestinian",
    currency_code: "",
    CountryCode: "970",
    Status: 1,
  },
  {
    CountryId: 171,
    CountryName: "Panama",
    ISOCode: "PA",
    iso_code_3: "PAN",
    nationality: "Panamanian",
    currency_code: "PAB",
    CountryCode: "507",
    Status: 1,
  },
  {
    CountryId: 172,
    CountryName: "Papua New Guinea",
    ISOCode: "PG",
    iso_code_3: "PNG",
    nationality: "Papua New Guinean",
    currency_code: "PGK",
    CountryCode: "675",
    Status: 1,
  },
  {
    CountryId: 173,
    CountryName: "Paraguay",
    ISOCode: "PY",
    iso_code_3: "PRY",
    nationality: "Paraguayan",
    currency_code: "PYG",
    CountryCode: "595",
    Status: 1,
  },
  {
    CountryId: 174,
    CountryName: "Peru",
    ISOCode: "PE",
    iso_code_3: "PER",
    nationality: "Peruvian",
    currency_code: "PEN",
    CountryCode: "51",
    Status: 1,
  },
  {
    CountryId: 175,
    CountryName: "Philippines",
    ISOCode: "PH",
    iso_code_3: "PHL",
    nationality: "Filipino",
    currency_code: "PHP",
    CountryCode: "63",
    Status: 1,
  },
  {
    CountryId: 176,
    CountryName: "Pitcairn",
    ISOCode: "PN",
    iso_code_3: "PCN",
    nationality: "Pitcairner",
    currency_code: "NZD",
    CountryCode: "649",
    Status: 1,
  },
  {
    CountryId: 177,
    CountryName: "Poland",
    ISOCode: "PL",
    iso_code_3: "POL",
    nationality: "Polish",
    currency_code: "PLN",
    CountryCode: "48",
    Status: 1,
  },
  {
    CountryId: 178,
    CountryName: "Portugal",
    ISOCode: "PT",
    iso_code_3: "PRT",
    nationality: "Portuguese",
    currency_code: "EUR",
    CountryCode: "351",
    Status: 1,
  },
  {
    CountryId: 179,
    CountryName: "Puerto Rico",
    ISOCode: "PR",
    iso_code_3: "PRI",
    nationality: "Puerto Rican",
    currency_code: "USD",
    CountryCode: "1",
    Status: 1,
  },
  {
    CountryId: 180,
    CountryName: "Qatar",
    ISOCode: "QA",
    iso_code_3: "QAT",
    nationality: "Qatari",
    currency_code: "QAR",
    CountryCode: "974",
    Status: 1,
  },
  {
    CountryId: 181,
    CountryName: "R\u00e9union",
    ISOCode: "RE",
    iso_code_3: "REU",
    nationality: "Reunionese",
    currency_code: "EUR",
    CountryCode: "262",
    Status: 1,
  },
  {
    CountryId: 182,
    CountryName: "Romania",
    ISOCode: "RO",
    iso_code_3: "ROU",
    nationality: "Romanian",
    currency_code: "RON",
    CountryCode: "40",
    Status: 1,
  },
  {
    CountryId: 183,
    CountryName: "Russian Federation",
    ISOCode: "RU",
    iso_code_3: "RUS",
    nationality: "Russian",
    currency_code: "RUB",
    CountryCode: "7",
    Status: 1,
  },
  {
    CountryId: 184,
    CountryName: "Rwanda",
    ISOCode: "RW",
    iso_code_3: "RWA",
    nationality: "Rwandan; Rwandese",
    currency_code: "RWF",
    CountryCode: "250",
    Status: 1,
  },
  {
    CountryId: 185,
    CountryName: "Saint Barth\u00e9lemy",
    ISOCode: "BL",
    iso_code_3: "BLM",
    nationality: "of Saint Barth\u00e9lemy",
    currency_code: "EUR",
    CountryCode: "590",
    Status: 1,
  },
  {
    CountryId: 186,
    CountryName: "Saint Helena, Ascension and Tristan da Cunha",
    ISOCode: "SH",
    iso_code_3: "SHN",
    nationality: "Saint Helenian",
    currency_code: "SHP",
    CountryCode: "290",
    Status: 1,
  },
  {
    CountryId: 187,
    CountryName: "Saint Kitts and Nevis",
    ISOCode: "KN",
    iso_code_3: "KNA",
    nationality: "Kittsian; Nevisian",
    currency_code: "XCD",
    CountryCode: "1",
    Status: 1,
  },
  {
    CountryId: 188,
    CountryName: "Saint Lucia",
    ISOCode: "LC",
    iso_code_3: "LCA",
    nationality: "Saint Lucian",
    currency_code: "XCD",
    CountryCode: "1",
    Status: 1,
  },
  {
    CountryId: 189,
    CountryName: "Saint Martin (French part)",
    ISOCode: "MF",
    iso_code_3: "MAF",
    nationality: "of Saint Martin",
    currency_code: "EUR",
    CountryCode: "590",
    Status: 1,
  },
  {
    CountryId: 190,
    CountryName: "Saint Pierre and Miquelon",
    ISOCode: "PM",
    iso_code_3: "SPM",
    nationality: "St-Pierrais; Miquelonnais",
    currency_code: "EUR",
    CountryCode: "508",
    Status: 1,
  },
  {
    CountryId: 191,
    CountryName: "Saint Vincent and the Grenadines",
    ISOCode: "VC",
    iso_code_3: "VCT",
    nationality: "Vincentian",
    currency_code: "XCD",
    CountryCode: "1",
    Status: 1,
  },
  {
    CountryId: 192,
    CountryName: "Samoa",
    ISOCode: "WS",
    iso_code_3: "WSM",
    nationality: "Samoan",
    currency_code: "WST",
    CountryCode: "685",
    Status: 1,
  },
  {
    CountryId: 193,
    CountryName: "San Marino",
    ISOCode: "SM",
    iso_code_3: "SMR",
    nationality: "San Marinese",
    currency_code: "EUR ",
    CountryCode: "378",
    Status: 1,
  },
  {
    CountryId: 194,
    CountryName: "Sao Tome and Principe",
    ISOCode: "ST",
    iso_code_3: "STP",
    nationality: "S\u00e3o Tom\u00e9an",
    currency_code: "STD",
    CountryCode: "239",
    Status: 1,
  },
  {
    CountryId: 195,
    CountryName: "Saudi Arabia",
    ISOCode: "SA",
    iso_code_3: "SAU",
    nationality: "Saudi Arabian",
    currency_code: "SAR",
    CountryCode: "966",
    Status: 1,
  },
  {
    CountryId: 196,
    CountryName: "Senegal",
    ISOCode: "SN",
    iso_code_3: "SEN",
    nationality: "Senegalese",
    currency_code: "XOF",
    CountryCode: "221",
    Status: 1,
  },
  {
    CountryId: 197,
    CountryName: "Serbia",
    ISOCode: "RS",
    iso_code_3: "SRB",
    nationality: "Serb",
    currency_code: "RSD",
    CountryCode: "381",
    Status: 1,
  },
  {
    CountryId: 198,
    CountryName: "Seychelles",
    ISOCode: "SC",
    iso_code_3: "SYC",
    nationality: "Seychellois",
    currency_code: "SCR",
    CountryCode: "248",
    Status: 1,
  },
  {
    CountryId: 199,
    CountryName: "Sierra Leone",
    ISOCode: "SL",
    iso_code_3: "SLE",
    nationality: "Sierra Leonean",
    currency_code: "SLL",
    CountryCode: "232",
    Status: 1,
  },
  {
    CountryId: 200,
    CountryName: "Singapore",
    ISOCode: "SG",
    iso_code_3: "SGP",
    nationality: "Singaporean",
    currency_code: "SGD",
    CountryCode: "65",
    Status: 1,
  },
  {
    CountryId: 201,
    CountryName: "Sint Maarten (Dutch part)",
    ISOCode: "SX",
    iso_code_3: "SXM",
    nationality: "Sint Maartener",
    currency_code: "ANG",
    CountryCode: "721",
    Status: 1,
  },
  {
    CountryId: 202,
    CountryName: "Slovakia",
    ISOCode: "SK",
    iso_code_3: "SVK",
    nationality: "Slovak",
    currency_code: "EUR",
    CountryCode: "421",
    Status: 1,
  },
  {
    CountryId: 203,
    CountryName: "Slovenia",
    ISOCode: "SI",
    iso_code_3: "SVN",
    nationality: "Slovene",
    currency_code: "EUR",
    CountryCode: "386",
    Status: 1,
  },
  {
    CountryId: 204,
    CountryName: "Solomon Islands",
    ISOCode: "SB",
    iso_code_3: "SLB",
    nationality: "Solomon Islander",
    currency_code: "SBD",
    CountryCode: "677",
    Status: 1,
  },
  {
    CountryId: 205,
    CountryName: "Somalia",
    ISOCode: "SO",
    iso_code_3: "SOM",
    nationality: "Somali",
    currency_code: "SOS",
    CountryCode: "252",
    Status: 1,
  },
  {
    CountryId: 206,
    CountryName: "South Africa",
    ISOCode: "ZA",
    iso_code_3: "ZAF",
    nationality: "South African",
    currency_code: "ZAR",
    CountryCode: "27",
    Status: 1,
  },
  {
    CountryId: 207,
    CountryName: "South Georgia and the South Sandwich Islands",
    ISOCode: "GS",
    iso_code_3: "SGS",
    nationality: "of South Georgia and the South Sandwich Islands",
    currency_code: "",
    CountryCode: "44",
    Status: 1,
  },
  {
    CountryId: 208,
    CountryName: "South Sudan",
    ISOCode: "SS",
    iso_code_3: "SSD",
    nationality: "South Sudanese",
    currency_code: "SSP",
    CountryCode: "211",
    Status: 1,
  },
  {
    CountryId: 209,
    CountryName: "Spain",
    ISOCode: "ES",
    iso_code_3: "ESP",
    nationality: "Spaniard",
    currency_code: "EUR",
    CountryCode: "34",
    Status: 1,
  },
  {
    CountryId: 210,
    CountryName: "Sri Lanka",
    ISOCode: "LK",
    iso_code_3: "LKA",
    nationality: "Sri Lankan",
    currency_code: "LKR",
    CountryCode: "94",
    Status: 1,
  },
  {
    CountryId: 211,
    CountryName: "Sudan",
    ISOCode: "SD",
    iso_code_3: "SDN",
    nationality: "Sudanese",
    currency_code: "SDG",
    CountryCode: "249",
    Status: 1,
  },
  {
    CountryId: 212,
    CountryName: "Suriname",
    ISOCode: "SR",
    iso_code_3: "SUR",
    nationality: "Surinamese",
    currency_code: "SRD",
    CountryCode: "597",
    Status: 1,
  },
  {
    CountryId: 213,
    CountryName: "Svalbard and Jan Mayen",
    ISOCode: "SJ",
    iso_code_3: "SJM",
    nationality: "of Svalbard",
    currency_code: "NOK",
    CountryCode: "47",
    Status: 1,
  },
  {
    CountryId: 214,
    CountryName: "Swaziland",
    ISOCode: "SZ",
    iso_code_3: "SWZ",
    nationality: "Swazi",
    currency_code: "SZL",
    CountryCode: "268",
    Status: 1,
  },
  {
    CountryId: 215,
    CountryName: "Sweden",
    ISOCode: "SE",
    iso_code_3: "SWE",
    nationality: "Swedish",
    currency_code: "SEK",
    CountryCode: "46",
    Status: 1,
  },
  {
    CountryId: 216,
    CountryName: "Switzerland",
    ISOCode: "CH",
    iso_code_3: "CHE",
    nationality: "Swiss",
    currency_code: "CHF",
    CountryCode: "41",
    Status: 1,
  },
  {
    CountryId: 217,
    CountryName: "Syrian Arab Republic",
    ISOCode: "SY",
    iso_code_3: "SYR",
    nationality: "Syrian",
    currency_code: "SYP",
    CountryCode: "963",
    Status: 1,
  },
  {
    CountryId: 218,
    CountryName: "Taiwan",
    ISOCode: "TW",
    iso_code_3: "TWN",
    nationality: "Taiwanese",
    currency_code: "TWD",
    CountryCode: "886",
    Status: 1,
  },
  {
    CountryId: 219,
    CountryName: "Tajikistan",
    ISOCode: "TJ",
    iso_code_3: "TJK",
    nationality: "Tajik",
    currency_code: "TJS",
    CountryCode: "992",
    Status: 1,
  },
  {
    CountryId: 220,
    CountryName: "Tanzania, United Republic of",
    ISOCode: "TZ",
    iso_code_3: "TZA",
    nationality: "Tanzanian",
    currency_code: "TZS",
    CountryCode: "255",
    Status: 1,
  },
  {
    CountryId: 221,
    CountryName: "Thailand",
    ISOCode: "TH",
    iso_code_3: "THA",
    nationality: "Thai",
    currency_code: "THB",
    CountryCode: "66",
    Status: 1,
  },
  {
    CountryId: 222,
    CountryName: "Timor-Leste",
    ISOCode: "TL",
    iso_code_3: "TLS",
    nationality: "East Timorese",
    currency_code: "USD",
    CountryCode: "670",
    Status: 1,
  },
  {
    CountryId: 223,
    CountryName: "Togo",
    ISOCode: "TG",
    iso_code_3: "TGO",
    nationality: "Togolese",
    currency_code: "XOF",
    CountryCode: "228",
    Status: 1,
  },
  {
    CountryId: 224,
    CountryName: "Tokelau",
    ISOCode: "TK",
    iso_code_3: "TKL",
    nationality: "Tokelauan",
    currency_code: "NZD",
    CountryCode: "690",
    Status: 1,
  },
  {
    CountryId: 225,
    CountryName: "Tonga",
    ISOCode: "TO",
    iso_code_3: "TON",
    nationality: "Tongan",
    currency_code: "TOP",
    CountryCode: "676",
    Status: 1,
  },
  {
    CountryId: 226,
    CountryName: "TrinCountryIdad and Tobago",
    ISOCode: "TT",
    iso_code_3: "TTO",
    nationality: "TrinCountryIdadian; Tobagonian",
    currency_code: "TTD",
    CountryCode: "1",
    Status: 1,
  },
  {
    CountryId: 227,
    CountryName: "Tunisia",
    ISOCode: "TN",
    iso_code_3: "TUN",
    nationality: "Tunisian",
    currency_code: "TND",
    CountryCode: "216",
    Status: 1,
  },
  {
    CountryId: 228,
    CountryName: "Turkey",
    ISOCode: "TR",
    iso_code_3: "TUR",
    nationality: "Turk",
    currency_code: "TRY",
    CountryCode: "90",
    Status: 1,
  },
  {
    CountryId: 229,
    CountryName: "Turkmenistan",
    ISOCode: "TM",
    iso_code_3: "TKM",
    nationality: "Turkmen",
    currency_code: "TMT",
    CountryCode: "993",
    Status: 1,
  },
  {
    CountryId: 230,
    CountryName: "Turks and Caicos Islands",
    ISOCode: "TC",
    iso_code_3: "TCA",
    nationality: "Turks and Caicos Islander",
    currency_code: "USD",
    CountryCode: "1",
    Status: 1,
  },
  {
    CountryId: 231,
    CountryName: "Tuvalu",
    ISOCode: "TV",
    iso_code_3: "TUV",
    nationality: "Tuvaluan",
    currency_code: "AUD",
    CountryCode: "688",
    Status: 1,
  },
  {
    CountryId: 232,
    CountryName: "Uganda",
    ISOCode: "UG",
    iso_code_3: "UGA",
    nationality: "Ugandan",
    currency_code: "UGX",
    CountryCode: "256",
    Status: 1,
  },
  {
    CountryId: 233,
    CountryName: "Ukraine",
    ISOCode: "UA",
    iso_code_3: "UKR",
    nationality: "Ukrainian",
    currency_code: "UAH",
    CountryCode: "380",
    Status: 1,
  },
  {
    CountryId: 234,
    CountryName: "United Arab Emirates",
    ISOCode: "AE",
    iso_code_3: "ARE",
    nationality: "Emirian",
    currency_code: "AED",
    CountryCode: "971",
    Status: 1,
  },
  {
    CountryId: 235,
    CountryName: "United Kingdom",
    ISOCode: "GB",
    iso_code_3: "GBR",
    nationality: "British",
    currency_code: "GBP",
    CountryCode: "44",
    Status: 1,
  },
  {
    CountryId: 236,
    CountryName: "United States",
    ISOCode: "US",
    iso_code_3: "USA",
    nationality: "American",
    currency_code: "USD",
    CountryCode: "1",
    Status: 1,
  },
  {
    CountryId: 237,
    CountryName: "United States Minor Outlying Islands",
    ISOCode: "UM",
    iso_code_3: "UMI",
    nationality: "of United States Minor Outlying Islands",
    currency_code: "USD",
    CountryCode: "1",
    Status: 1,
  },
  {
    CountryId: 238,
    CountryName: "Uruguay",
    ISOCode: "UY",
    iso_code_3: "URY",
    nationality: "Uruguayan",
    currency_code: "UYU",
    CountryCode: "598",
    Status: 1,
  },
  {
    CountryId: 239,
    CountryName: "Uzbekistan",
    ISOCode: "UZ",
    iso_code_3: "UZB",
    nationality: "Uzbek",
    currency_code: "UZS",
    CountryCode: "998",
    Status: 1,
  },
  {
    CountryId: 240,
    CountryName: "Vanuatu",
    ISOCode: "VU",
    iso_code_3: "VUT",
    nationality: "Vanuatuan",
    currency_code: "VUV",
    CountryCode: "678",
    Status: 1,
  },
  {
    CountryId: 241,
    CountryName: "Venezuela, Bolivarian Republic of",
    ISOCode: "VE",
    iso_code_3: "VEN",
    nationality: "Venezuelan",
    currency_code: "VEF",
    CountryCode: "58",
    Status: 1,
  },
  {
    CountryId: 242,
    CountryName: "Viet Nam",
    ISOCode: "VN",
    iso_code_3: "VNM",
    nationality: "Vietnamese",
    currency_code: "VND",
    CountryCode: "84",
    Status: 1,
  },
  {
    CountryId: 243,
    CountryName: "Virgin Islands, British",
    ISOCode: "VG",
    iso_code_3: "VGB",
    nationality: "British Virgin Islander;",
    currency_code: "USD",
    CountryCode: "1",
    Status: 1,
  },
  {
    CountryId: 244,
    CountryName: "Virgin Islands, U.S.",
    ISOCode: "VI",
    iso_code_3: "VIR",
    nationality: "US Virgin Islander",
    currency_code: "USD",
    CountryCode: "1",
    Status: 1,
  },
  {
    CountryId: 245,
    CountryName: "Wallis and Futuna",
    ISOCode: "WF",
    iso_code_3: "WLF",
    nationality: "Wallisian; Futunan; Wallis and Futuna Islander",
    currency_code: "XPF",
    CountryCode: "681",
    Status: 1,
  },
  {
    CountryId: 246,
    CountryName: "Western Sahara",
    ISOCode: "EH",
    iso_code_3: "ESH",
    nationality: "Sahrawi",
    currency_code: "MAD",
    CountryCode: "212",
    Status: 1,
  },
  {
    CountryId: 247,
    CountryName: "Yemen",
    ISOCode: "YE",
    iso_code_3: "YEM",
    nationality: "Yemenite",
    currency_code: "YER",
    CountryCode: "967",
    Status: 1,
  },
  {
    CountryId: 248,
    CountryName: "Zambia",
    ISOCode: "ZM",
    iso_code_3: "ZMB",
    nationality: "Zambian",
    currency_code: "ZMW",
    CountryCode: "260",
    Status: 1,
  },
  {
    CountryId: 249,
    CountryName: "Zimbabwe",
    ISOCode: "ZW",
    iso_code_3: "ZWE",
    nationality: "Zimbabwean",
    currency_code: "ZWL",
    CountryCode: "263",
    Status: 1,
  },
];
