import { gql } from "apollo-boost";

const GET_ADDRESSBOOKS = gql`
  query (
    $filter: [filterInput]
    $first: Int!
    $page: Int
    $orderBy: [OrderByClause!]
  ) {
    addressBooks(
      filter: $filter
      first: $first
      page: $page
      orderBy: $orderBy
    ) {
      data {
        id
        arranger {
          id
          first_name
          last_name
        }
        deleted_at
        prefix
        first_name
        last_name
        phone
        email
        nationality {
          id
          name
        }
        dob
        passport
        passport_exp
        nrc
        address
        township {
          id
          name
          city {
            id
            region {
              id
            }
          }
        }
      }
      paginatorInfo {
        count
        currentPage
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const ADDRESSBOOK_BY_ARRANGER = gql`
  query (
    $filter: [filterInput]
    $first: Int!
    $page: Int
    $orderBy: [OrderByClause!]
  ) {
    addressBooksByArranger(
      filter: $filter
      first: $first
      page: $page
      orderBy: $orderBy
    ) {
      data {
        id
        arranger {
          id
          first_name
          last_name
        }
        deleted_at
        prefix
        first_name
        last_name
        phone
        email
        nationality {
          id
          name
          iso_code_2
          iso_code_3
          currency_code
          phone
        }
        dob
        passport
        passport_exp
        nrc
        address
        township {
          id
          name
          city {
            id
            name
            region {
              id
              name
            }
          }
        }
      }
      paginatorInfo {
        count
        currentPage
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const CREATE_ADDRESSBOOK = gql`
  mutation ($input: addressBookInput) {
    createAddressBook(input: $input) {
      id
      arranger {
        id
        first_name
        last_name
      }
      deleted_at
      prefix
      first_name
      last_name
      phone
      email
      nationality {
        id
        name
      }
      dob
      passport
      passport_exp
      nrc
      address
      township {
        id
        name
      }
    }
  }
`;

const GET_ADDRESSBOOK = gql`
  query ($id: ID!) {
    addressBook(id: $id) {
      id
      arranger {
        id
        first_name
        last_name
      }
      deleted_at
      prefix
      first_name
      last_name
      phone
      email
      nationality {
        id
        name
      }
      dob
      passport
      passport_exp
      nrc
      address
      township {
        id
        name
        city_id
        city {
          id
          name
          region_id
          region {
            id
            name
          }
        }
      }
    }
  }
`;

const DELETE_ADDRESS_PASSENGER = gql`
  mutation deleteAddressPassenger($id: ID!) {
    deleteAddressPassenger(id: $id) {
      id
      arranger {
        id
        first_name
        last_name
      }
      deleted_at
      prefix
      first_name
      last_name
      phone
      email
      nationality {
        id
        name
      }
      dob
      passport
      passport_exp
      nrc
      address
      township {
        id
        name
      }
    }
  }
`;

export {
  GET_ADDRESSBOOKS,
  CREATE_ADDRESSBOOK,
  DELETE_ADDRESS_PASSENGER,
  GET_ADDRESSBOOK,
  ADDRESSBOOK_BY_ARRANGER,
};
