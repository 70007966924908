import { object, string } from "yup";
import { PhoneValidation } from "../../utils/phoneValidation";

const ValidationMethod = (props) => {
  const socialLogin = props.isSocialLogin;
  const pageName = props.page;

  if (socialLogin) {
    return object().shape({
      signupFirstName: string()
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
        .test("signupFirstName", "First name is required", (value) => {
          return value?.trim() === "" ? false : true;
        })
        .required("First Name is required."),
      signupLastName: string()
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
        .test("signupLastName", "Last name is required", (value) => {
          return value?.trim() === "" ? false : true;
        })
        .required("Last Name is required."),
      signupEmail: string()
        .email("Please enter valid email address.")
        .required("Email is required."),
      signupPhone: string()
        .matches(/^[0-9]\d{4,20}$/, {
          message: "Please enter valid number.",
          excludeEmptyString: false,
        })
        .when("signupPhonePrefix", {
          is: "95",
          then: string().test(
            "signupPhone",
            "Please enter valid number.",
            (value) => {
              return PhoneValidation(value);
            },
          ),
        })
        .required("Phone is required."),
    });
  } else if (pageName === "addressBook") {
    return object().shape({
      signupFirstName: string()
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
        .test("signupFirstName", "First name is required", (value) => {
          return value?.trim() === "" ? false : true;
        })
        .required("First Name is required."),
      signupLastName: string()
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
        .test("signupLastName", "Last name is required", (value) => {
          return value?.trim() === "" ? false : true;
        })
        .required("Last Name is required."),
      signupNrcPass: string().required("Passport is required."),
      signupExpDay: string()
        .required("Exp Day is required.")
        .test("expDay", "Exp Day is required.", (value) => {
          if (value === "00") {
            return false;
          } else {
            return true;
          }
        }),
      signupExpMonth: string()
        .required("Exp Month is required.")
        .test("expDay", "Exp Month is required.", (value) => {
          if (value === "00") {
            return false;
          } else {
            return true;
          }
        }),
      signupExpYear: string()
        .required("Exp Year is required.")
        .test("expDay", "Exp Year is required.", (value) => {
          if (value === "0000") {
            return false;
          } else {
            return true;
          }
        }),
    });
  } else if (pageName === "profile") {
    return object().shape({
      signupFirstName: string()
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
        .test("signupFirstName", "First name is required", (value) => {
          return value?.trim() === "" ? false : true;
        })
        .required("First Name is required."),
      signupLastName: string()
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
        .test("signupLastName", "Last name is required", (value) => {
          return value?.trim() === "" ? false : true;
        })
        .required("Last Name is required."),
    });
  } else if (pageName === "emailLinkAuth") {
    return object().shape({
      signupFirstName: string()
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
        .test("signupFirstName", "First name is required", (value) => {
          return value?.trim() === "" ? false : true;
        })
        .required("First Name is required."),
      signupLastName: string()
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
        .test("signupLastName", "Last name is required", (value) => {
          return value?.trim() === "" ? false : true;
        })
        .required("Last Name is required."),
      signupEmail: string()
        .email("Please enter valid email address.")
        .required("Email is required."),
      signupPhone: string()
        .matches(/^[0-9]\d{4,20}$/, {
          message: "Please enter valid number.",
          excludeEmptyString: false,
        })
        .when("signupPhonePrefix", {
          is: "95",
          then: string().test(
            "signupPhone",
            "Please enter valid number.",
            (value) => {
              return PhoneValidation(value);
            },
          ),
        })
        .required("Phone is required."),
    });
  } else {
    return object().shape({
      signupFirstName: string()
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
        .test("signupFirstName", "First name is required", (value) => {
          return value?.trim() === "" ? false : true;
        })
        .required("First Name is required."),
      signupLastName: string()
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
        .test("signupLastName", "Last name is required", (value) => {
          return value?.trim() === "" ? false : true;
        })
        .required("Last Name is required."),
      signupEmail: string()
        .email("Please enter valid email address.")
        .required("Email is required."),
      signupPassword: string()
        .min(8, "Password should be 8 characters long.")
        .required("Password is required."),
      // signupConfirmPassword: string()
      //   .oneOf([ref("signupPassword"), null], "Password does not match.")
      //   .required("Confirm Password is required."),
      signupPhone: string()
        .matches(/^[0-9]\d{4,20}$/, {
          message: "Please enter valid number.",
          excludeEmptyString: false,
        })
        .when("signupPhonePrefix", {
          is: "95",
          then: string().test(
            "signupPhone",
            "Please enter valid number.",
            (value) => {
              return PhoneValidation(value);
            },
          ),
        })
        .required("Phone is required."),
    });
  }
};

export default ValidationMethod;
