import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../../assets/scss/loginusers/Passenger.scss";
import {
  ADDRESSBOOK_ID,
  EN_LOCALE,
  LOGIN_USER_ID,
  MM_LOCALE,
  MY_MM,
} from "../../config/constants";
import { checkInApp, checkInWeb, getLocale } from "../../utils/checkurl";
import { getAge, getPassengerType } from "../../utils/addressbookUtils";

const Passenger = ({ addBook, pageContent }) => {
  const params = useParams();
  const navigate = useNavigate();
  const [passType, setPassType] = useState("");
  const [language] = useState(
    getLocale(params.locale) === MY_MM ? MM_LOCALE : EN_LOCALE,
  );
  const [selectedLanguage] = useState(getLocale(params.locale));
  const [isInApp] = useState(checkInApp(window.location.pathname));
  const [isInWeb] = useState(checkInWeb(window.location.pathname));

  useEffect(() => {
    let age = getAge(addBook?.dob);
    let passengerType = getPassengerType(age);
    let psType;
    if (passengerType === "Adult") {
      psType = pageContent && pageContent.adult ? pageContent.adult : "Adult";
    } else if (passengerType === "Child") {
      psType = pageContent && pageContent.child ? pageContent.child : "Child";
    } else {
      psType =
        pageContent && pageContent.infant ? pageContent.infant : "Infant";
    }
    setPassType(psType);
  }, [addBook?.dob, pageContent]);

  const handleLinkToAddressBookDetail = () => {
    localStorage.setItem(ADDRESSBOOK_ID, addBook?.id);
    navigate({
      pathname: isInApp
        ? `/${selectedLanguage}/in-app/address-book-detail/${localStorage.getItem(
            LOGIN_USER_ID,
          )}`
        : isInWeb
        ? `/${selectedLanguage}/in-web/address-book-detail/${localStorage.getItem(
            LOGIN_USER_ID,
          )}`
        : `/${selectedLanguage}/address-book-detail/${localStorage.getItem(
            LOGIN_USER_ID,
          )}`,
      search: window.location.search,
    });
  };

  return (
    <div onClick={handleLinkToAddressBookDetail}>
      <div className="passengerContainer">
        <div className="passengerAddressbook">
          <div className="fullName">
            {pageContent && pageContent.fullName
              ? pageContent.fullName
              : "Full Name"}
          </div>

          <div className="arranger">{passType}</div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div className="fullNameText">
            {addBook && addBook?.prefix} {addBook?.first_name}{" "}
            {addBook?.last_name}
          </div>
          {/* <div className="passType">{passType}</div> */}
        </div>
      </div>
    </div>
  );
};

export default Passenger;
