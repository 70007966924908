import { isAuthenticated } from "../lib/auth";
import {
  SALES_WAVEPAY_APP,
  SALES_ONEPAY_APP,
  SALES_KBZPAY_APP,
  SALES_MPITESAN_APP,
  SALES_AYAPAY_APP,
  SALES_CITIZENPAY_APP,
  SALES_MOMONEY_APP,
  SALES_UAB_APP,
  SALES_DESKTOP,
  SALES_MOBILE,
  SALES_TABLET,
  SALES_B2B_PORTAL,
  B2B_OFFLINE_PORTAL,
  SALES_B2B_OFFLINE_PORTAL,
  B2B_CORPORATE_OFFLINE,
  B2B_CORPORATE_ONLINE,
  B2BCLASSTYPE_N_ID,
  B2BCLASSTYPE_C_ID,
  B2BCLASSTYPE_B_ID,
  B2BCLASSTYPE_S_ID,
  IS_B2B,
  IS_EMPLOYEE,
  SALES_NINJA_VAN_INWEB,
  SALES_UAB_INWEB,
  SALES_ABC_STORE_INWEB,
  SALES_KBZ_INWEB,
  SALES_VIBER_INWEB,
  SALES_CITY_REWARD_INWEB,
} from "../config/constants";
import { isDesktop, isMobileOnly, isTablet } from "react-device-detect";
import {
  WAVEMONEY,
  KBZ,
  ONEPAY,
  MPITESAN,
  CITIZENPAY,
  AYAPAY,
  MOMONEY,
  UABPAY,
} from "../config/inappclients";
import {
  VIBER_IN_WEB,
  KBZSC_IN_WEB,
  CITY_REWARD_IN_WEB,
  NINJA_VAN_IN_WEB,
  UAB_IN_WAB,
  ABC_IN_WAB,
} from "../config/inwebclients";
import { orderBy } from "lodash";

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const GetSalesChannelID = (
  values,
  strData,
  isInApp,
  isInWeb,
  clientTag,
) => {
  const isB2B =
    localStorage.getItem(IS_B2B) && localStorage.getItem(IS_B2B) === "yes"
      ? true
      : false;
  const isEmployee =
    localStorage.getItem(IS_EMPLOYEE) &&
    localStorage.getItem(IS_EMPLOYEE) === "yes"
      ? true
      : false;

  return isAuthenticated() &&
    isB2B &&
    values.b2bPeopleId &&
    strData &&
    strData.company &&
    strData.company.class_type &&
    (strData.company.class_type.id === B2BCLASSTYPE_N_ID ||
      strData.company.class_type.id === B2BCLASSTYPE_C_ID ||
      strData.company.class_type.id === B2BCLASSTYPE_S_ID)
    ? B2B_CORPORATE_ONLINE
    : (isB2B &&
        values.b2bPeopleId &&
        strData &&
        strData.company &&
        strData.company.class_type &&
        (strData.company.class_type.id !== B2BCLASSTYPE_N_ID ||
          strData.company.class_type.id !== B2BCLASSTYPE_C_ID ||
          strData.company.class_type.id !== B2BCLASSTYPE_S_ID)) ||
      (isB2B && values.b2bPeopleId)
    ? SALES_B2B_PORTAL
    : isEmployee &&
      (localStorage.getItem("companyClassType") === B2BCLASSTYPE_N_ID ||
        localStorage.getItem("companyClassType") === B2BCLASSTYPE_C_ID ||
        localStorage.getItem("companyClassType") === B2BCLASSTYPE_S_ID)
    ? B2B_CORPORATE_OFFLINE
    : isEmployee &&
      localStorage.getItem("companyClassType") === B2BCLASSTYPE_B_ID
    ? B2B_OFFLINE_PORTAL
    : isEmployee &&
      (localStorage.getItem("companyClassType") !== B2BCLASSTYPE_N_ID ||
        localStorage.getItem("companyClassType") !== B2BCLASSTYPE_C_ID ||
        localStorage.getItem("companyClassType") !== B2BCLASSTYPE_S_ID ||
        localStorage.getItem("companyClassType") !== B2BCLASSTYPE_B_ID)
    ? SALES_B2B_OFFLINE_PORTAL
    : isInApp
    ? clientTag === WAVEMONEY
      ? SALES_WAVEPAY_APP
      : clientTag === ONEPAY
      ? SALES_ONEPAY_APP
      : clientTag === KBZ
      ? SALES_KBZPAY_APP
      : clientTag === MPITESAN
      ? SALES_MPITESAN_APP
      : clientTag === AYAPAY
      ? SALES_AYAPAY_APP
      : clientTag === CITIZENPAY
      ? SALES_CITIZENPAY_APP
      : clientTag === MOMONEY
      ? SALES_MOMONEY_APP
      : clientTag === UABPAY
      ? SALES_UAB_APP
      : SALES_DESKTOP
    : isInWeb
    ? clientTag === VIBER_IN_WEB
      ? SALES_VIBER_INWEB
      : clientTag === KBZSC_IN_WEB
      ? SALES_KBZ_INWEB
      : clientTag === CITY_REWARD_IN_WEB
      ? SALES_CITY_REWARD_INWEB
      : clientTag === NINJA_VAN_IN_WEB
      ? SALES_NINJA_VAN_INWEB
      : clientTag === UAB_IN_WAB
      ? SALES_UAB_INWEB
      : clientTag === ABC_IN_WAB
      ? SALES_ABC_STORE_INWEB
      : SALES_DESKTOP
    : isDesktop
    ? SALES_DESKTOP
    : isMobileOnly
    ? SALES_MOBILE
    : isTablet
    ? SALES_TABLET
    : SALES_DESKTOP;
};

export const GetArrangerPhonePrefix = (phones) => {
  return phones && phones.length > 0
    ? phones.find((val) => val.is_primary)
      ? phones.find((val) => val.is_primary).phone_prefix
      : orderBy(phones, ["id"], ["desc"])[0].phone_prefix
    : "";
};

export const GetArrangerPhone = (phones) => {
  return phones && phones.length > 0
    ? phones.find((val) => val.is_primary)
      ? phones.find((val) => val.is_primary).phone
      : orderBy(phones, ["id"], ["desc"])[0].phone
    : "";
};

export const GetArrangerEmail = (emails) => {
  return emails && emails.length > 0
    ? emails.find((val) => val.is_primary)
      ? emails.find((val) => val.is_primary).email
      : orderBy(emails, ["id"], ["desc"])[0].email
    : "";
};

export const GetArrangerOTPId = (data) => {
  return data && data.length > 0
    ? data.find((val) => Number(val.id) === 5)
    : null;
};

export const GetSocialLoginId = (data) => {
  return data && data.length > 0
    ? data.find((val) => Number(val.id) === 5)
    : null;
};

export const GetPasswordLoginId = (data) => {
  return data && data.length > 0
    ? data.find((val) => Number(val.id) === 4)
    : null;
};
