import React from "react";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const PhonePrefix = ({
  handlePhonePrefix,
  prefixValue,
  handlePhoneFlag,
  mobileCountryFlag,
  isDisable,
  dropdownStyle = {},
  showSpanLabel = true,
  val,
}) => {
  return (
    <>
      <ReactPhoneInput
        inputClass="MuiInputBase-formControl"
        defaultCountry={mobileCountryFlag}
        onChange={(value, data) => {
          handlePhoneFlag(data.countryCode);
          handlePhonePrefix(value);
        }}
        value={prefixValue !== "" ? "+" + prefixValue : "+95"}
        style={{ width: "95px" }}
        disabled={val?.mobileNo || isDisable ? true : false}
        dropdownStyle={dropdownStyle}
        inputProps={{
          readOnly: true,
        }}
      />
      {showSpanLabel && (
        <span className="countryCode">
          {prefixValue !== "" ? "+" + prefixValue : "+95"}
        </span>
      )}
    </>
  );
};

export default PhonePrefix;
