import React from "react";
import Grid from "@material-ui/core/Grid";
import { MY_MM } from "../../config/constants";
import { useTheme } from "@mui/material/styles";
import {
  ContentfulData,
  getDataContentfulId,
} from "../../utils/contentfulUtil";
import { ProgressBarStyles } from "../../pages/balloon/styles/ProgressBarStyles";

const FlightProgressbar = ({ pageName, selectedLanguage, pageContent }) => {
  const theme = useTheme();
  const styleProps = { bgColor: theme.palette.primary.main };
  const classes = ProgressBarStyles(styleProps);

  return (
    <Grid item xs={9} sm={7} md={7} className="progressBarContainer">
      <ul
        className={
          selectedLanguage === MY_MM
            ? `${classes.progressBar} progressbar mm-progress`
            : `${classes.progressBar} progressbar eng-progress`
        }
      >
        <li
          className={
            pageName === "FlightSearch"
              ? "active"
              : "" + pageName !== "/"
              ? "completed"
              : ""
          }
        >
          <span
            style={{
              color:
                pageName === "FlightSearch"
                  ? theme.palette.primary.main
                  : "#a1a1a1",
            }}
            className="step-info"
            data-contentful-id={getDataContentfulId("book")}
          >
            <ContentfulData content={pageContent} id="book">
              Book
            </ContentfulData>
          </span>
        </li>
        <li
          className={
            pageName === "CustomerDetails"
              ? "active"
              : "" + pageName === "FlightPayment" ||
                pageName === "FlightConfirmation"
              ? "completed"
              : ""
          }
        >
          <span
            style={{
              color:
                pageName === "CustomerDetails"
                  ? theme.palette.primary.main
                  : "#a1a1a1",
            }}
            className="step-info"
            data-contentful-id={getDataContentfulId("info")}
          >
            <ContentfulData content={pageContent} id="info">
              Info
            </ContentfulData>
          </span>
        </li>
        <li
          className={
            pageName === "FlightPayment"
              ? "active"
              : "" + pageName === "FlightConfirmation"
              ? "completed"
              : ""
          }
        >
          <span
            style={{
              color:
                pageName === "FlightPayment"
                  ? theme.palette.primary.main
                  : "#a1a1a1",
            }}
            className="step-info"
            data-contentful-id={getDataContentfulId("pay")}
          >
            <ContentfulData content={pageContent} id="pay">
              Pay
            </ContentfulData>
          </span>
        </li>
        <li className={pageName === "FlightConfirmation" ? "active" : ""}>
          <span
            style={{
              color:
                pageName === "FlightConfirmation"
                  ? theme.palette.primary.main
                  : "#a1a1a1",
            }}
            className="step-info"
            data-contentful-id={getDataContentfulId("confirm")}
          >
            <ContentfulData content={pageContent} id="confirm">
              Confirm
            </ContentfulData>
          </span>
        </li>
      </ul>
    </Grid>
  );
};

export default FlightProgressbar;
