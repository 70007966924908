import { Button, Checkbox, Dialog, FormControlLabel } from "@material-ui/core";

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import "../../assets/scss/loginusers/Profile.scss";
import { LOGOUT_QUERY } from "../../queries/loginquery";
import { DELETE_ACCOUNT } from "../../queries/loginuserquery";
import { checkInApp, getClientTag, getLocale } from "../../utils/checkurl";
import { removeLocalStorageItem } from "../../utils/loginutils";
import { clientV3 } from "../../utils/v3utils";
import { removeFromLocalStorage } from "../../utils/logoututil";
import { ADDRESSBOOK_ID, LOGIN_USER_ID } from "../../config/constants";
import { DELETE_ADDRESS_PASSENGER } from "../../queries/addressbookquery";

const DeleteAccountDialog = ({ open, setOpen, page, pageContent }) => {
  const [checked, setChecked] = useState(false);
  const params = useParams();
  const [selectedLanguage] = useState(getLocale(params.locale));

  // const [language] = useState(
  //   getLocale(params.locale) === MY_MM ? MM_LOCALE : EN_LOCALE
  // );
  const handleBackToProfile = () => {
    setOpen(false);
  };

  const handleBackToPassengerList = () => {
    window.location.href = `/${selectedLanguage}/address-book/${localStorage.getItem(
      LOGIN_USER_ID,
    )}`;
  };

  const handleCheckApply = () => {
    setChecked((prev) => !prev);
  };

  const commonFunc = () => {
    removeFromLocalStorage();
    if (checkInApp(window.location.pathname)) {
      window.location.href = `/${selectedLanguage}/in-app?client=${getClientTag(
        window.location.search,
      )}`;
    } else {
      window.location.href = `/${selectedLanguage}`;
    }
  };

  const deleteAddressPassenger = () => {
    let addId = localStorage.getItem(ADDRESSBOOK_ID);
    clientV3
      .mutate({
        mutation: DELETE_ADDRESS_PASSENGER,
        variables: {
          id: addId,
        },
      })
      .then(
        (res) =>
          (window.location.href = `/${selectedLanguage}/address-book/${localStorage.getItem(
            LOGIN_USER_ID,
          )}`),
      )
      .catch((e) => console.log(e));
  };

  const handleLogout = () => {
    clientV3
      .mutate({
        mutation: LOGOUT_QUERY,
      })
      .then(() => {
        commonFunc();
      })
      .catch((err) => {
        console.log(err);
        let msg =
          err &&
          err.graphQLErrors &&
          err.graphQLErrors.length > 0 &&
          err.graphQLErrors[0]
            ? err.graphQLErrors[0].extensions.reason
            : "";
        if (msg === "Not Authenticated") {
          commonFunc();
        }
      });
  };

  const handleDeleteAccount = () => {
    if (page === "profile") {
      if (!checked) return;
      if (checked) {
        clientV3
          .mutate({
            mutation: DELETE_ACCOUNT,
          })
          .then((res) => handleLogout(), removeLocalStorageItem("userData"))
          .catch((e) => console.log(e));
      }
    } else {
      deleteAddressPassenger();
    }
  };

  return (
    <Dialog maxWidth={50} id="deleteAccountDialog" open={open}>
      <div className="dialog">
        <div className="account">
          {page === "addressBook" ? (
            <span>
              {pageContent && pageContent.removePassenger
                ? pageContent.removePassenger
                : "Are you sure you want to remove passenger?"}
            </span>
          ) : (
            <span>Are you sure you want to delete your account?</span>
          )}
        </div>
        {page === "profile" && (
          <>
            <div className="textWrapper">
              <span className="text">
                You will no longer have access to your booking history and
                address book.
              </span>
              <br />

              <span className="text"> This action can't be undone.</span>
            </div>
            <div className="terms">
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleCheckApply}
                    size="small"
                    style={{ color: "#00aeef" }}
                    checked={checked}
                  />
                }
                label={
                  <span style={{ fontSize: "11px" }}>
                    By clicking "Delete" you agree to the{" "}
                    <a
                      href="https://www.notion.so/flymya/Flymya-Terms-Conditions-d0a5ac3c68384407898f989ca914964e"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span
                        style={{
                          color: "#00aeef",
                          fontSize: "11px",
                          position: "absolute",
                          bottom: "39%",
                          left: "38%",
                        }}
                      >
                        Terms and conditions
                      </span>
                    </a>
                  </span>
                }
              />
            </div>
          </>
        )}

        <div className="dialogBtnWrapper">
          <div style={{ width: "200px" }}>
            <Button onClick={handleDeleteAccount} className="delete">
              {page === "profile"
                ? "Delete"
                : pageContent && pageContent.remove
                ? pageContent.remove
                : "Remove"}
            </Button>
          </div>
          <div style={{ width: "200px" }}>
            <Button
              onClick={
                page === "profile"
                  ? handleBackToProfile
                  : handleBackToPassengerList
              }
              variant="outlined"
              className="toProfile"
            >
              {page === "profile"
                ? "Back to Profile"
                : pageContent && pageContent.backToPassengerList
                ? pageContent.backToPassengerList
                : "Back to Passengers List"}
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteAccountDialog;
