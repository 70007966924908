import { gql } from "apollo-boost";

const LOGOUT_QUERY = gql`
  mutation logout {
    logout {
      status
      message
    }
  }
`;

const FIREBASE_LOGIN_QUERY = gql`
  mutation ($input: WebsiteLoginInput) {
    firebaseLogin(input: $input) {
      refresh_token
      access_token
      expires_in
      token_type
      user {
        id
        name
        email
        phone
        company {
          id
          name
          arrangers {
            id
            prefix
            first_name
            last_name
            arranger_phones {
              id
              phone_prefix
              phone
              is_verified
              is_primary
            }
            arranger_emails {
              id
              email
              is_verified
              is_primary
            }
            nationality {
              id
              name
            }
            dob
            passport
            passport_exp
            nrc
            address
            township {
              id
              name
              city {
                id
                name
                region {
                  id
                  name
                }
              }
            }
          }
        }
        roles {
          id
          name
          permissions {
            id
            name
          }
        }
        arranger {
          id
          prefix
          first_name
          last_name
          arranger_phones {
            id
            phone_prefix
            phone
            is_verified
            is_primary
          }
          arranger_emails {
            id
            email
            is_verified
            is_primary
          }
          nationality {
            id
            name
          }
          company {
            id
            name
            active
            class_type {
              id
              name
            }
          }
          dob
          passport
          passport_exp
          nrc
          address
          township {
            id
            name
            city {
              id
              name
              region {
                id
                name
              }
            }
          }
          loyalty_program {
            id
            sign_up
            logged_in_mobile
            joined_experience_myanmar
            invited_friend
            arranger {
              id
            }
            referral_by {
              id
            }
            booked_flight
            booked_bus
            booked_hotel
            added_friend
          }
        }
      }
    }
  }
`;
const FIREBASE_SIGNUP_QUERY = gql`
  mutation firebaseSignup($input: RegisterInput) {
    firebaseSignup(input: $input) {
      tokens {
        access_token
        refresh_token
        expires_in
        token_type
        user {
          id
          name
          email
        }
      }
      status
    }
  }
`;

const FIREBASE_LOGIN_BY_TOKEN_QUERY = gql`
  mutation ($input: FirebaseLoginInput) {
    firebaseLoginByToken(input: $input) {
      refresh_token
      access_token
      id_token
      expires_in
      token_type
      user {
        id
        name
        email
        phone
        company {
          id
          name
          arrangers {
            id
            prefix
            first_name
            last_name
            arranger_phones {
              id
              phone_prefix
              phone
              is_verified
              is_primary
            }
            arranger_emails {
              id
              email
              is_verified
              is_primary
            }
            nationality {
              id
              name
            }
            dob
            passport
            passport_exp
            nrc
            address
            township {
              id
              name
              city {
                id
                name
                region {
                  id
                  name
                }
              }
            }
          }
        }
        roles {
          id
          name
          permissions {
            id
            name
          }
        }
        arranger {
          id
          prefix
          first_name
          last_name
          arranger_phones {
            id
            phone_prefix
            phone
            is_verified
            is_primary
          }
          arranger_emails {
            id
            email
            is_verified
            is_primary
          }
          nationality {
            id
            name
          }
          dob
          passport
          passport_exp
          nrc
          address
          township {
            id
            name
            city {
              id
              name
              region {
                id
                name
              }
            }
          }
          loyalty_program {
            id
            sign_up
            logged_in_mobile
            joined_experience_myanmar
            invited_friend
            arranger {
              id
            }
            referral_by {
              id
            }
            booked_flight
            booked_bus
            booked_hotel
            added_friend
          }
        }
      }
    }
  }
`;
const GET_COMPONENT_HAS_PERMISSION = gql`
  query ($filter: [filterInput], $first: Int!, $page: Int) {
    componentHasPermission(
      filter: $filter
      first: $first
      page: $page
      orderBy: [{ column: "id", order: DESC }]
    ) {
      data {
        id
        permission {
          id
          name
          url
        }
        source {
          id
          name
        }
      }
      paginatorInfo {
        count
        currentPage
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const RESET_PASSWORD = gql`
  mutation ($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      status
      message
    }
  }
`;

const UPDATE_PASSWORD = gql`
  mutation ($input: NewPasswordWithCodeInput) {
    updateForgottenPassword(input: $input) {
      status
      message
    }
  }
`;

const VERIFY_EMAIL = gql`
  mutation verifyEmail($input: VerifyEmailInput!) {
    verifyEmail(input: $input) {
      refresh_token
      access_token
      expires_in
      token_type
      user {
        id
        name
        email
        company {
          id
          name
          arrangers {
            id
            prefix
            first_name
            last_name
            arranger_phones {
              id
              phone_prefix
              phone
              is_verified
              is_primary
            }
            arranger_emails {
              id
              email
              is_verified
              is_primary
            }
            nationality {
              id
              name
            }
            dob
            passport
            passport_exp
            nrc
            address
            township {
              id
              name
              city {
                id
                name
                region {
                  id
                  name
                }
              }
            }
          }
        }
        arranger {
          id
          prefix
          first_name
          last_name
          arranger_phones {
            id
            phone_prefix
            phone
            is_verified
            is_primary
          }
          arranger_emails {
            id
            email
            is_verified
            is_primary
          }
          company {
            id
            name
            active
            class_type {
              id
              name
            }
            arrangers {
              id
              prefix
              first_name
              last_name
              arranger_phones {
                id
                phone_prefix
                phone
                is_verified
                is_primary
              }
              arranger_emails {
                id
                email
                is_verified
                is_primary
              }
              nationality {
                id
                name
              }
              dob
              passport
              passport_exp
              nrc
              address
              township {
                id
                name
                city {
                  id
                  name
                  region {
                    id
                    name
                  }
                }
              }
            }
          }
          nationality {
            id
            name
          }
          dob
          passport
          passport_exp
          nrc
          address
        }
        login_types {
          id
          name
        }
        roles {
          id
          name
          permissions {
            id
            name
          }
        }
      }
    }
  }
`;

const RESEND_VERIFY_EMAIL = gql`
  mutation ($input: ReSendVerifyEmailInput!) {
    reSendVerifyEmail(input: $input) {
      status
      message
    }
  }
`;

const SEND_EMAIL_OTP = gql`
  mutation ($input: SendEmailLinkAuthInput) {
    sendEmailLinkAuth(input: $input) {
      status
      message
    }
  }
`;

export {
  LOGOUT_QUERY,
  FIREBASE_LOGIN_QUERY,
  FIREBASE_SIGNUP_QUERY,
  FIREBASE_LOGIN_BY_TOKEN_QUERY,
  GET_COMPONENT_HAS_PERMISSION,
  RESET_PASSWORD,
  UPDATE_PASSWORD,
  VERIFY_EMAIL,
  RESEND_VERIFY_EMAIL,
  SEND_EMAIL_OTP,
};
