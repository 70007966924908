import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Modal,
  Card,
  TextField,
  CircularProgress,
} from "@mui/material";
import MediaQuery from "react-responsive";
import { useNavigate } from "react-router-dom";
import closeIcon from "../../assets/images/relief_flight_close_icon.png";
import { clientV3 } from "../../utils/v3utils";
import {
  FIREBASE_LOGIN_BY_TOKEN_QUERY,
  RESET_PASSWORD,
  SEND_EMAIL_OTP,
} from "../../queries/loginquery";
import { CREATE_ARRANGER } from "../../queries/loginuserquery";
import firebase from "firebase/compat/app";
//import { initializeApp } from "firebase/app";
//import { CREATE_PEOPLE } from "queries/peoplequery";
import { validateEmail } from "../../utils/common";
import {
  //initializeAuth,
  signInWithPopup,
  //RecaptchaVerifier,
  GoogleAuthProvider,
  //FacebookAuthProvider,
  browserPopupRedirectResolver,
} from "firebase/auth";
import "firebase/compat/auth";
import {
  setToLocalStorage,
  //storeUserProfileInfo,
  storeUserProfileInformation,
} from "../../utils/loginutils";
import EmailSentImage from "../../assets/images/email_sent_icon.png";
import SigninEmailPassIcon from "../../assets/images/signin-email-pass.svg";
//import SigninPhonePassIcon from "../../assets/images/signin-phone-pass.svg";
import SigninOtpIcon from "../../assets/images/signin-otp.svg";
import SignupRegion from "./SignupRegion";
import PhonePrefix from "../../components/inputs/PhonePrefix";
//import PhoneSignInIcon from "../../components/svg/PhoneSignInIcon";
import GoogleIcon from "../../components/svg/GoogleIcon";
import {
  LOGIN_PEOPLE_DETAIL,
  LOGIN_NAME,
  LOGIN_PHONE_PREFIX,
  LOGIN_PHONE,
  LOGIN_SOCIAL,
  SOCIAL_TOKEN,
  LOGIN_USER_ID,
  IDENTIFIER,
  IS_CUSTOMER,
} from "../../config/constants";
import { isAuthenticated } from "../../lib/auth";
import { useTheme } from "@mui/material/styles";
import { isMobile } from "react-device-detect";
import { PhoneValidation } from "../../utils/phoneValidation";

const LoginSignupModal = ({
  isInApp,
  isInWeb,
  isShow,
  setIsShow,
  setIsSpinner,
  isDim,
  setIsDim,
  loginEmail,
  setLoginEmail,
  loginPassword,
  setLoginPassword,
  handleLogin,
  errorMsg,
  setErrorMsg,
  showB2BAccountBlockContactDetail,
  isForget,
  setIsForget,
  isSpinner,
  page,
  regValues,
  createdResPeople,
  setIsCheck,
  selectedLanguage,
  clientTag,
  handleContinue,
  setFieldValue,
  data,
  addId,
  pageContent,
  originalPage,
  setInfoOpen,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isSignUp, setIsSignUp] = useState(
    page === "home" || page === "reset password" ? false : true,
  );
  const [otp, setotp] = useState("");
  const [isPhoneOnly, setIsPhoneOnly] = useState(false);
  const [final, setfinal] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [clickSendOtp, setClickSendOtp] = useState(false);
  const [sendOtp, setSendOtp] = useState(false);
  const [otpPassword, setOtpPassword] = useState("");
  const [otpErrMsg, setOtpErrMsg] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [chooseState, setChooseState] = useState(true);
  const [isEmailPass, setIsEmailPass] = useState(false);
  const [isPhone, setIsPhone] = useState(false);
  const [isPhonePass, setIsPhonePass] = useState(false);
  const [isEmailOtp, setIsEmailOtp] = useState(false);
  const [emailOtpErrMsg, setEmailOtpErrMsg] = useState("");
  const [emailOtp, setEmailOtp] = useState("");
  const [clickSendEmailOtp, setClickSendEmailOtp] = useState(false);

  firebase.initializeApp({
    apiKey: "AIzaSyARU2wL-jgf6OCs04WzmBHpk9nZesx1ZJQ",
    authDomain: "auth.flymya.com",
    projectId: "v3-flymya-com",
    storageBucket: "v3-flymya-com.appspot.com",
    messagingSenderId: "938069228539",
    appId: "1:938069228539:web:a2356ace20a8307c5d295e",
    measurementId: "G-TFVZTWJD9J",
  });
  const auth = firebase.auth();

  const [activeTab, setActiveTab] = useState(0);
  const [signUpValues, setSignUpValues] = useState({});
  const [isSocialLogin, setIsSocialLogin] = useState(false);
  const [socialLoginType, setSocialLoginType] = useState(null);
  const [socialAuthToken, setSocialAuthToken] = useState();
  const [phonenumber, setPhonenumber] = useState("");
  const [phoneCountryCode, setPhoneCountryCode] = useState("95");
  const [phoneCountryFlag, setPhoneCountryFlag] = useState("mm");

  const handleSocialLogin = (tokenAcc, pass) => {
    setIsSocialLogin(true);
    localStorage.setItem(LOGIN_SOCIAL, "true");

    const inputVal = pass
      ? {
          token: tokenAcc,
          password: otpPassword,
          device_type: "website",
          oneclick: false,
          client:
            clientTag && clientTag !== ""
              ? clientTag
              : isMobile
              ? "default"
              : "default",
        }
      : {
          token: tokenAcc,
          device_type: "website",
          oneclick: false,
          client:
            clientTag && clientTag !== ""
              ? clientTag
              : isMobile
              ? "default"
              : "default",
        };

    clientV3
      .mutate({
        mutation: FIREBASE_LOGIN_BY_TOKEN_QUERY,
        variables: {
          input: inputVal,
        },
      })
      .then((res) => {
        setToLocalStorage(res);
        storeUserProfileInformation(res);

        if (
          res.data !== null &&
          res.data.firebaseLoginByToken !== null &&
          res.data.firebaseLoginByToken.user &&
          res.data.firebaseLoginByToken.user.arranger
        ) {
          setIsSpinner(true);
          setIsDim(true);
          if (isInApp) {
            window.location.href = `/${selectedLanguage}/in-app?client=${clientTag}`;
          } else if (isInWeb) {
            window.location.href = `/${selectedLanguage}/in-web?client=${clientTag}`;
          } else {
            if (originalPage && originalPage === "flightResult") {
              window.location.href = `/`;
            } else {
              window.location.href = `/${selectedLanguage}`;
            }
          }
        } else {
          localStorage.setItem(SOCIAL_TOKEN, tokenAcc);
          localStorage.setItem(
            LOGIN_NAME,
            res.data?.firebaseLoginByToken?.user?.name,
          );
          localStorage.setItem(LOGIN_PHONE_PREFIX, phoneCountryCode);
          localStorage.setItem(LOGIN_PHONE, phonenumber);
          setSignUpValues({
            adultInfo: [
              {
                id: res.data?.firebaseLoginByToken?.user?.id,
                firstName: res.data?.firebaseLoginByToken?.user?.name,
                email: res.data?.firebaseLoginByToken?.user?.email,
                mobilePrefix: phoneCountryCode,
                mobileNo: phonenumber,
              },
            ],
          });
          regValues
            ? (regValues.adultInfo[0] = signUpValues)
            : (regValues = { adultInfo: [signUpValues] });
          setIsSignUp(true);
        }
      })
      .catch((err) => {
        console.log(err);

        setErrorMsg("The user credentials were incorrect.");
        setIsSpinner(false);
        setIsDim(false);
      });
  };

  const signInWithGoogle = () => {
    signInWithPopup(
      auth,
      new GoogleAuthProvider(),
      browserPopupRedirectResolver,
    )
      .then((res) => {
        auth.currentUser
          .getIdToken(/* forceRefresh */ true)
          .then(function (idToken) {
            // Send token to your backend via HTTPS
            // ...
            setSocialAuthToken(idToken);
            setSocialLoginType("google");
            handleSocialLogin(idToken);
          })
          .catch(function (error) {
            // Handle error
            console.log(error.message);
          });

        //setToken(res.credential.idToken);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  // const signInWithFacebook = () => {
  //   signInWithPopup(new FacebookAuthProvider())
  //     .then((res) => {
  //       setSocialLoginType("facebook");
  //       handleSocialLogin(accessToken);
  //       setToken(res.credential.idToken);
  //     })
  //     .catch((error) => {
  //       console.log(error.message);
  //     });
  // };

  useEffect(() => {
    if (page === "addressBook") {
      setIsShow(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleIsNumber = (number) => {
    let reg = /^[0-9]+$/;
    return reg.test(number);
  };

  const handleClearTimer = (interval) => {
    clearInterval(interval);
    setIsDisable(false);
  };

  const signInWithPhoneNumber = () => {
    const firstNo = phonenumber.charAt(0);
    if (
      phonenumber === "" ||
      phonenumber.length < 8 ||
      Number(firstNo) === 0 ||
      !handleIsNumber(phonenumber)
    ) {
      setOtpErrMsg("Please check your phone number.");
      return;
    }

    setClickSendOtp(true);
    let totalSec = 60;
    const interval = setInterval(() => {
      setIsDisable(true);
      totalSec = totalSec - 1;
      if (totalSec === 0) {
        handleClearTimer(interval);
      }
    }, 1000);

    const phoneNumberString = "+" + phoneCountryCode + phonenumber;

    let verify = new firebase.auth.RecaptchaVerifier("recaptcha-container");
    auth
      .signInWithPhoneNumber(phoneNumberString, verify)
      .then((result) => {
        setfinal(result);
        setClickSendOtp(false);
        setSendOtp(true);
        alert(
          "We have sent you a message with a code. Please use this code to sign in",
        );
        setIsPhoneOnly(true);
      })
      .catch((err) => {
        alert(err);
        setClickSendOtp(false);
        window.location.reload();
      });
  };

  const OTPValidation = () => {
    if (otp === null || final === null) return;
    final
      .confirm(otp)
      .then((result) => {
        auth.currentUser
          .getIdToken(/* forceRefresh */ true)
          .then(function (idToken) {
            // Send token to your backend via HTTPS
            // ...
            setSocialAuthToken(idToken);
            setSocialLoginType("phone");
            isPhoneOnly
              ? handleSocialLogin(idToken)
              : handleSocialLogin(idToken, otpPassword);
          })
          .catch(function (error) {
            // Handle error
            console.log(error.message);
          });
      })
      .catch((err) => {
        alert("Wrong code");
      });
  };

  const signInWithEmailOtp = () => {
    if (!validateEmail(emailOtp)) {
      setEmailOtpErrMsg("Please check your email address.");
      return;
    }
    setClickSendEmailOtp(true);

    clientV3
      .mutate({
        mutation: SEND_EMAIL_OTP,
        variables: {
          input: { email: emailOtp },
        },
      })
      .then((res) => {
        alert(
          `We have sent a link to your email address ${emailOtp}. Please check your inbox to sign in with this email address.`,
        );
        setIsShow(false);
        setClickSendEmailOtp(false);
      })
      .catch((err) => {
        console.log(err);
        setEmailOtpErrMsg("Something went wrong.");
        setClickSendEmailOtp(false);
      });
  };

  const createPeople = (values) => {
    setIsDim(true);
    let commonInput = {
      id: values.id,
      login_id:
        localStorage.getItem("userId") && localStorage.getItem("userId") !== ""
          ? localStorage.getItem("userId")
          : null,
      prefix: values.signupPrefix,
      first_name: values.signupFirstName,
      last_name: values.signupLastName,
      phone_prefix: values.signupPhonePrefix,
      phone: values.signupPhone,
      email: values.signupEmail,
      nationality_id: values.signupNationality,
      dob:
        values.signupDobYear !== "0000" &&
        values.signupDobMonth !== "00" &&
        values.signupDobDay !== "00"
          ? `${values.signupDobYear}-${values.signupDobMonth}-${values.signupDobDay}`
          : null,
      passport: values.signupNrcPass,
      passport_exp:
        values.signupExpYear !== "0000" &&
        values.signupExpMonth !== "00" &&
        values.signupExpDay !== "00"
          ? `${values.signupExpYear}-${values.signupExpMonth}-${values.signupExpDay}`
          : null,
      nrc: values.signupNrc,
      address: values.address,
      township_id: values.township,
    };

    clientV3
      .mutate({
        mutation: CREATE_ARRANGER,
        variables: {
          input: commonInput,
        },
      })
      .then((res) => {
        if (res.data) {
          // setToLocalStorage(res);
          handleSocialLogin(socialAuthToken);
          // if (isInApp) {
          //   window.location.href = `/${selectedLanguage}/in-app?client=${clientTag}`;
          // } else {
          //   window.location.href = `/${selectedLanguage}`;
          // }
        }
      })
      .catch((err) => {
        console.log(err);
        setErrorMsg("Something went wrong.");
        setIsSpinner(false);
        setIsDim(false);
      });
  };

  const handleClearSendEmail = (interval) => {
    clearInterval(interval);
    setEmailSent(false);
    setIsForget(false);
    setIsEmailPass(false);
    setChooseState(true);
  };

  const handleResetPassword = async () => {
    setIsSpinner(true);
    try {
      const { data } = await clientV3.mutate({
        mutation: RESET_PASSWORD,
        variables: {
          input: {
            email: loginEmail,
          },
        },
      });

      if (data?.forgotPassword?.status === "EMAIL_NOT_SENT") {
        setIsSpinner(false);
        setErrorMsg("We can't find a user with that email address.");
      } else if (data?.forgotPassword?.status === "EMAIL_SENT") {
        setIsSpinner(false);
        setErrorMsg("");
        setEmailSent(true);
        let totalSec = 10;
        const interval = setInterval(() => {
          totalSec = totalSec - 1;
          if (totalSec === 0) {
            handleClearSendEmail(interval);
          }
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      setIsSpinner(false);
    }
  };

  // If error occure in login page when user switch to sign up page, clear the error message.
  // Else the error message will be showing on sign up page without any reason.
  useEffect(() => {
    setErrorMsg("");
    const getLoginDetailFromLS = localStorage.getItem(LOGIN_PEOPLE_DETAIL);
    if (
      isAuthenticated() &&
      localStorage.getItem(IS_CUSTOMER) &&
      localStorage.getItem(IS_CUSTOMER) === "yes" &&
      localStorage.getItem(SOCIAL_TOKEN) &&
      localStorage.getItem(SOCIAL_TOKEN) !== "" &&
      (getLoginDetailFromLS === "false" ||
        getLoginDetailFromLS === "" ||
        getLoginDetailFromLS === "undefined")
    ) {
      setIsSignUp(true);
      setIsSocialLogin(
        localStorage.getItem(LOGIN_SOCIAL) &&
          localStorage.getItem(LOGIN_SOCIAL) === "true"
          ? true
          : false,
      );
      setSignUpValues({
        adultInfo: [
          {
            id: localStorage.getItem(LOGIN_USER_ID),
            firstName:
              localStorage.getItem(LOGIN_NAME) &&
              localStorage.getItem(LOGIN_NAME) !== "" &&
              localStorage.getItem(LOGIN_NAME) !== "null"
                ? localStorage.getItem(LOGIN_NAME)
                : "",
            email: localStorage.getItem(IDENTIFIER),
            mobilePrefix: localStorage.getItem(LOGIN_PHONE_PREFIX),
            mobileNo: localStorage.getItem(LOGIN_PHONE),
          },
        ],
      });
      localStorage.getItem(SOCIAL_TOKEN) &&
        localStorage.getItem(SOCIAL_TOKEN) !== "" &&
        setSocialAuthToken(localStorage.getItem(SOCIAL_TOKEN));
    }
  }, [setErrorMsg]);

  useEffect(() => {
    if (page === "reset password") {
      setChooseState(false);
      setIsEmailPass(true);
      setErrorMsg("");
      setOtpErrMsg("");
    }
  }, [page, setErrorMsg]);

  return (
    <Modal
      open={isShow}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          setIsShow();
        }
        setIsForget(false);
        setIsPhoneOnly(false);
      }}
    >
      {/* Home Login Modal Box Css */}
      <Card
        className="login-div"
        style={
          page === "profile" ||
          page === "addressBook" ||
          page === "reset password" ||
          page === "customerInfo"
            ? { width: "40%" }
            : { width: "60%" }
        }
      >
        <Grid container className="login-container">
          <Grid container className="login-inner">
            <Grid
              item={true}
              sm={12}
              md={
                page === "profile" ||
                page === "addressBook" ||
                page === "reset password" ||
                page === "customerInfo"
                  ? 12
                  : 8
              }
              xs={12}
              style={
                page === "profile" ? { height: "85vh" } : { height: "70vh" }
              }
              className="login-left"
            >
              {!isSignUp &&
                (page === "home" || page === "reset password") &&
                !emailSent && (
                  <div className="login-left-inner signin">
                    <MediaQuery maxWidth={780}>
                      <img
                        src={closeIcon}
                        alt="close"
                        onClick={() => {
                          setIsShow();
                          setIsForget(false);
                          setIsSignUp(false);
                        }}
                        className="closeImg"
                      />
                    </MediaQuery>
                    <h3>
                      {isForget
                        ? "Reset Password"
                        : pageContent && pageContent.userLogin
                        ? pageContent.userLogin
                        : "User Login"}
                    </h3>
                    {!isInApp && !isInWeb && !isForget && (
                      <div className="div-login-tab">
                        <div
                          className={
                            activeTab === 0 ? "active login-tab" : "login-tab"
                          }
                          onClick={() => {
                            setIsCheck(false);
                            setActiveTab(0);
                          }}
                        >
                          {pageContent && pageContent.customerLogin
                            ? pageContent.customerLogin
                            : "Customer Login"}
                        </div>

                        <div
                          className={
                            activeTab === 1 ? "active login-tab" : "login-tab"
                          }
                          onClick={() => {
                            setIsCheck(true);
                            setActiveTab(1);
                          }}
                        >
                          {pageContent && pageContent.agentLogin
                            ? pageContent.agentLogin
                            : "Agent Login"}
                        </div>
                      </div>
                    )}

                    {chooseState && activeTab === 0 && (
                      <div
                        style={{
                          border: `1px solid ${theme.palette.primary.main}`,
                        }}
                        className="infobox infobox-loginbuttons"
                      >
                        <Button
                          style={{
                            border: `1px solid ${theme.palette.primary.main}`,
                          }}
                          className="btn-login-box btn-email-password"
                          onClick={() => {
                            setChooseState(false);
                            setIsEmailPass(true);
                            setErrorMsg("");
                            setOtpErrMsg("");
                          }}
                          startIcon={<img src={SigninEmailPassIcon} alt="" />}
                        >
                          <span className="sign-text">
                            {pageContent && pageContent.signInEmailPassword
                              ? pageContent.signInEmailPassword
                              : "Sign in with Email + password"}
                          </span>
                        </Button>
                        {/* <Button
                        className="btn-login-box btn-phone-password"
                        onClick={() => {
                          setChooseState(false);
                          setIsPhone(true);
                          setIsPhonePass(true);
                          setErrorMsg("");
                          setOtpErrMsg("");
                        }}
                        startIcon={<img src={SigninPhonePassIcon} alt="" />}
                      >
                        <span className="sign-text">
                          Sign in with Phone + Password
                        </span>
                      </Button> */}
                        {!isInApp && (
                          <Button
                            style={{
                              border: `1px solid ${theme.palette.primary.main}`,
                            }}
                            className="btn-login-box"
                            onClick={signInWithGoogle}
                            startIcon={<GoogleIcon />}
                          >
                            <span className="sign-text">
                              {pageContent && pageContent.continueGoogleSso
                                ? pageContent.continueGoogleSso
                                : "Continue with Google SSO"}
                            </span>
                          </Button>
                        )}
                        <Button
                          style={{
                            border: `1px solid ${theme.palette.primary.main}`,
                          }}
                          className="btn-login-box"
                          onClick={() => {
                            setChooseState(false);
                            setIsPhone(true);
                            setIsPhoneOnly(true);
                            setErrorMsg("");
                            setOtpErrMsg("");
                          }}
                          startIcon={<img src={SigninOtpIcon} alt="" />}
                        >
                          <span className="sign-text">
                            {" "}
                            {pageContent && pageContent.continueOtp
                              ? pageContent.continueOtp
                              : "Continue with Phone OTP"}
                          </span>
                        </Button>
                        <Button
                          style={{
                            border: `1px solid ${theme.palette.primary.main}`,
                          }}
                          className="btn-login-box"
                          onClick={() => {
                            setChooseState(false);
                            setIsEmailOtp(true);
                            setErrorMsg("");
                            setEmailOtpErrMsg("");
                          }}
                          startIcon={<img src={SigninEmailPassIcon} alt="" />}
                        >
                          <span className="sign-text">
                            {" "}
                            {pageContent && pageContent.continueEmailOtp
                              ? pageContent.continueEmailOtp
                              : "Continue with Email OTP"}{" "}
                          </span>
                        </Button>
                      </div>
                    )}

                    {isForget && (
                      <p className="forget-info">
                        {pageContent && pageContent.emailAssociated
                          ? pageContent.emailAssociated
                          : "Enter the email associated with your account."}

                        <br />
                        {pageContent && pageContent.sendInstructionsReset
                          ? pageContent.sendInstructionsReset
                          : " And we’ll send an email with instructions to Reset your password."}
                      </p>
                    )}
                    {((!isEmailOtp &&
                      !isPhone &&
                      isEmailPass &&
                      activeTab === 0) ||
                      activeTab === 1) && (
                      <div
                        style={{
                          border: `1px solid ${theme.palette.primary.main}`,
                        }}
                        className={isDim ? "dim-div infobox" : "infobox"}
                      >
                        {activeTab === 0 && !isForget && (
                          <span className="login-subtitle">
                            {pageContent && pageContent.signInEmailPassword
                              ? pageContent.signInEmailPassword
                              : "Sign in with Email + password"}
                          </span>
                        )}
                        <TextField
                          className={errorMsg !== "" ? "error-textbox" : ""}
                          name="loginEmail"
                          value={loginEmail}
                          onChange={(e) => {
                            setLoginEmail(e.target.value);
                          }}
                          onBlur={(e) => {
                            // if (!validateEmail(e.target.value))
                            //   setErrorMsg("Please fill correct Email");
                            // else setErrorMsg("");
                            setErrorMsg("");
                          }}
                          label={
                            pageContent && pageContent.email
                              ? pageContent.email
                              : "Email"
                          }
                          inputProps={{
                            autoComplete: "new-email",
                            form: {
                              autoComplete: "off",
                            },
                          }}
                          required
                          error={errorMsg !== "" ? true : false}
                          variant="standard"
                        />

                        {!isForget && (
                          <TextField
                            className={errorMsg !== "" ? "error-textbox" : ""}
                            name="loginPassword"
                            type="password"
                            value={loginPassword}
                            autoComplete="off"
                            onChange={(e) => {
                              setErrorMsg("");
                              setLoginPassword(e.target.value);
                            }}
                            label="Password"
                            required
                            error={errorMsg !== "" ? true : false}
                            variant="standard"
                          />
                        )}

                        {errorMsg !== "" && showB2BAccountBlockContactDetail && (
                          <>
                            <p>Ph no - 09 797978885, 09 263097152 </p>
                            <p>Email - partners@flymya.co</p>
                          </>
                        )}
                        {errorMsg !== "" && <p>{errorMsg}</p>}
                        {!isForget && (
                          <Button
                            color="primary"
                            variant="contained"
                            type="button"
                            id="btn-sign-in"
                            onClick={() => {
                              setIsCheck(activeTab === 1 ? true : false);

                              handleLogin();
                            }}
                            disabled={
                              errorMsg !== "" ||
                              loginEmail === "airticket770@gmail.com"
                                ? true
                                : false
                            }
                          >
                            {pageContent && pageContent.signIn
                              ? pageContent.signIn
                              : "Sign In"}{" "}
                            {isSpinner && <CircularProgress />}
                          </Button>
                        )}

                        {isForget && (
                          <Button
                            color="primary"
                            variant="contained"
                            type="button"
                            id="btn-sign-in"
                            onClick={() => handleResetPassword()}
                            disabled={
                              errorMsg !== "" ||
                              loginEmail === "airticket770@gmail.com"
                                ? true
                                : false
                            }
                          >
                            {pageContent && pageContent.sentInstructions
                              ? pageContent.sentInstructions
                              : "Sent Instructions"}{" "}
                            {isSpinner && <CircularProgress />}
                          </Button>
                        )}

                        {!isForget && activeTab === 0 && (
                          <p
                            style={{ color: theme.palette.primary.main }}
                            className="forgot-email"
                            onClick={() => {
                              setErrorMsg("");
                              setIsForget(true);
                            }}
                          >
                            {pageContent && pageContent.forgotPassword
                              ? pageContent.forgotPassword
                              : "Forgot Password"}
                          </p>
                        )}

                        {activeTab === 0 && (
                          <span
                            style={{
                              border: `1px solid ${theme.palette.primary.main}`,
                            }}
                            className="signin-back-btn"
                            onClick={() => {
                              if (page === "reset password") {
                                setIsShow(false);
                              } else {
                                setChooseState(true);
                                setIsEmailPass(false);
                                setIsForget(false);
                              }
                            }}
                          >
                            {pageContent && pageContent.back
                              ? pageContent.back
                              : "Back"}
                          </span>
                        )}
                      </div>
                    )}

                    {isPhone && !sendOtp && activeTab === 0 && (
                      <div
                        style={{
                          border: `1px solid ${theme.palette.primary.main}`,
                        }}
                        className="infobox"
                      >
                        <span className="login-subtitle">
                          {pageContent && pageContent.loginOtp
                            ? pageContent.loginOtp
                            : "Login with Phone OTP"}
                        </span>
                        <div style={{ display: "flex" }}>
                          <Grid
                            className="phone-container"
                            style={{ marginLeft: "3px" }}
                          >
                            <PhonePrefix
                              handlePhonePrefix={setPhoneCountryCode}
                              prefixValue={phoneCountryCode}
                              handlePhoneFlag={setPhoneCountryFlag}
                              mobileCountryFlag={phoneCountryFlag}
                              showSpanLabel={false}
                              dropdownStyle={{
                                background: "white",
                                border: "none",
                              }}
                            />
                          </Grid>

                          <TextField
                            className={otpErrMsg !== "" ? "error-textbox" : ""}
                            name="Phone Number"
                            type="text"
                            value={phonenumber}
                            autoComplete="off"
                            onChange={(e) => {
                              setOtpErrMsg("");
                              setPhonenumber(e.target.value);
                            }}
                            onBlur={() => {
                              const num = /^[0-9]+$/;
                              const firstNo = phonenumber.charAt(0);
                              if (
                                !num.test(phonenumber) ||
                                Number(firstNo) === 0
                              ) {
                                setOtpErrMsg("Please check your phone number.");
                                return;
                              }
                              if (
                                phoneCountryCode === "95" &&
                                !PhoneValidation(phonenumber)
                              ) {
                                setOtpErrMsg("Please enter valid number.");
                                return;
                              }
                              setOtpErrMsg("");
                            }}
                            // label="Phone Number"
                            placeholder="Phone Number"
                            required
                            style={{ marginBottom: "16px", paddingTop: "6px" }}
                            error={otpErrMsg !== "" ? true : false}
                            variant="standard"
                          />
                        </div>

                        {isPhonePass && (
                          <TextField
                            className={otpErrMsg !== "" ? "error-textbox" : ""}
                            name="otpPassword"
                            type="password"
                            value={otpPassword}
                            autoComplete="off"
                            onChange={(e) => {
                              setOtpErrMsg("");
                              setOtpPassword(e.target.value);
                            }}
                            label="Password"
                            required
                            error={otpErrMsg !== "" ? true : false}
                          />
                        )}

                        {otpErrMsg !== "" && <p>{otpErrMsg}</p>}
                        <span className="phoneInfo">
                          e.g. 9123456789, for country code (+95) and mobile
                          No.9123456789
                        </span>
                        <br />
                        <br />
                        <div id="recaptcha-container"></div>

                        <Button
                          color="primary"
                          variant="contained"
                          className={`btn-send-otp ${
                            isDisable ? "dim-btn-send-otp" : ""
                          }`}
                          onClick={signInWithPhoneNumber}
                          disabled={clickSendOtp}
                        >
                          {pageContent && pageContent.sendOtp
                            ? pageContent.sendOtp
                            : "Send OTP"}
                        </Button>
                        {isDisable && (
                          <p>
                            {" "}
                            {pageContent && pageContent.waitNextAttempt
                              ? pageContent.waitNextAttempt
                              : "Please wait 60secs before the next attempt."}
                          </p>
                        )}
                        <span
                          style={{
                            border: `1px solid ${theme.palette.primary.main}`,
                          }}
                          className="signin-back-btn"
                          onClick={() => {
                            if (page === "reset password") {
                              setIsShow(false);
                            } else {
                              setChooseState(true);
                              setIsEmailPass(false);
                              setIsForget(false);
                              setIsPhone(false);
                              setIsPhonePass(false);
                              setIsPhoneOnly(false);
                            }
                          }}
                        >
                          {pageContent && pageContent.back
                            ? pageContent.back
                            : "Back"}
                        </span>
                      </div>
                    )}

                    {sendOtp && activeTab === 0 && (
                      <div
                        style={{
                          border: `1px solid ${theme.palette.primary.main}`,
                        }}
                        className="infobox"
                      >
                        <TextField
                          className={errorMsg !== "" ? "error-textbox" : ""}
                          name="OTP"
                          type="text"
                          value={otp}
                          autoComplete="off"
                          onChange={(e) => {
                            setotp(e.target.value);
                          }}
                          label="OTP"
                          required
                          error={errorMsg !== "" ? true : false}
                          variant="standard"
                        />
                        <Button
                          color="primary"
                          variant="contained"
                          className="btn-send-otp"
                          onClick={OTPValidation}
                        >
                          {pageContent && pageContent.verify
                            ? pageContent.verify
                            : "Verify"}
                        </Button>
                        <span
                          style={{
                            border: `1px solid ${theme.palette.primary.main}`,
                          }}
                          className="signin-back-btn"
                          onClick={() => setSendOtp(false)}
                        >
                          {pageContent && pageContent.back
                            ? pageContent.back
                            : "Back"}
                        </span>
                      </div>
                    )}

                    {isEmailOtp && activeTab === 0 && (
                      <div
                        style={{
                          border: `1px solid ${theme.palette.primary.main}`,
                        }}
                        className="infobox"
                      >
                        <span className="login-subtitle">
                          {pageContent && pageContent.loginWithEmailOtp
                            ? pageContent.loginWithEmailOtp
                            : "Login with Email OTP"}
                        </span>
                        <div style={{ display: "flex" }}>
                          <TextField
                            className={otpErrMsg !== "" ? "error-textbox" : ""}
                            name="Email"
                            type="text"
                            value={emailOtp}
                            autoComplete="off"
                            onChange={(e) => {
                              setEmailOtpErrMsg("");
                              setEmailOtp(e.target.value);
                            }}
                            onBlur={() => {
                              if (!validateEmail(emailOtp)) {
                                setEmailOtpErrMsg("Please check your email.");
                                return;
                              }
                              setEmailOtpErrMsg("");
                            }}
                            placeholder="Email *"
                            required
                            style={{ marginBottom: "16px", paddingTop: "6px" }}
                            error={emailOtpErrMsg !== "" ? true : false}
                            variant="standard"
                          />
                        </div>

                        {emailOtpErrMsg !== "" && <p>{emailOtpErrMsg}</p>}
                        <br />
                        <br />

                        <Button
                          color="primary"
                          variant="contained"
                          className={`btn-send-otp ${
                            clickSendEmailOtp ? "dim-btn-send-otp" : ""
                          }`}
                          onClick={signInWithEmailOtp}
                          disabled={clickSendEmailOtp}
                        >
                          Send {clickSendEmailOtp && <CircularProgress />}
                        </Button>
                        <span
                          style={{
                            border: `1px solid ${theme.palette.primary.main}`,
                          }}
                          className="signin-back-btn"
                          onClick={() => {
                            if (page === "reset password") {
                              setIsShow(false);
                            } else {
                              setChooseState(true);
                              setIsEmailPass(false);
                              setIsForget(false);
                              setIsPhone(false);
                              setIsPhonePass(false);
                              setIsPhoneOnly(false);
                              setIsEmailOtp(false);
                            }
                          }}
                        >
                          {pageContent && pageContent.back
                            ? pageContent.back
                            : "Back"}
                        </span>
                      </div>
                    )}

                    {/* <div className="login-buttons">
                    <Button
                      className="btn-sign-in btn-facebook"
                      onClick={signInWithFacebook}
                      startIcon={<GoogleIcon />}
                    >
                      <span className="sign-text">Sign in with Facebook</span>
                    </Button>
                  </div> */}
                  </div>
                )}

              {/* After the email is sent, we will show this screen */}
              {emailSent && (
                <div className="login-left-inner signin email-sent">
                  <img src={EmailSentImage} alt="" />
                  <h2>
                    {" "}
                    {pageContent && pageContent.checkYourEmail
                      ? pageContent.checkYourEmail
                      : "Check your Email"}
                  </h2>
                  <p>
                    {pageContent && pageContent.sentRecoverInstructions
                      ? pageContent.sentRecoverInstructions
                      : " We have sent a password recovery Instructions to your email."}
                  </p>
                  <a
                    href="https://mail.google.com"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Button>
                      {" "}
                      {pageContent && pageContent.openEmail
                        ? pageContent.openEmail
                        : "Open Email"}
                    </Button>
                  </a>
                  <span className="info">
                    {pageContent && pageContent.didnotReceiveEmail
                      ? pageContent.didnotReceiveEmail
                      : "Did not receive the email?"}
                    {pageContent && pageContent.checkSpamFilter
                      ? pageContent.checkSpamFilter
                      : "Check your spam filter."}
                  </span>
                </div>
              )}

              {isSignUp && (
                <SignupRegion
                  handleContinue={handleContinue}
                  addId={addId}
                  isShow={isShow}
                  setIsShow={setIsShow}
                  setIsSignUp={setIsSignUp}
                  isSocialLogin={isSocialLogin}
                  socialLoginType={socialLoginType}
                  createPeople={createPeople}
                  setIsDim={setIsDim}
                  createdResPeople={createdResPeople}
                  page={page}
                  handleLogin={handleLogin}
                  errorMsg={errorMsg}
                  setErrorMsg={setErrorMsg}
                  isDim={isDim}
                  regValues={regValues}
                  signUpValues={signUpValues}
                  isSpinner={isSpinner}
                  setIsSpinner={setIsSpinner}
                  signInWithGoogle={signInWithGoogle}
                  setChooseState={setChooseState}
                  setIsPhone={setIsPhone}
                  setIsPhoneOnly={setIsPhoneOnly}
                  setOtpErrMsg={setOtpErrMsg}
                  setIsForget={setIsForget}
                  setFieldValue={setFieldValue}
                  data={data}
                  pageContent={pageContent}
                  setInfoOpen={setInfoOpen}
                  originalPage={originalPage}
                />
              )}
            </Grid>
            {page === "home" && (
              <>
                <MediaQuery maxDeviceWidth={768}>
                  <Grid
                    style={
                      !isSignUp
                        ? {
                            minHeight: "none",
                          }
                        : {
                            marginBottom: 0,
                          }
                    }
                    item={true}
                    sm={12}
                    md={4}
                    xs={12}
                    className="login-right"
                  >
                    {!isSignUp && (
                      <h4>
                        {pageContent && pageContent.newHere
                          ? pageContent.newHere
                          : "New Here"}
                      </h4>
                    )}

                    {!isSignUp && (
                      <span>
                        {pageContent && pageContent.discoverNewOpportunities
                          ? pageContent.discoverNewOpportunities
                          : "Sign up and discover great amount of new opportunities."}
                      </span>
                    )}
                    {!isSignUp && (
                      <Button type="button" onClick={() => setIsSignUp(true)}>
                        {pageContent && pageContent.signUp
                          ? pageContent.signUp
                          : "Sign Up"}
                      </Button>
                    )}
                  </Grid>
                </MediaQuery>
                <MediaQuery minDeviceWidth={768}>
                  <Grid
                    item={true}
                    sm={12}
                    md={4}
                    xs={12}
                    className="login-right"
                  >
                    {(!localStorage.getItem(IDENTIFIER) ||
                      localStorage.getItem(IDENTIFIER) === "") && (
                      <MediaQuery minDeviceWidth={768}>
                        <img
                          src={closeIcon}
                          alt="close"
                          onClick={() => {
                            setIsShow();

                            setIsForget(false);
                          }}
                          className="closeImg"
                        />
                      </MediaQuery>
                    )}

                    {!isSignUp && (
                      <h4>
                        {pageContent && pageContent.newHere
                          ? pageContent.newHere
                          : "New Here"}
                      </h4>
                    )}

                    {!isSignUp && (
                      <span>
                        {pageContent && pageContent.discoverNewOpportunities
                          ? pageContent.discoverNewOpportunities
                          : "Sign up and discover great amount of new opportunities."}
                      </span>
                    )}
                    {!isSignUp && (
                      <Button type="button" onClick={() => setIsSignUp(true)}>
                        {pageContent && pageContent.signUp
                          ? pageContent.signUp
                          : "Sign Up"}
                      </Button>
                    )}
                  </Grid>
                </MediaQuery>
              </>
            )}
          </Grid>
        </Grid>
      </Card>
    </Modal>
  );
};

export default LoginSignupModal;
