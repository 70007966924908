import { gql } from "apollo-boost";

const GET_REGIONS = gql`
  query regions(
    $filter: [filterInput]
    $orderBy: [OrderByClause!]
    $first: Int!
    $page: Int
  ) {
    regions(filter: $filter, orderBy: $orderBy, first: $first, page: $page) {
      data {
        id
        name
        country {
          id
          name
          iso_code_2
          iso_code_3
          currency_code
          nationality
          phone
        }
        cities {
          id
          name
          region_id
          townships {
            id
            name
            city_id
          }
        }
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const GET_CITIES = gql`
  query cities(
    $filter: [filterInput]
    $orderBy: [OrderByClause!]
    $first: Int!
    $page: Int
  ) {
    cities(filter: $filter, orderBy: $orderBy, first: $first, page: $page) {
      data {
        id
        name
        region_id
        region {
          id
          name
          country {
            id
            name
            iso_code_2
            iso_code_3
            currency_code
            nationality
            phone
          }
        }
        townships {
          id
          name
          city_id
        }
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const GET_TOWNSHIPS = gql`
  query townships(
    $filter: [filterInput]
    $orderBy: [OrderByClause!]
    $first: Int!
    $page: Int
  ) {
    townships(filter: $filter, orderBy: $orderBy, first: $first, page: $page) {
      data {
        id
        name
        city_id
        city {
          id
          name
          region_id
          region {
            id
            name
            country {
              id
              name
              iso_code_2
              iso_code_3
              currency_code
              nationality
              phone
            }
          }
        }
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

const GET_COUNTRIES = gql`
  query (
    $first: Int!
    $page: Int
    $filter: [filterInput]
    $orderBy: [OrderByClause!]
  ) {
    countries(orderBy: $orderBy, first: $first, page: $page, filter: $filter) {
      data {
        id
        name
        iso_code_2
        iso_code_3
        nationality
        currency_code
        phone
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

export { GET_REGIONS, GET_CITIES, GET_TOWNSHIPS, GET_COUNTRIES };
